/** @format */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GeneralForm from "../../helpers/GeneralForm";
import { artistObj } from "../../dbObjects.js/dbArtists";
import { userObj } from "../../dbObjects.js/dbUser";
import { bookingsObj } from "../../dbObjects.js/dbBookings";
import { useDispatch, useSelector } from "react-redux";
import { bookingsGeneralObj } from "../../dbObjects.js/dbBookingsGeneral";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slider,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import WhatDayIsIt from "../MiscUIComponents/WhatDayIsIt";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import AgreementModal from "../Documents/AgreementModal";
import { useEffect } from "react";
import {
  storeCurrentAgreements,
  storeCurrentBooking,
  storeCurrentTickets,
  storeCurrentVenue,
  storeSimilarBookings,
} from "../../features/venueFilter/venueFilterSlice";
import CheckIcon from "@mui/icons-material/Check";
import DisplayObject from "../../helpers/displayObject";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useNavigate } from "react-router-dom";
import { numberFormatted } from "../../helpers/Numbers";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import TicketSalesGraph from "../UI helpers/TicketSalesGraph";
import GetCoordinatesFromEventId from "../../helpers/GetCoordinatesFromEventId";
import GetTicketsForBooking from "../../helpers/GetTicketsForBooking";
import TicketsMap from "../../helpers/TicketsMap";
import GetEventImageFromGoogle from "../../helpers/GetEventImageFromGoogle";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import getTicketSalesSummary from "../../helpers/GetTicketSalesSummary";
import EventTicketSummary from "../../helpers/GetTicketSalesSummary";
import WeeklySalesChart from "../../helpers/SalesChartWeekly";
import DeclineDialog from "../../helpers/DeclineDialog";
import SelectStatus from "./SelectStatus";
import ProductSummaryBookingForm from "./ProductSummaryBookingForm";
import Products from "./Products";
import SelectProductInBookingForm from "../MiscUIComponents/SelectProductInBookingForm";
import BookingFeatureEditor from "../ProductFeatures/BookingFeatureEditor";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IncomingRequests from "../UI helpers/IncomingRequests";
import validateEmail from "../MiscUIComponents/ValidateEmail";
import { calculateBookingPrice } from "../MiscUIComponents/calculateBookingPrice";
import VhDatePicker from "../MiscUIComponents/DatePicker";
import StandardTimesSlider from "../MiscUIComponents/StandardTimesSlider";
import EditIcon from "@mui/icons-material/Edit";
import ProductSelect from "../MiscUIComponents/ProductSelect";
import PaymentSlider from "../../helpers/PaymentSlider";
import CancelationSlider from "../../helpers/CancelationSlider";
import EventTravelData from "../../features/EventTravelData";
import VisualDataSummary from "../VisualData/VisualDataSummary";
import VisualDataSummaryBooking from "../VisualData/VisualDataSummaryBooking";
import CreateBookingQrCode from "../../helpers/CreateBookingQrCode";
import BenchmarkTravelData from "../../features/BenchmarkTravelData";
import ObjectSize from "../../helpers/ObjectSize";
import BookingStaffEditor from "../ProductFeatures/BookingStaffEditor";
import DeepCompareComponent from "../../helpers/DeepCompareComponent";
import BatchAgreementModal from "../Documents/BatchAgreementModal";

// export default function BookingFormGeneral({ currentBooking, handleClose }) {
export default function BookingFormGeneral({ bookingDataProp, handleClose }) {
  //   const [open, setOpen] = useState(bookingFormOpen);
  const dispatch = useDispatch();
  const [bookingData, setBookingData] = useState(bookingDataProp);
  const [formlock, setFormlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([{}]);
  // const [eventImage, setEventImage] = useState(currentBooking["artist"].image);
  const [eventImage, setEventImage] = useState(bookingData["artist"].image);
  // const productData = getProductData();
  // getProductData();
  const currentBooking = useSelector((state) => state.counter.currentBooking);
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);

  const currentTicketSummary = useSelector((state) => state.counter.currentTicketSummary);

  // const currentBooking = useSelector((state) => state.counter.currentBooking);
  // console.log("currentBooking", currentBooking);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const userRole = useSelector((state) => state.counter.userRole);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const similarBookings = useSelector((state) => state.counter.similarBookings);
  const reduxCurrentBooking = useSelector((state) => state.counter.currentBooking);
  const currentTickets = useSelector((state) => state.counter.currentTickets);
  const [ticketsLoaded, setTicketsLoaded] = useState(false);
  const [batchSave, setBatchSave] = useState(false);
  const [saveCountdown, setSaveCountdown] = useState(0);
  const [agreement, setAgreement] = useState();
  const agreementId = bookingData.agreementId;
  const [openRequests, setOpenRequests] = useState(false);
  const [TextFields, setTextFields] = useState(false);
  const [unsavedOpen, setUnsavedOpen] = useState(false);
  const closeRequests = () => {
    setOpenRequests(false);
  };

  const handleCloseUnsaved = () => {
    setUnsavedOpen(false);
  };

  const handleCloseBookingForm = () => {
    checkforChanges();
  };

  const checkforChanges = () => {
    handleClose(); //Just close for now // Remove this later
    // console.log("checking for Changes");
    // const changes = DeepCompareComponent(bookingData, bookingDataProp);
    // const changes = DeepCompareComponent(bookingDataProp, bookingData);
    // console.log("CHANGES", changes);
    // changes ? setUnsavedOpen(true) : handleClose();
  };

  console.log("currentVenue", currentVenue);

  // either set deductFromTickets to specific bookings setting, otherwise use general currentVenue setting.
  const [deductFromTickets, setDeductFromTickets] = useState(
    bookingData && currentVenue && bookingData.deductFromTickets !== currentVenue.deductFromTickets
      ? bookingData.deductFromTickets
      : currentVenue.deductFromTickets
  );

  useEffect(() => {
    dispatch(storeCurrentBooking(bookingDataProp));
  }, [bookingDataProp]);

  useEffect(() => {
    setEventImage(bookingData.artist.image);
  }, [bookingData]);

  //Sync bookingData with agreement...
  async function getAgreement(agreementId) {
    const snap = await getDoc(doc(db, "Agreements", agreementId));
    let agreementData = await snap.data();
    if (!agreementData) return;
    // If agreement.agent isn't present create it.
    if (!agreementData.agent) {
      agreementData.agent = {};
      await setDoc(
        doc(db, "Agreements", agreementId),
        {
          agent: {},
        },
        { merge: true }
      );
    }
    // if (agreementData.agent) {
    setBookingData((previous) => ({
      ...previous,
      // upfront: agreementData.upfront || bookingData.upfront || currentVenue.upfront,
      // upfrontAt: agreementData.upfrontAt || bookingData.upfrontAt || currentVenue.upfrontAt,
      // preCharge: agreementData.preCharge || bookingData.preCharge || currentVenue.preCharge, //checking agreement, bookingData and lastly currentVenue
      // preChargeAt: agreementData.preChargeAt || bookingData.preChargeAt || currentVenue.preChargeAt,
      // balance: agreementData.balance || bookingData.balance || currentVenue.balance,
      // balanceAt: agreementData.balanceAt || bookingData.balanceAt || currentVenue.balanceAt,
      // earlyCancelation: agreementData.earlyCancelation || bookingData.earlyCancelation || currentVenue.earlyCancelation,
      // earlyCancelationAt: agreementData.earlyCancelationAt || bookingData.earlyCancelationAt || currentVenue.earlyCancelationAt,
      // lateCancelation: agreementData.lateCancelation || bookingData.lateCancelation || currentVenue.lateCancelation,
      // lateCancelationAt: agreementData.lateCancelationAt || bookingData.lateCancelationAt || currentVenue.lateCancelationAt,
      upfront: agreementData.upfront,
      upfrontAt: agreementData.upfrontAt,
      preCharge: agreementData.preCharge, //checking agreement, bookingData and lastly currentVenue
      preChargeAt: agreementData.preChargeAt,
      balance: agreementData.balance,
      balanceAt: agreementData.balanceAt,
      earlyCancelation: agreementData.earlyCancelation,
      earlyCancelationAt: agreementData.earlyCancelationAt,
      lateCancelation: agreementData.lateCancelation,
      lateCancelationAt: agreementData.lateCancelationAt,
      agent: {
        ...previous.agent,
        firstName: agreementData.agent.firstName || bookingData.agent.firstName || "",
        lastName: agreementData.agent.lastName || bookingData.agent.lastName || "",
        phone: agreementData.agent.phone || bookingData.agent.phone || "",
        email: agreementData.agent.email || bookingData.agent.email || "",
        company: agreementData.agent.company || bookingData.agent.company || "",
        orgNumber: agreementData.agent.orgNumber || bookingData.agent.orgNumber || "",
        streetAddress: agreementData.agent.streetAddress || bookingData.agent.streetAddress || "",
        zipCode: agreementData.agent.zipCode || bookingData.agent.zipCode || "",
        city: agreementData.agent.city || bookingData.agent.city || "",
        country: agreementData.agent.country || bookingData.agent.country || "",
        userid: agreementData.agent.userid || bookingData.agent.userid || "",
      },
      status: agreementData.status,
    }));
    // }

    setAgreement(agreementData);
  }

  const navigate = useNavigate();

  const settings = { size: "small", fullWidth: "true", disabled: formlock };
  const xs = 12;
  const md = 12;
  const sm = 12;
  // const xs = 12;
  // const md = 3;
  // const sm = 4;

  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const handleOpenAgreementModal = () => {
    setAgreementModalOpen(true);
    console.log(agreementModalOpen);
    // alert("opening...?");
    // handleClose();
  };

  const handleCloseAgreementModal = () => {
    setAgreementModalOpen(false);
  };

  const [value, setValue] = useState("General");
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  // Storing to Bookings and Agreements
  const saveBooking = async () => {
    // if (similarBookings.length !== 1) {
    // }
    // batchSave && (await batchSaveSimilarBookings());
    setLoading(true);
    //If bookingsarray is stored inside booking.venue.bookings it needs to be deleted to keep the system snappy and fast.
    let tmp = JSON.parse(JSON.stringify({ ...bookingData }));
    if (tmp.venue.bookings) tmp.venue.bookings = null; // doing some clean up! (removing space consuming data)

    // !tmp.priceIncVat && (tmp.priceIncVat = calculateBookingPrice(tmp.bookingFeatures, "totalIncVat"));
    tmp.priceIncVat = calculateBookingPrice(tmp.bookingFeatures, "totalIncVat");
    // alert(tmp.priceIncVat);
    tmp.price = calculateBookingPrice(tmp.bookingFeatures);

    await setDoc(doc(db, "Bookings", bookingData.bookingid), {
      ...tmp,
    });
    console.log("bookingData", tmp);
    await dispatch(storeCurrentBooking(tmp));
    // await dispatchBooking(tmp); // to avoid having to refresh the page to see changes
    await dispatchToCurrentAgreements();
    // console.log("currentVenue in booking", currentVenue);
    // Also store related data (data that is general for all bookings included in agreement) in the agreement
    // console.log("currentBooking Check", currentBooking);
    await setDoc(
      doc(db, "Agreements", bookingData.agreementId),
      {
        upfront: bookingData.upfront,
        upfrontAt: bookingData.upfrontAt,
        preCharge: bookingData.preCharge,
        preChargeAt: bookingData.preChargeAt,
        // charge: bookingData.charge,
        // chargeAt: bookingData.chargeAt,
        balance: bookingData.balance,
        balanceAt: bookingData.balanceAt,
        earlyCancelation: bookingData.earlyCancelation,
        earlyCancelationAt: bookingData.earlyCancelationAt,
        lateCancelation: bookingData.lateCancelation,
        lateCancelationAt: bookingData.lateCancelationAt,
        status: bookingData.status,
        agent: {
          firstName: bookingData.agent.firstName,
          lastName: bookingData.agent.lastName,
          phone: bookingData.agent.phone,
          email: bookingData.agent.email,
          company: bookingData.agent.company,
          orgNumber: bookingData.agent.orgNumber,
          streetAddress: bookingData.agent.streetAddress,
          zipCode: bookingData.agent.zipCode,
          city: bookingData.agent.city,
          country: bookingData.agent.country,
          userid: bookingData.agent.userid,
        },
        artist: {
          artist: bookingData.artist.artist || "",
          image: eventImage || "",
        },
      },
      { merge: true }
    );
    setLoading(false);
    // handleClose();
    // console.log("Saving", bookingData, " to: ", currentBooking.bookingid);
    // console.log("bookingData", bookingData);
    // alert(calculateBookingPrice(bookingData.bookingFeatures, "totalIncVat"));
    //
    // ❤️ This might go back....
    // await setDoc(doc(db, "Bookings", currentBooking.bookingid), {
    //   ...bookingData,
    //   price: calculateBookingPrice(bookingData.bookingFeatures),
    //   priceIncVat: calculateBookingPrice(bookingData.bookingFeatures, "totalIncVat"), // using type "totalIncVat" to get total price including VAT
    // });

    // Update all bookings in agreement with new image
    // agreement.bookingids.map(async (bookingid) => {
    //   await updateDoc(doc(db, "Bookings", bookingid), {
    //     artist: { image: eventImage },
    //   });
    // });

    // console.log("bookingData", bookingData);
    // await dispatch(storeCurrentBooking(bookingData));
    // setLoading(false);
    // handleClose();
  };

  const dispatchBooking = async (tmp) => {
    let tmpBookings = JSON.parse(JSON.stringify(currentVenue.bookings));

    let index = tmpBookings.findIndex((b) => b.bookingid === tmp.bookingid);
    tmpBookings[index] = tmp;
    console.log("tmpBookings", tmpBookings, index, tmpBookings[index]);
    await dispatch(storeCurrentVenue({ ...currentVenue, bookings: tmpBookings }));
  };

  // Update currentAgreements to avoid having to reload the page to see changes
  const dispatchToCurrentAgreements = async () => {
    let tmpAgreements = JSON.parse(JSON.stringify(currentAgreements));
    let index = tmpAgreements.findIndex((b) => b.agreementId === agreement.agreementId);

    let thisAgreement = {
      ...agreement,
      upfront: bookingData.upfront || currentVenue.upfront,
      upfrontAt: bookingData.upfrontAt || currentVenue.upfrontAt,
      preCharge: bookingData.preCharge || currentVenue.preCharge, //checking agreement, bookingData and lastly currentVenue
      preChargeAt: bookingData.preChargeAt || currentVenue.preChargeAt,
      // chargeAt: bookingData.chargeAt || currentVenue.chargeAt,
      // charge: bookingData.charge || currentVenue.charge,
      balanceAt: bookingData.balanceAt || currentVenue.balanceAt,
      balance: bookingData.balance || currentVenue.balance,
      earlyCancelation: bookingData.earlyCancelation || currentVenue.earlyCancelation,
      earlyCancelationAt: bookingData.earlyCancelationAt || currentVenue.earlyCancelationAt,
      lateCancelation: bookingData.lateCancelation || currentVenue.lateCancelation,
      lateCancelationAt: bookingData.lateCancelationAt || currentVenue.lateCancelationAt,
      agent: {
        ...agreement.agent,
        firstName: bookingData.agent.firstName || "",
        lastName: bookingData.agent.lastName || "",
        phone: bookingData.agent.phone || "",
        email: bookingData.agent.email || "",
        company: bookingData.agent.company || "",
        orgNumber: bookingData.agent.orgNumber || "",
        streetAddress: bookingData.agent.streetAddress || "",
        zipCode: bookingData.agent.zipCode || "",
        city: bookingData.agent.city || "",
        country: bookingData.agent.country || "",
        userid: bookingData.agent.userid || "",
      },
      // artist: {
      //   ...agreement.artist,
      //   artist: bookingData.artist.artist || "",
      //   image: bookingData.artist.image || "",
      // },
      // status: agreementData.status || bookingData.status || "",
      status: bookingData.status,
    };

    tmpAgreements[index] = thisAgreement;

    await dispatch(storeCurrentAgreements(tmpAgreements));
  };

  const dateDaysPriorTo = (date, days) => {
    if (days === undefined || days === "-") days = 0;

    console.log(date, days);
    return new Date(new Date(date).setDate(new Date(date).getDate() - days)).toLocaleDateString("sv-SE");
  };

  async function batchSaveSimilarBookings() {
    // alert("batchsaving");
    setFormlock(true);
    setSaveCountdown(similarBookings.length);
    setLoading(true);
    for (let index = 0; index < similarBookings.length; index++) {
      const item = similarBookings[index];
      setSaveCountdown(similarBookings.length - index);

      await setDoc(doc(db, "Bookings", item.bookingid), {
        ...item,
        artist: { ...item.artist, image: eventImage },
        price: bookingData.price,
        agent: {
          // ...item.agent,
          // email: bookingData.agent.email,
          // phone: bookingData.agent.phone,
          // streetAddress: bookingData.agent.streetAddress,
          // zipCode: bookingData.agent.zipCode,
          // city: bookingData.agent.city,
          // country: bookingData.agent.country,
        },
      });
    }

    setFormlock(false);
    setLoading(false);
  }

  useEffect(() => {
    bookingData.status !== "Pending Request" ? setFormlock(true) : setFormlock(false);
  }, [bookingData]);

  const showSummary = async () => {
    await saveBooking();
    navigate(`/Owner/Summary?bookingid=${bookingData.bookingid}`);
  };

  async function handleDecline() {
    // alert("sending message that booking has kindly been declined");
    // await updateDoc(doc(db, "Bookings", currentBooking.bookingid), {
    //   status: "Killed",
    // });
    handleCloseBookingForm();
  }

  async function handleDelete() {
    // instead of deleting the booking with the id, we should delete the booking where bookingid is the same as the bookingid of the current booking
    const bookingsToDelete = await getDocs(query(collection(db, "Bookings"), where("bookingid", "==", bookingData.bookingid)));
    // console.log("🎃 => ", bookingsToDelete);
    bookingsToDelete.forEach(async (doc) => {
      console.log("🎃 => ", doc.data());
      await deleteDoc(doc.ref);
    });
    // await deleteDoc(doc(db, "Bookings", bookingData.bookingid));
    // await updateDoc(doc(db, "Bookings", bookingData.bookingid), {
    //   status: "Killed",
    //   ticketEventId: "-",
    // });
    handleCloseBookingForm();
  }

  function handleChange(key, value) {
    setBookingData((prev) => ({ ...prev, [key]: value }));
  }

  //THIS??
  // useEffect(() => {
  //   // if (!currentBooking["artist"].image) alert("no image");
  //   setBookingData(currentBooking);
  //   console.log("CURRENT BOOKING:", currentBooking);
  // }, [currentBooking]);

  useEffect(() => {
    console.log("Event Image:", eventImage);
    const artist = { ...bookingData.artist, image: eventImage };
    setBookingData((prev) => ({ ...prev, artist }));
    dispatch(storeCurrentBooking((prev) => ({ ...prev, artist })));
    // currentBooking = (prev) => ({ ...prev, artist });
  }, [eventImage]);

  // useEffect(() => {
  //   async function getTicketData() {
  //     const tickets = await GetTicketsForBooking(bookingData);
  //     console.log("🌿🌿🌿 tickets", tickets);
  //     // If a ticketsEventId is entered (tixlyId) get Tickets and store to Redux
  //     dispatch(storeCurrentTickets(tickets));
  //     setTicketsLoaded(true);
  //   }
  //   bookingData.tixlyId && bookingData.tixlyId !== "-" && getTicketData();
  //   console.log("tixlyId?", bookingData.tixlyId);
  //   getTicketData();
  // }, []);

  useEffect(() => {
    // setTimeout(() => {
    bookingData.agreementId && getAgreement(bookingData.agreementId);

    // }, 3000);
  }, [bookingData.agreementId]);

  //check if similar bookings exist to make batch changes.
  // useEffect(() => {
  //   let similar = [];
  //   async function getSimilarBookings() {
  //     const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("artist.artist", "==", bookingData.artist.artist)));
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       // console.log(doc.id, "🎃 => ", doc.data());

  //       setTimeout(() => {
  //         console.log("doc", doc.data().venueid);
  //         console.log("bookingData", bookingData);
  //       }, 2000);

  //       doc.data().venueid === bookingData.venueid && similar.push(doc.data());
  //     });
  //     console.log("🎃 => ", similar);
  //     dispatch(storeSimilarBookings(similar));
  //   }
  //   getSimilarBookings();
  // }, [bookingData]);

  useEffect(() => {
    setBookingData((prev) => ({ ...prev, deductFromTickets: deductFromTickets }));
  }, [deductFromTickets]);

  // useEffect(() => {
  //   if (currentBooking.tixlyId) console.log("🤎", currentTickets);
  //   currentBooking.tixlyId && getTicketSalesSummary(currentBooking.tixlyId);
  // }, [currentTickets]);

  function changeImage() {
    const artist = { ...bookingData.artist, image: null };
    setBookingData((prev) => ({ ...prev, artist }));
    dispatch(storeCurrentBooking((prev) => ({ ...prev, artist })));
    // currentBooking = (prev) => ({ ...prev, artist });
    setEventImage(null);
  }

  // async function getProductData() {
  //   const productid = await currentBooking.productid;
  //   const productData = currentVenue && currentVenue.products.find((product) => product.productid === productid);
  //   console.log("PRODUCT => ", product);
  //   console.log("productid => ", productid);
  // }

  // useEffect(() => {
  //   bookingData.tixlyId && getTicketSalesSummary(bookingData.tixlyId);
  // }, [bookingData]);

  useEffect(() => {
    dispatch(storeCurrentBooking(bookingData));
  }, [bookingData]);

  useEffect(() => {
    console.log("🎃", bookingData);
  }, [bookingData]);

  // const ticketSales =
  //   (bookingData.ticketEventId &&
  //     Object.values(currentTicketSummary).filter((item) => item.ticketEventId === bookingData.ticketEventId)[0].summary.count);

  if (bookingData)
    return (
      <Grid container xs={12} className="frameA">
        {/* {currentVenue.venueid}
        <ObjectSize dataObject={currentVenue} /> */}
        <TabContext value={value} xs={12}>
          <Grid container xs={12} className="frameB">
            {/* INFO (Static) */}
            <Grid item xs={12} sm={12} md={6} className="frameLeft">
              <Grid container mt={4} xs={12}>
                <Grid
                  item
                  xs={12}
                  // sm={3}
                  // md={2}
                >
                  <div style={{ display: "block", float: "left", top: "0px", position: "relative" }}>
                    {!eventImage && (
                      <div className="bookingImagePick">
                        <GetEventImageFromGoogle query={bookingData.artist.artist} setEventImage={setEventImage} eventImage={eventImage} />
                      </div>
                    )}
                    <div className="circular-div-backBlur">{eventImage && <img src={eventImage} width="400px" />}</div>
                    <ConfirmDialog
                      onConfirm={() => changeImage()}
                      size={"small"}
                      color={"secondary"}
                      header="Remove image"
                      message="You will get 10 new ones to pick from"
                    >
                      <div className="circular-div">{eventImage && <img src={eventImage} width="400px" />}</div>
                    </ConfirmDialog>
                  </div>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <input
                    className="bookingArtist"
                    style={{ cursor: "pointer" }}
                    value={bookingData["artist"].artist}
                    contentEditable
                    maxLength={30}
                    onInput={(e) =>
                      // e.target.value !== "" &&
                      setBookingData((prev) => ({
                        ...prev,
                        artist: {
                          ...prev.artist,
                          artist: e.target.value,
                        },
                      }))
                    }
                  />
                  <div style={{ marginTop: "-2px", marginLeft: "5px", color: "#00ffd499", cursor: "date", width: "250px" }}>
                    <VhDatePicker currentVenue={currentVenue} bookingData={bookingData} setBookingData={setBookingData} />
                  </div>
                </Grid>
                <Grid container>
                  <Divider />
                  <table className="bookingFormTable">
                    <tr>
                      <td>Status</td>
                      <td>
                        <SelectStatus
                          bookingData={bookingData}
                          setBookingData={setBookingData}
                          agreement={agreement}
                          setLoading={setLoading}
                        />
                      </td>
                      {/* {bookingData.status === "Pending Request" && (
                        <td>
                          <Button onClick={() => setValue("Agreement")}>Accept</Button>
                        </td>
                      )}
                      {bookingData.status === "Pending Request" && (
                        <td>
                          <DeclineDialog
                            onConfirm={() => handleDecline()}
                            size={"small"}
                            variant={"contained"}
                            color={"secondary"}
                            header="Decline Request"
                            message="This request will be permanently removed. Do you want to continue?"
                          >
                            Decline
                          </DeclineDialog>
                        </td>
                      )} */}
                    </tr>
                    <tr style={{ height: "40px !important" }}>
                      <td>Product</td>
                      {/* <td>{bookingData.product}</td> */}
                      <td>
                        <SelectProductInBookingForm
                          bookingData={bookingData}
                          setBookingData={setBookingData}
                          currentVenue={currentVenue}
                          formlock={formlock}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Time Slot</td>
                      <td>
                        {bookingData.loadIn}-{bookingData.loadOut}
                      </td>
                    </tr>
                  </table>
                  {/* <div style={{ scale: "0.6", transform: "translateX(-26%)" }}> */}
                  <Grid item xs={12}>
                    <table className="bookingFormTable">
                      <tr>
                        <td width="10%">Day timeline</td>

                        <td width="90%">
                          <StandardTimesSlider
                            productData={bookingData}
                            setProductData={setBookingData}
                            TextFields={true}
                            simple={true}
                            formlock={formlock}
                          />
                          <span onClick={() => setTextFields(!TextFields)} style={{ marginLeft: "15px" }}>
                            <EditIcon fontSize="12px" style={{ color: "#777" }} />
                          </span>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                  {/* </div> */}
                </Grid>

                {/* === === === === === === === === === === === === === === === === === === === === === */}
                {/* === === === === === === === === === === === === === === === === === === === === === */}
                {/* === === === === === === === === === === === === === === === === === === === === === */}
                {/* === === === === === === === === BELOW is for development pusposes = === === === === */}
                <Grid container spacing={2}>
                  {/* {currentUser.userid === "vC2Gb4rTaXXyeJgUYkLn8ypuzMq2" && ( */}
                  {superOwner && (
                    <>
                      <Grid item xs={xs} md={md} sm={sm}>
                        {console.log("currentTicketSummary", bookingData.ticketEventId, currentTicketSummary)}
                      </Grid>
                      <Grid item xs={xs} md={md} sm={sm}>
                        <TextField fullWidth label="TicketEventId" defaultValue={bookingData.ticketEventId} disabled size="small" />
                      </Grid>
                      <Grid item xs={xs} md={md} sm={sm}>
                        <TextField fullWidth label="TicketVenueId" defaultValue={currentVenue.ticketVenueId} disabled size="small" />
                      </Grid>
                      <Grid item xs={xs} md={md} sm={sm}>
                        <TextField fullWidth label="BookingId" defaultValue={bookingData.bookingid} disabled size="small" />
                      </Grid>
                      <Grid item xs={xs} md={md} sm={sm}>
                        <TextField
                          fullWidth
                          label="AgreementId"
                          defaultValue={bookingData.agreementId}
                          size="small"
                          onChange={(e) =>
                            // e.target.value !== "" &&
                            setBookingData((prev) => ({
                              ...prev,
                              agreementId: e.target.value,
                            }))
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {/* )} */}
                </Grid>

                {/* <Grid container spacing={2}>
                  {agreement && agreement.venueSignature ? (
                    <Grid item xs={xs} md={md} sm={sm} color="primary">
                      <div className="agreementCheck">
                        <CheckIcon />
                      </div>
                      <p>Signed by:</p>
                      <div style={{ color: "#00ffd4" }}>
                        <div> {agreement.venueSignature.userId}</div>
                        <div>{agreement.venueSignature.date}</div>
                      </div>
                    </Grid>
                  ) : (
                    <div
                      className="awaitingVenueSignature"
                      onClick={() => {
                        setOpenRequests(true);
                      }}
                    >
                      Awaiting venue signature...
                    </div>
                  )}
                  {agreement && agreement.agentSignature ? (
                    <Grid item xs={xs} md={md} sm={sm} color="primary">
                      <div className="agreementCheck">
                        <CheckIcon />
                      </div>
                      <p>Signed by:</p>
                      <div style={{ color: "#00ffd4" }}>
                        <div> {agreement.agentSignature.userId}</div>
                        <div>{agreement.agentSignature.date}</div>
                      </div>
                    </Grid>
                  ) : (
                    <div style={{ color: "#00ffd455", marginTop: "50px", padding: "20px", border: "1px dashed" }}>
                      Awaiting customer signature...
                    </div>
                  )}
                </Grid> */}
              </Grid>
            </Grid>
            {/* TABPANELS */}
            <Grid item xs={12} sm={12} md={6} style={{ height: "80vh", overflow: "auto" }} className="frameRight">
              <Grid item xs={12} className="bookingMenu">
                <div className="bookingFormTabsMenu">
                  <TabList
                    onChange={handleChangeTab}
                    // variant="scrollable"
                    // orientation="horizontal"
                    aria-label="lab API tabs example"
                    style={{ float: "right" }}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                  >
                    <Tab label="General" value="General" />
                    <Tab label="Customer" value="Customer" />
                    <Tab label="CO2e Impact" value="Sustainability" />
                    <Tab label="Agreement" value="Agreement" />
                  </TabList>
                </div>
                {loading && (
                  <div style={{ position: "absolute", right: "20px", zoom: "0.6" }}>
                    <CircularProgress />
                  </div>
                )}
              </Grid>
              {/* GENERAL */}
              <TabPanel value="General" className="TabPanels">
                <Grid container xs={12} style={{ height: "50vh" }} p={1} className="gridSample">
                  <h7>General</h7>

                  <Grid item mt={2} xs={12} style={{ height: "50vh" }} className="gridSample">
                    <BookingFeatureEditor bookingData={bookingData} setBookingData={setBookingData} />
                  </Grid>
                  <Grid item mt={2} xs={12} style={{ height: "50vh" }} className="gridSample">
                    {/* <BookingStaffEditor bookingData={bookingData} setBookingData={setBookingData} /> */}
                  </Grid>
                </Grid>
              </TabPanel>
              {/* SETTINGS */}
              <TabPanel value="Settings" className="TabPanels">
                <Grid container xs={12} style={{ height: "50vh" }} p={1} className="gridSample">
                  <h7>Settings</h7>
                  <Grid item mt={2} xs={12} style={{ height: "50vh" }} className="gridSample">
                    <formSectionHeader>Payments</formSectionHeader>

                    <table>
                      <tr>
                        <td></td>
                      </tr>

                      <tr>
                        <td>Deposit {bookingData.preCharge !== "-" ? bookingData.preCharge : currentVenue.preCharge}%</td>
                      </tr>
                      <tr>
                        <td>
                          <Slider
                            disabled={formlock}
                            style={{ width: "200px" }}
                            value={bookingData.preCharge !== "-" ? bookingData.preCharge : currentVenue.preCharge}
                            min={0}
                            step={5}
                            max={100}
                            marks={[
                              { value: 0, label: "0%" },
                              { value: 100, label: "100%" },
                            ]}
                            onChange={(e) => setBookingData((prev) => ({ ...prev, preCharge: e.target.value }))}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}%`}
                            size="large"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Deposit {bookingData.preChargeAt !== "-" ? bookingData.preChargeAt : currentVenue.preChargeAt} days ahead{" "}
                          <span style={{ color: "#777" }}>
                            (
                            {bookingData.preChargeAt
                              ? dateDaysPriorTo(bookingData.date, bookingData.preChargeAt)
                              : dateDaysPriorTo(bookingData.date, currentVenue.preChargeAt)}
                            )
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Slider
                            disabled={formlock}
                            defaultValue={[5, 8]}
                            value={bookingData.preChargeAt !== "-" ? bookingData.preChargeAt : currentVenue.preChargeAt}
                            min={0}
                            step={5}
                            max={300}
                            marks={[
                              { value: 0, label: "0 days" },
                              { value: 300, label: "300 days" },
                            ]}
                            onChange={(e) => setBookingData((prev) => ({ ...prev, preChargeAt: e.target.value }))}
                            size="large"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Balance {bookingData.balanceAt !== "-" ? bookingData.balanceAt : currentVenue.balanceAt} days ahead{" "}
                          <span style={{ color: "#777" }}>
                            (
                            {bookingData.balanceAt
                              ? dateDaysPriorTo(bookingData.date, bookingData.balanceAt)
                              : dateDaysPriorTo(bookingData.date, 30)}
                            )
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Slider
                            disabled={formlock}
                            defaultValue={[5, 8]}
                            value={bookingData.balanceAt !== "-" ? bookingData.balanceAt : currentVenue.balanceAt}
                            min={0}
                            step={5}
                            max={300}
                            marks={[
                              { value: 0, label: "0 days" },
                              { value: 300, label: "300 days" },
                            ]}
                            onChange={(e) => setBookingData((prev) => ({ ...prev, balanceAt: e.target.value }))}
                            size="large"
                          />
                        </td>
                      </tr>
                    </table>
                    <formSectionHeader>
                      <LocalActivityIcon sx={{ marginRight: "5px" }} />
                      Tickets
                    </formSectionHeader>
                    <Grid container spacing={2} p={4} alignContent="center">
                      <Tooltip title="Deduct rental fee + additional costs from ticket sales">
                        <FormControlLabel
                          control={<Switch checked={deductFromTickets} onChange={(e) => setDeductFromTickets(e.target.checked)} />}
                          label="Deduct From Tickets"
                        />
                      </Tooltip>
                      {deductFromTickets && (
                        <Grid item xs={xs} md={md} sm={sm}>
                          <TextField
                            fullWidth
                            label="Ticket Service EventId"
                            defaultValue={bookingData.ticketEventId}
                            size="small"
                            onChange={(e) => setBookingData((prev) => ({ ...prev, tixlyId: e.target.value }))}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              {/* SUSTAINABILITY */}
              <TabPanel value="Sustainability" className="TabPanels">
                <Grid container xs={12} style={{ height: "80vh" }} p={1} className="gridSample">
                  <Grid item xs={12} style={{ height: "80vh", width: "100%", display: "grid", gap: "15px" }} className="gridSample">
                    <h7>Sustainability</h7>
                    {/* KARTAN */}

                    <Grid item>
                      <EventTravelData booking={bookingData} />
                    </Grid>

                    <Grid item>
                      <h7>Premises</h7>
                      <VisualDataSummaryBooking venueid={currentVenue.venueid} />
                    </Grid>
                    <Grid item>
                      <h7>Poll QR code</h7>
                      <CreateBookingQrCode
                        message={`https://venuehub.live/TravelForm?id=${currentBooking.bookingid}`}
                        filnamn={`travel${currentBooking.bookingid}`}
                      />
                    </Grid>
                    <Grid item>
                      <h7>Poll Data</h7>
                      <BenchmarkTravelData booking={bookingData} />
                    </Grid>

                    {/* <GetCoordinatesFromEventId booking={bookingData} /> */}
                    {currentBooking.tixlyId && currentBooking.tixlyId !== "-" ? (
                      <Grid item xs={4} sm={1} item className="bookingFormPie" style={{ marginTop: "40px" }}>
                        <EventTicketSummary
                          eventId={currentBooking.tixlyId}
                          salesPie
                          pieSize="60"
                          pieStroke="5"
                          showSales="inside"
                          noNumber
                        />
                        {/* {<WeeklySalesChart eventId={currentBooking.tixlyId} />} */}
                      </Grid>
                    ) : null}
                  </Grid>
                  {/* <Grid item xs={6} style={{ height: "66vh" }} className="gridSample"></Grid> */}
                </Grid>
              </TabPanel>
              {/* AGREEMENT */}
              <TabPanel value="Agreement" className="TabPanels">
                <Grid container xs={12} style={{ height: "50vh" }} p={1} className="gridSample">
                  <h7>Agreement</h7>
                  <Grid item mt={2} xs={12} style={{ height: "50vh" }} className="gridSample">
                    <div className="signatures">
                      {agreement && agreement.venueSignature ? (
                        <Grid item xs={xs} md={md} sm={sm} color="primary">
                          <div className="agreementCheck">
                            <CheckIcon />
                          </div>
                          <p>Signed by:</p>
                          <div style={{ color: "#00ffd4" }}>
                            <div> {agreement.venueSignature.userId}</div>
                            <div>{agreement.venueSignature.date}</div>
                          </div>
                        </Grid>
                      ) : (
                        <div
                          className="awaitingVenueSignature"
                          onClick={() => {
                            setOpenRequests(true);
                          }}
                        >
                          Awaiting venue signature...
                        </div>
                      )}
                      {agreement && agreement.agentSignature ? (
                        <Grid item xs={xs} md={md} sm={sm} color="primary">
                          <div className="agreementCheck">
                            <CheckIcon />
                          </div>
                          <p>Signed by:</p>
                          <div style={{ color: "#00ffd4" }}>
                            <div> {agreement.agentSignature.userId}</div>
                            <div>{agreement.agentSignature.date}</div>
                          </div>
                        </Grid>
                      ) : (
                        <div className="awaitingCustomerSignature">Awaiting customer signature...</div>
                      )}
                    </div>
                    {/* <div style={{ cursor: "pointer" }}>
                      {currentBooking.status === "Confirmed" ? (
                        <BatchAgreementModal agreementId={currentBooking.agreementId} /> //agreementId={line[getID.id].agreementId}
                      ) : // <BatchAgreementModal agreementId={line[getID.id].agreementId} /> //agreementId={line[getID.id].agreementId}
                      // <AgreementModal bookingid={line[getID.id].bookingid} /> // OLD
                      currentBooking.status === "Pending Request" ? (
                        <BatchAgreementModal sign="venueSignature" agreementId={currentBooking.agreementId} />
                      ) : currentBooking.status === "Awaiting Artist" ? (
                        <BatchAgreementModal agreementId={currentBooking.agreementId} />
                      ) : (
                        // <AgreementModal bookingid={line[getID.id].bookingid} sign="venueSignature" /> // OLD
                        "null"
                      )}
                    </div> */}

                    {/* <table className="bookingFormTable"> */}
                    <formSectionHeader>Payments</formSectionHeader>
                    <div style={{ height: "280px" }}>
                      <PaymentSlider
                        currentVenue={currentVenue}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        formlock={formlock}
                      />
                    </div>

                    <formSectionHeader>Cancelation</formSectionHeader>
                    <CancelationSlider bookingData={bookingData} setBookingData={setBookingData} formlock={formlock} />

                    <formSectionHeader>
                      <LocalActivityIcon sx={{ marginRight: "5px" }} />
                      Tickets
                    </formSectionHeader>
                    <Grid container spacing={2} p={4} alignContent="center">
                      <Tooltip title="Deduct rental fee + additional costs from ticket sales">
                        <FormControlLabel
                          control={<Switch checked={deductFromTickets} onChange={(e) => setDeductFromTickets(e.target.checked)} />}
                          label="Deduct From Tickets"
                        />
                      </Tooltip>
                      {deductFromTickets && (
                        <Grid item xs={xs} md={md} sm={sm}>
                          <TextField
                            fullWidth
                            label="Ticket Service EventId"
                            defaultValue={bookingData.ticketEventId}
                            size="small"
                            onChange={(e) =>
                              setBookingData((prev) => ({ ...prev, tixlyId: e.target.value, ticketEventId: e.target.value }))
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={6} style={{ height: "66vh" }} className="gridSample"></Grid> */}
                </Grid>
              </TabPanel>
              <TabPanel value="Competition" className="TabPanels">
                <Grid container xs={12} style={{ height: "66vh" }} p={1} className="gridSample">
                  <Grid item xs={12} style={{ height: "66vh", width: "100%" }} className="gridSample">
                    <h7>Competition this day</h7>
                  </Grid>
                  {/* <Grid item xs={6} style={{ height: "66vh" }} className="gridSample"></Grid> */}
                </Grid>
              </TabPanel>
              {/* CUSTOMER */}
              <TabPanel value="Customer" className="TabPanels">
                <Grid container xs={12} style={{ height: "66vh" }} p={1} className="gridSample">
                  <Grid item xs={12}>
                    <h7>Customer</h7>
                  </Grid>
                  {/* <Grid item xs={12} style={{ height: "20vh" }} className="gridSample">
                    <table className="bookingFormTable">
                      <tr>
                        <td>First Name</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                firstName: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.firstName}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Name</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                lastName: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                email: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.email}
                        </td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                phone: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.phone}
                        </td>
                      </tr>
                      <tr>
                        <td>Street Address</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                streetAddress: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.streetAddress}
                        </td>
                      </tr>
                      <tr>
                        <td>Zip Code</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                zipCode: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.zipCode}
                        </td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                city: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.city}
                        </td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                country: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.country}
                        </td>
                      </tr>
                      <tr>
                        <td>Company</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                company: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.company}
                        </td>
                      </tr>
                      <tr>
                        <td>Company Id</td>
                        <td
                          contentEditable
                          onBlur={(e) =>
                            setBookingData((prevState) => ({
                              ...prevState,
                              agent: {
                                ...prevState.agent,
                                orgNumber: e.currentTarget.textContent,
                              },
                            }))
                          }
                        >
                          {bookingData.agent.orgNumber}
                        </td>
                      </tr>
                    </table>
                  </Grid> */}
                  <Grid container className="customerInfo" style={{ maxWidth: "500px" }}>
                    <Grid item xs={xs} md={md} sm={sm}>
                      {console.log("🎃", settings)}
                      <TextField
                        {...settings}
                        label="First Name"
                        // value={currentBooking.agent.firstName + " " + currentBooking.agent.lastName}
                        value={bookingData.agent.firstName}
                        // disabled
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              firstName: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Last Name"
                        // value={currentBooking.agent.firstName + " " + currentBooking.agent.lastName}
                        value={bookingData.agent.lastName}
                        // disabled
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              lastName: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Email"
                        // defaultValue={bookingData.agent.email}
                        value={bookingData.agent.email}
                        style={{ background: validateEmail(bookingData.agent.email) ? "" : "#ffa50055" }}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              email: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Telephone"
                        defaultValue={bookingData.agent.phone}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              phone: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Street Address"
                        defaultValue={bookingData.agent.streetAddress}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              streetAddress: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Zip Code"
                        defaultValue={bookingData.agent.zipCode}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              zipCode: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="City"
                        defaultValue={bookingData.agent.city}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              city: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Country"
                        defaultValue={bookingData.agent.country}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              country: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Company"
                        defaultValue={bookingData.agent.company}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              company: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        {...settings}
                        label="Corporate ID Number (Org.nr)"
                        defaultValue={bookingData.agent.orgNumber}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              orgNumber: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    {/* <Grid item xs={xs} md={md} sm={sm}>
                      <TextField
                        disabled
                        {...settings}
                        label="Agent Id"
                        defaultValue={currentBooking.agent.agentid}
                        onChange={(e) =>
                          setBookingData((prevState) => ({
                            ...prevState,
                            agent: {
                              ...prevState.agent,
                              agentid: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>

          <Grid item xs={12} className="fixedButtonBarBottom" flexDirection="row" justify="flex-end">
            <Button
              // {...settings}
              variant="contained"
              onClick={saveBooking}
              endIcon={loading ? <CircularProgress style={{ zoom: "0.4", color: "black" }} /> : <ViewCompactIcon />}
              size={"small"}
              disabled={formlock}
              sx={{ width: "200px" }}
            >
              {loading ? "Saving" : "Save"}
            </Button>

            <Button
              // {...settings}
              variant="outlined"
              onClick={() => handleCloseBookingForm()}
              //   onClick={() => setBookingFormOpen(false)}
              startIcon={<CloseIcon />}
              size={"small"}
            >
              Close
            </Button>

            {bookingData.venueSignature && bookingData.agentSignature && (
              <Tooltip
                title={
                  bookingData.venueSignature && bookingData.agentSignature
                    ? "Add up event summary and send settlement"
                    : "Make sure agreements are fully signed on both ends"
                }
              >
                <Button
                  // {...settings}
                  variant="contained"
                  color="primary"
                  // onClick={() => navigate(`/Owner/Summary?bookingid=${currentBooking.bookingid}`)}
                  onClick={() => showSummary()}
                  startIcon={<SummarizeIcon />}
                  size={"small"}
                  disabled={bookingData.venueSignature && bookingData.agentSignature ? false : true}
                >
                  Summary
                </Button>
              </Tooltip>
            )}

            <ConfirmDialog
              onConfirm={() => handleDelete()}
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              header="Delete Booking"
              message="This booking will be permanently removed. Do you want to continue?"
            >
              <DeleteIcon size="small" /> Kill Booking
            </ConfirmDialog>
          </Grid>

          {bookingData && (
            <FormControl style={{ display: "flex", gap: "20px" }} mt={4}>
              <Grid container xs={12} gap={10}>
                {/* <Grid item>
                    <ProductSummaryBookingForm currentVenue={currentVenue} currentBooking={currentBooking} />
                  </Grid> */}
              </Grid>
              {/* <DisplayObject data={bookingData} /> */}

              <Grid container xs={12} spacing={2} mt={10}>
                {/* {!currentBooking.venueSignature && (
              <Grid item xs={12} md={2} sm={2}>
                <Button
                  {...settings}
                  variant="contained"
                  onClick={() => {
                    handleOpenAgreementModal();
                  }}
                  endIcon={<SendIcon />}
                  size={"small"}
                >
                  Accept Booking
                </Button>
              </Grid>
            )} */}
              </Grid>
              {/* <DisplayObject data={bookingData} /> */}
              {/* <DisplayObject data={similarBookings} /> */}
            </FormControl>
          )}
          <Dialog open={unsavedOpen} onClose={handleCloseUnsaved}>
            <DialogTitle id="alert-dialog-title">Close without saving?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">You have Unsaved Changes. Disregard any changes?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={saveBooking} variant="contained">
                Save
              </Button>
              {/* <Button onClick={handleCloseUnsaved}>Close</Button> */}
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* </Modal> */}
          <Modal open={agreementModalOpen} onClose={handleCloseAgreementModal} aria-labelledby="bookingform" aria-describedby="bookingform">
            <h6>
              {!bookingData.venueSignature && !bookingData.agentSignature && <>Review agreement and click "Send"</>}
              {bookingData.venueSignature && !bookingData.agentSignature && <>Awaiting customer signature</>}
              {(bookingData.venueSignature || bookingData.agentSignature) && <>Agreement signed</>}
              <AgreementModal bookingid={bookingData.bookingid} sign="venueSignature" open={true} />
            </h6>
            {/* </div> */}
          </Modal>
        </TabContext>
        {/* </div> */}
        <Modal open={openRequests} onClose={closeRequests}>
          <Grid container xs={11} sm={10} md={10} className="standardModal">
            <IncomingRequests closeRequests={closeRequests} incomingAgreementId={agreement && agreement.agreementId} />
          </Grid>
        </Modal>
        {superOwner && (
          <>
            <div style={{ color: "orange" }}>
              agreement:{agreement && agreement.upfront}/{agreement && agreement.preCharge}/{agreement && agreement.balance} || booking:
              {bookingData && bookingData.upfront}/{bookingData && bookingData.preCharge}/{bookingData && bookingData.balance} || venue:
              {currentVenue && currentVenue.upfront}/{currentVenue && currentVenue.preCharge}/{currentVenue && currentVenue.balance}
            </div>
          </>
        )}
      </Grid>
    );
}
