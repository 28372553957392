/** @format */

import React from 'react';
import {
	GoogleMap,
	useJsApiLoader,
	MarkerF,
	OverlayViewF,
	OverlayView,
	Marker,
	LoadScript,
	Autocomplete,
	StandaloneSearchBox,
} from '@react-google-maps/api';
import { useState } from 'react';
import { useCallback } from 'react';

const containerStyle = {
	width: '400px',
	height: '400px',
};

const center = {
	lat: -3.745,
	lng: -38.523,
};

const AddCoordinatesOnMap = ({
	handleClose,
	setCoordinates,
	setFormValues,
	formValues,
}) => {
	const [map, setMap] = React.useState(null);
	const [markers, setMarkers] = useState([]);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	});

	const onMapClick = (e) => {
		//We only want to set one marker at this point
		setMarkers(() => [
			{
				lat: e.latLng.lat(),
				lng: e.latLng.lng(),
			},
		]);
		setFormValues({
			...formValues,
			venueLat: e.latLng.lat(),
			venueLng: e.latLng.lng(),
		});
		setCoordinates({
			latitude: e.latLng.lat(),
			longitude: e.latLng.lng(),
		});
		setTimeout(() => {
			handleClose();
		}, 1000);
	};

	React.useEffect(() => {
		if (map) map.setZoom(6);
		// if (map) setZoomValue(map.getZoom());
	}, [map]);

	const center = {
		lat: 60,
		lng: 15,
	};

	//   const onLoad = useCallback(function callback(loadedMap) {
	//     setMap(loadedMap);
	//     const bounds = new window.google.maps.LatLngBounds(center);
	//   }, []);

	//   const onLoad = (ref) => (this.searchBox = ref);

	//   const onPlacesChanged = () => console.log(this.searchBox.getPlaces());

	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={6}
				onClick={onMapClick}
				mapContainerStyle={{
					width: '100%',
					height: '35vh',
				}}
			>
				{markers.map((marker) => (
					<MarkerF
						position={{
							lat: marker.lat,
							lng: marker.lng,
						}}
					/>
				))}
				{/* Child components, such as markers, info windows, etc. */}
				{/* <StandaloneSearchBox onLoad={onload} onPlacesChanged={onPlacesChanged}>
          <input
            type="text"
            placeholder="Customized your placeholder"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox> */}
			</GoogleMap>
		</LoadScript>
	);
};

export default AddCoordinatesOnMap;
