/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Grid, Card, CardMedia, CardActionArea, Skeleton, Icon, IconButton, Modal, Tooltip, Dialog, Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { artistPlaceholderImage } from "../../assets/images";
import { findDocumentById } from "../../firebase";
import { SquareRounded } from "@mui/icons-material";
import { ArtistInfoModal } from "../Modals/ArtistInfoModal";
import DayOfTheWeekMini from "../UI helpers/DayOfTheWeekMini";
import WhatDayIsIt from "../MiscUIComponents/WhatDayIsIt";
import { useDispatch } from "react-redux";
import { storeCurrentBooking } from "../../features/venueFilter/venueFilterSlice";
import BookingFormGeneral from "../Forms/BookingFormGeneral";
import shortText from "../../helpers/shortText";
import EventTicketSummary from "../../helpers/GetTicketSalesSummary";
import ObjectSize from "../../helpers/ObjectSize";
import { useSelector } from "react-redux";
import { set } from "date-fns";

export const SingleBookingContainer = (props) => {
  // console.log(props.event, "props.event");
  const userRole = useSelector((state) => state.counter.userRole);
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const [open, setOpen] = useState(false);
  const [artist, setArtist] = useState([]);
  const { artistid, date, status } = props.event;
  const dispatch = useDispatch();
  const divRef = useRef();
  const [width, setWidth] = useState(0);
  const [booking, setBooking] = useState(props.event);
  const [bookingDataComparable, setBookingDataComparable] = useState(booking);
  const [agreement, setAgreement] = useState({});
  // console.log("booking HERE", booking);

  useEffect(() => {
    setBooking(props.event);
  }, [props.event]);

  // console.log("Event:", props.event);
  // useEffect(() => {
  //   if (artistid) {
  //     findDocumentById("Artists", artistid).then((artist) => {
  //       if (artist) {
  //         setArtist(artist);
  //       }
  //     });
  //   }
  // }, [props.event]);

  const artistName = artist && (artist.artist || "");
  // console.log("BOOKING:::", booking);
  const onClickHandler = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 10);
  };

  // useEffect(() => {
  //   // get artist from agreement
  //   console.log("currentAgreements", currentAgreements);
  //   function findAgreementAndSetEventImage() {
  //     if (booking.agreementId) {
  //       let agreement = currentAgreements.find((agreement) => agreement.documentid === booking.agreementId);
  //       if (agreement && agreement.artist) {
  //         let artist = agreement.artist;
  //         setBooking({ ...booking, artist: artist });
  //       }
  //     }
  //   }
  //   findAgreementAndSetEventImage();
  // }, [currentAgreements]);

  useEffect(() => {
    setAgreement(currentAgreements.filter((item) => item.documentid === booking.agreementId)[0]);
  }, [currentAgreements]);

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth); // or use clientWidth/getBoundingClientRect() as needed
    }
  }, []);

  return (
    <Grid
      container
      // sx={{ maxWidth: "200px" }}
      // onClick={() => {
      //   props.action();
      //   props.setSelectedArtist(artist);
      //   props.setSelectedEvent(props.event);
      // }}
      onClick={() => {
        setOpen(true);
      }}
    >
      <Grid item className="bookingCard" xs={12}>
        {/* <CardActionArea onClick={onClickHandler}> */}
        <CardActionArea>
          <CardMedia component="img" sx={{ height: 140 }} image={booking.artist.image || artistPlaceholderImage} title="Artist Image" />
          <CardMedia component="img" sx={{ height: 140 }} className="imgOverlay" />
          <div
            ref={divRef}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
              height: "100px",
            }}
          >
            <div style={{ display: "flex", height: "15px" }} className="statusOfEvent">
              {status || <Skeleton />}
              <SquareRounded
                style={{
                  height: "15px",
                  marginLeft: "auto",
                  color: status === "Confirmed" ? "#1DB954" : status === "Awaiting Artist" ? "#FFA500" : "#FF4500",
                }}
              />
            </div>
            <div className="dateOfEvent">
              <WhatDayIsIt date={date} numberOfLetters={3} />
              {date || <Skeleton />}
            </div>
            {/* {console.log("window.innerWidth", window.innerWidth)}
            {console.log("width", width)} */}
            <div className="titleOfEvent">{shortText(booking.artist.artist, 1 * (width / 9)) || ""}</div>
            <Tooltip title={`${booking.product} (${booking.loadIn} - ${booking.loadOut})`}>
              <div className="product">
                {booking.product} <br></br>({booking.loadIn} - {booking.loadOut})
              </div>
            </Tooltip>

            {superOwner && (
              <div className="bookingSize">
                <ObjectSize dataObject={booking} name="booking" />
                <ObjectSize dataObject={currentAgreements} name="agreements" />
              </div>
            )}
            {console.log("booking", booking)}

            {booking.ticketEventId && booking.ticketEventId !== "-" && (
              <div className="ticketSales">
                {/* <EventTicketSummary eventId={booking.tixlyId} salesPie noNumber /> */}
                <EventTicketSummary ticketEventId={booking.ticketEventId} salesPie noNumber />
              </div>
            )}
          </div>
        </CardActionArea>
      </Grid>
      <Modal
        className="bookingFormModal"
        open={open}
        // onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="bookingFormModal"
        sx={{ borderRadius: "20px" }}
      >
        {/* <Dialog open={open}>Unsaved Changes?</Dialog> */}
        {booking && <BookingFormGeneral bookingDataProp={booking} handleClose={handleClose} noNumber />}
      </Modal>
    </Grid>
  );
};
