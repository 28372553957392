/** @format */

import { MenuItem, Select } from "@mui/material";
import { set } from "date-fns";
import React, { useState } from "react";
import { useEffect } from "react";
import { privateIcon, publicIcon } from "../../helpers/customIcons";

const SelectProductInBookingForm = ({ bookingData, setBookingData, currentVenue, formLock }) => {
  const sortedProducts = Object.values(currentVenue.products).sort((b, a) => {
    console.log("a", a);
    console.log("b", b);
    if (!a.privatePublic || !b.privatePublic) return 0;
    return a.privatePublic.localeCompare(b.privatePublic);
  });
  const [products, setProducts] = useState(sortedProducts);
  const [value, setValue] = useState();
  console.log("bookingData", bookingData);

  const handleChange = async (product) => {
    // setValue(productid);
    // alert(productid);
    // const product = await newProduct(productid);
    let tmp = JSON.parse(JSON.stringify(bookingData));
    tmp.bookingFeatures = product.features;
    tmp.productid = product.productid;
    tmp.product = product.product;
    tmp.getIn = product.getIn;
    tmp.loadIn = product.loadIn;
    tmp.soundcheck = product.soundcheck;
    tmp.doors = product.doors;
    tmp.show = product.show;
    tmp.loadOut = product.loadOut;
    tmp.curfew = product.curfew;
    console.log("tmp", tmp);
    console.log(tmp, product.features, product.productid, product.product);

    console.log("bookingData new", bookingData);
    // setBookingData(tmp, product.features, product.productid, product.product); // this might be horribly wrong and cause modules to be overwritten or deleted.
    setBookingData(tmp, product.features, product.productid, product.product);
  };

  useEffect(() => {
    if (value === undefined || value.productid === bookingData.productid) return;
    handleChange(value);
    console.log("NOW value", value);
  }, [value]);

  useEffect(() => {
    console.log("bookingData.productid", bookingData.productid);
    const currentProduct = products.filter((product) => product.productid === bookingData.productid);
    console.log("currentProduct", currentProduct[0]);
    setValue(currentProduct[0]);
  }, [products]);

  useEffect(() => {
    console.log("bookingData:=!", bookingData);
  }, [bookingData]);

  if (value)
    return (
      <div>
        {console.log("value", value)}
        <Select
          disabled={formLock}
          sx={{ width: "250px" }}
          size="small"
          label="Product"
          value={value}
          // productid={bookingData.productid}
          // onChange={(e) => handleChange(e.target.value)}
          onChange={(e) => setValue(e.target.value)}
        >
          {console.log("products HERE", products)}
          {console.log("bookingData HERE", bookingData)}
          {products.map((product) => (
            <MenuItem label={product.product} value={product}>
              {product.privatePublic === "Public Event" ? publicIcon : privateIcon} {product.product}{" "}
              <span style={{ opacity: "0.3", marginLeft: "5px" }}>
                {product.loadIn}-{product.loadOut}
              </span>
            </MenuItem>
          ))}
        </Select>
      </div>
    );
};

export default SelectProductInBookingForm;
