/** @format */

import { Button, Box, Typography, Grid, CircularProgress } from "@mui/material";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import EmailProgressBar from "../helpers/EmailProgressBar";
import LoadingDots from "../components/UI helpers/LoadingDots";

const PassedVerification = () => {
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const response = urlParams.get("id_token");
  const userId = urlParams.get("user");
  const agreementId = urlParams.get("agreementId");
  const socialId = urlParams.get("socialId");
  const phoneNumber = urlParams.get("phoneNumber");
  const type = urlParams.get("type");
  const returnAddress = urlParams.get("returnAddress");
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const date = new Date().toLocaleString();
  let currentBooking = [];
  const [sentArray, setSentArray] = React.useState([]);
  const navigate = useNavigate();

  // alert("Let's wait");

  useEffect(() => {
    async function getData() {
      // const docSnap = await getDoc(doc(db, "Bookings", bookingId));
      // if (docSnap.exists()) {
      //   currentBooking = docSnap.data();
      //   console.log(currentBooking);
      // } else {
      //   console.log("No such document!");
      // }

      // This is the signature
      let Signature = { userId: userId, agreementId: agreementId, date: date, socialId: socialId, phoneNumber: phoneNumber };

      //If authorization is venueSignature store signature in booking and change status
      // NOTE This code has been altered since (Dec2023) since moving to "Batch" Agreement Signing instead of single bookings.
      if (type === "venueSignature") {
        // Ta fram lista på bookingids från agreementId
        // alert(`venueSignature in process for  ${agreementId}`);
        let bookingids = [];
        const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", agreementId)));
        querySnapshot.forEach((doc) => {
          console.log("STATUS", doc.data().status);
          doc.data().status === "Pending Request" && bookingids.push(doc.data().bookingid);
        });

        // mappa genom bookingids och spara ny status och venueSignature
        bookingids.map(async (item) => {
          console.log("ITEM", item);
          let venueSignature = await Signature;
          let status = "Awaiting Artist";
          console.log("updating booking:", item, venueSignature);
          await updateDoc(doc(db, "Bookings", item), {
            venueSignature: venueSignature,
            status: status,
          });

          await updateDoc(doc(db, "Agreements", agreementId), {
            venueSignature: venueSignature,
            status: status,
            bookingids: bookingids,
          });
        });
        // await emailSuccess("venueSignature", agreementId);
        // navigate("/Owner/Home");
      }

      //If authorization is agentSignature store signature in booking and change status
      if (type === "agentSignature") {
        // alert(agreementId);
        // Ta fram lista på bookingids från agreementId
        let bookingids = [];
        const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", agreementId)));
        querySnapshot.forEach((doc) => {
          bookingids.push(doc.data().bookingid);
        });

        // mappa genom bookingids och spara ny status och agentSignature
        await bookingids.map(async (item) => {
          let agentSignature = await Signature;
          let status = "Confirmed";
          console.log("updating booking:", item, agentSignature);
          await updateDoc(doc(db, "Bookings", item), {
            agentSignature: agentSignature,
            status: status,
          });
          console.log("updating Agreement:", item, agentSignature);
          await updateDoc(doc(db, "Agreements", agreementId), {
            agentSignature: agentSignature,
            status: status,
            bookingids: bookingids,
          });
        });

        // navigate("/Requests");
      }

      // OLD
      //If authorization is agentSignature store signature in booking and change status
      // if (type === "agentSignature") {
      //   currentBooking.agentSignature = await Signature;
      //   currentBooking.status = "Confirmed";
      //   await updateDoc(doc(db, "Bookings", bookingId), {
      //     ...currentBooking,
      //   });
      //   navigate("/Requests");
      // }
      // console.log(currentBooking);
      // navigate("/Requests");

      if (type === "signup") {
        navigate("/Signup");
      }
    }

    getData();
  }, []);

  // Make sure email got sent before redirecting
  useEffect(() => {
    console.log("sentArray", sentArray);
    // If email was sent, redirect
    if (sentArray[0] === agreementId + type) {
      // alert(agreementId + type);
      type === "venueSignature" && navigate("/Owner/Home");
      // type === "agentSignature" && navigate("/Requests");
      type === "agentSignature" && navigate("/Home");
    }
    if (sentArray[0] === "ERROR") {
      alert("❌ Something went wrong. Please try again.");
      setTimeout(() => {
        navigate("/Owner/Home");
      }, 3000);
    }
  }, [sentArray]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Grid item>
          <Typography variant="h4" align="center" gutterBottom>
            Thank You
          </Typography>
          {/* <div>User ID: {userId}</div>
          <div>Type: {type}</div>
          <div>Booking ID: {bookingId}</div>
          <div>Return Address: {returnAddress}</div> */}
          Authorization complete.
        </Grid>
        <Grid item>
          <CircularProgress />
        </Grid>

        <Grid item>
          {(type === "venueSignature" || type === "agentSignature") && (
            <div style={{ width: "50vw", textAlign: "center" }}>
              Finalizing...
              <EmailProgressBar docId={agreementId + type} sentArray={sentArray} setSentArray={setSentArray} />
            </div>
          )}
          {/* <h2>Please Wait</h2> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PassedVerification;
