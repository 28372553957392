/** @format */

export const calculateBookingPrice = (bookingFeatures, type) => {
  // console.log("bookingFeatures", bookingFeatures, type);
  let tmpIncludedFeaturesSum = [];
  // This too is only applicable when bookingFeatures are set.
  // console.log("bookingFeatures", bookingFeatures);
  if (!bookingFeatures || bookingFeatures === "-") return 0;
  Object.values(bookingFeatures && bookingFeatures).map((feature) =>
    Object.values(feature.options).map((option) => {
      const result = option.selected && !option.customerOpted && option.price && Number(option.price);
      // console.log("result", result, "option.price", option.price, "vat", option.vat);

      // result !== undefined && result > 0 && tmpIncludedFeaturesSum.push({ amount: Number(result), vat: option.vat });
      result !== undefined && tmpIncludedFeaturesSum.push({ amount: Number(result), vat: option.vat }); // removed: && result > 0
    })
  );

  const totalsByVat = tmpIncludedFeaturesSum.reduce((acc, { amount, vat }) => {
    if (vat === undefined) vat = "25%";
    vat = parseFloat(vat.replace("%", ""));
    if (acc[vat]) {
      acc[vat] += (amount * vat) / 100; // If VAT exists, add to its total
    } else {
      acc[vat] = (amount * vat) / 100; // Otherwise, initialize it
    }
    return acc;
  }, {});

  // const totalSum = tmpIncludedFeaturesSum.reduce((acc, { amount }) => acc + amount, 0);
  console.log("tmpIncludedFeaturesSum", tmpIncludedFeaturesSum);
  console.log(
    "well..",
    tmpIncludedFeaturesSum.map((item) => item.amount)
  );
  const totalSum = tmpIncludedFeaturesSum.reduce((acc, { amount }) => {
    console.log("amount", amount);
    return acc + amount;
  }, 0);

  // const totalVat = tmpIncludedFeaturesSum.reduce((acc, { amount }) => acc + amount, 0);
  const totalVat = Object.values(totalsByVat).reduce((acc, value) => acc + value, 0);
  // console.log(
  //   "acc",
  //   Object.values(totalsByVat).reduce((acc, value) => acc + value, 0)
  // );

  // console.log("totalSum", totalSum);
  // console.log("totalsByVat", totalsByVat);
  // console.log(("totalIncVat", totalSum + totalVat).toFixed(2));

  //   // return by type (default without type is totalSum without VAT included)  OLD
  //   if (type === "totalSum" || type === undefined) return totalSum; // Returns total sum without VAT
  //   if (type === "totalVat") return totalVat; // Returns total VAT
  //   if (type === "totalIncVat") return totalSum + totalVat; // Returns total sum with VAT included
  //   if (type === "totalsByVat") return totalsByVat; // Returns an object with VAT as keys and total VAT as values

  // Return by type (default without type is totalSum without VAT included) NEW
  if (type === "totalSum" || type === undefined) return parseFloat(totalSum.toFixed(2)); // Returns total sum without VAT (2 decimal places)
  if (type === "totalVat") return parseFloat(totalVat.toFixed(2)); // Returns total VAT (2 decimal places)
  if (type === "totalIncVat") return parseFloat((totalSum + totalVat).toFixed(2)); // Returns total sum with VAT included (2 decimal places)
  if (type === "totalsByVat") {
    // Ensures that all totalsByVat values also have 2 decimal places
    const formattedTotalsByVat = Object.keys(totalsByVat).reduce((acc, vatKey) => {
      acc[vatKey] = parseFloat(totalsByVat[vatKey].toFixed(2));
      return acc;
    }, {});
    return formattedTotalsByVat; // Returns an object with VAT as keys and total VAT as values (2 decimal places)
  }
};
