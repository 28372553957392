/** @format */
// VenueAdditionals

import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  IconButton,
  Button,
  Grid,
  Autocomplete,
  Switch,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  TextareaAutosize,
  styled,
  Tooltip,
  ListItemText,
  ListItemIcon,
  FormControl,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
  Dialog,
  Divider,
  CircularProgress,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { createId, createSmallId, db } from "../../firebase";
import DisplayObject from "../../helpers/displayObject";
import { signInWithPhoneNumber } from "firebase/auth";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import { storeCurrentVenue } from "../../features/venueFilter/venueFilterSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import StoreIcon from "@mui/icons-material/Store";
import StraightenIcon from "@mui/icons-material/Straighten";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import TuneIcon from "@mui/icons-material/Tune";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import SpeakerIcon from "@mui/icons-material/Speaker";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StadiumIcon from "@mui/icons-material/Stadium";
import AppsIcon from "@mui/icons-material/Apps";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import GroupIcon from "@mui/icons-material/Group";
import VatSelector from "./VatSelector";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { wait } from "./Wait";
import UnsavedChanges from "../../helpers/UnsavedChanges";
import { removePercentage } from "../../helpers/Numbers";
import { NumericFormat } from "react-number-format";
import PersonIcon from "@mui/icons-material/Person";

const VenueAdditionals = ({ formData, setFormData }) => {
  // Get and set feature data by reading and storing currentVenue.
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  console.log("currentVenue", currentVenue);
  const dispatch = useDispatch();
  const [triggerSave, setTriggerSave] = useState(false);
  const [featureCategoriesReduced, setFeatureCategoriesReduced] = useState(featureCategories);
  const [originalFeatures, setOriginalFeatures] = useState(currentVenue.features);

  const [focusNewOption, setFocusNewOption] = useState(null);

  const firstAdditionalId = createSmallId();
  const firstOptionId = createSmallId();
  const secondAdditionalId = createSmallId();
  const secondOptionId = createSmallId();
  const thirdAdditionalId = createSmallId();
  const thirdOptionId = createSmallId();

  const [additionals, setAdditionals] = useState(
    // If currentVenue holds features
    currentVenue.features
      ? //...set additionals to these features,
        currentVenue.features
      : //otherwise create first feature object.
        {
          // [firstAdditionalId]: {
          //   additionalId: firstAdditionalId,
          //   additional: "First Module",
          //   type: "standardFields",
          //   options: {
          //     [firstOptionId]: { optionId: firstOptionId, option: "", info: "", specs: "", price: "" },
          //   },
          // },
          [secondAdditionalId]: {
            additionalId: secondAdditionalId,
            additional: "Capacity",
            type: "capacityFields",
            options: {
              [secondOptionId]: {
                optionId: secondOptionId,
                option: "",
                capStanding: "",
                capSeated: "",
                price: "",
                capacity: "",
                price: "",
                vat: "",
                climate: "",
              },
            },
          },
          [thirdAdditionalId]: {
            additionalId: thirdAdditionalId,
            additional: "Rental Fee",
            type: "rentalFeeFields",
            options: {
              [thirdOptionId]: {
                optionId: thirdOptionId,
                option: "",
                price: "",
                price: "",
                vat: "",
                climate: "",
              },
            },
          },
        }
  );
  console.log("additionals", additionals);

  useEffect(() => {
    async function firstTimeStore() {
      setExpanded(0);
      handleClose();
      setLoading(true);
      setExpanded(0);
      await wait(1000);
      setLoading(false);
      await saveData();
      setExpanded(0);
    }
    if (!currentVenue.features) firstTimeStore();
  }, [additionals]);

  const additionalFunctionData = [
    { label: "Single Choice", id: "unique", type: "switch", sm: "1", md: "1" },
    { label: "Make optional", id: "optional", type: "switch", sm: "1", md: "1" },
    { label: "Add quantity", id: "quantity", type: "switch", sm: "1", md: "1" },
  ];

  const additionalTools = (additional, fieldType) => (
    <div className="featureTools">
      {additionalFunctionData.map((tool) => (
        <span style={{ width: "40px" }}>
          {tool.type === "switch" && (
            <FormControlLabel
              control={<Switch color="primary" />}
              checked={additional[tool.id]}
              label={tool.label}
              value={tool.id}
              labelPlacement="start"
              onChange={(e) => handleChangeTools(additional, e.target.checked, e.target.value)}
            />
          )}
        </span>
      ))}
    </div>
  );

  const handleChangeTools = (additional, checked, tool) => {
    console.log("Change tool", checked, tool, additional);
    const additionalId = additional.additionalId;
    // let tmp = JSON.parse(JSON.stringify(additional));
    let tmp = { ...additional };
    // tmp.options[optionId][field] = value;
    tmp[tool] = checked;
    console.log("Additional With Tool", tmp);
    setAdditionals((prev) => ({ ...prev, [additionalId]: tmp }));
  };

  const economyFields = [
    {
      label: "Price",
      id: "price",
      value: 0,
      type: "number",
      endAdornment: currentVenue.currency,
      // sm: "3",
      // md: "2",
      section: "info",
      visibility: "external",
    },

    {
      label: "Internal Cost",
      id: "internalCost",
      type: "number",
      endAdornment: currentVenue.currency,
      // sm: "3",
      // md: "2",
      section: "economy",
      visibility: "internal",
    },
    {
      label: "Mark-Up",
      id: "markUp",
      type: "markUp",
      endAdornment: "%",
      // sm: "3",
      // md: "2",
      disabled: true,
      section: "economy",
      visibility: "internal",
    },
    {
      label: "Revenue",
      id: "revenue",
      type: "revenue",
      endAdornment: currentVenue.currency,
      // sm: "3",
      // md: "2",
      section: "economy",
      visibility: "internal",
    },
    {
      label: "Vat",
      id: "vat",
      value: 0,
      type: "VatSelector",
      endAdornment: "%",
      // sm: "4",
      // md: "3",
      section: "economy",
      visibility: "external",
    },
    { label: "CO2e", id: "climate", type: "number", endAdornment: "kg", sm: "4", md: "3", section: "CO2e" },
  ];

  const notesField = [{ label: "Notes", id: "notes", type: "text", multiline: true, endAdornment: "", section: "notes" }];

  const allFields = {
    newStandardFields: [
      { label: "Option Label", id: "option", type: "text", endAdornment: "", sm: "4", md: "3", section: "info" },
      // { label: "Description", id: "info", value: 0, type: "text", multiline: true, endAdornment: "", sm: "4", md: "3" },
      // { label: "Specifications", id: "specs", value: 0, type: "text", multiline: true, endAdornment: "", sm: "4", md: "3" },
      // { label: "Additional Cost", id: "price", value: 0, type: "number", endAdornment: currentVenue.currency, sm: "4", md: "3" },
      // { label: "CO2e", id: "climate", value: 0, type: "number", endAdornment: "kg", sm: "4", md: "3" },
    ],
    standardFields: [
      { label: "Option Label", id: "option", type: "text", value: "Capacity", endAdornment: "", sm: "4", md: "3", section: "info" },
      { label: "Description", id: "info", value: "", type: "text", multiline: true, endAdornment: "", sm: "4", md: "3", section: "info" },
      // {
      //   label: "Specifications",
      //   id: "specs",
      //   value: 0,
      //   type: "text",
      //   multiline: true,
      //   endAdornment: "",
      //   sm: "4",
      //   md: "3",
      //   section: "info",
      // },
      ...notesField,
      ...economyFields,
      { label: "CO2e", id: "climate", value: 0, type: "number", endAdornment: "kg", sm: "4", md: "3", section: "CO2e" },
    ],
    rentalFeeFields: [
      { label: "Option Label", id: "option", value: "Rental Fee", type: "text", endAdornment: "", sm: "4", md: "3", section: "info" },
      ...notesField,
      ...economyFields,
      { label: "CO2e", id: "climate", value: 0, type: "number", endAdornment: "kg", sm: "4", md: "3", section: "CO2e" },
    ],
    cateringFields: [
      {
        label: "Option Label",
        id: "option",
        value: "Catering",
        defaultValue: "Catering",
        type: "text",
        endAdornment: "",
        sm: "4",
        md: "3",
        section: "info",
      },
      ...notesField,
      ...economyFields,
      // { label: "Sum", id: "price", defaultValue: 0, type: "number", endAdornment: currentVenue.currency, sm: "4", md: "3" },
      { label: "CO2e", id: "climate", defaultValue: 0, type: "number", endAdornment: "kg", sm: "4", md: "3", section: "CO2e" },
    ],
    staffFields: [
      { label: "Name", id: "option", type: "text", section: "info" },
      { label: "Phone", id: "phone", type: "text", section: "info" },
      { label: "Email", id: "email", type: "text", section: "info" },
      { label: "Area of Responsibility", id: "responsibility", type: "text", section: "info" },
      // { label: "Notify", id: "notify", type: "switch", sm: "4", md: "3" },

      ...notesField,
      ...economyFields,
    ],
    stageFields: [
      {
        label: "Option Label",
        id: "option",
        value: "Stage",
        type: "text",
        endAdornment: "",
        sm: "3",
        md: "3",
        defaultValue: "Standard",
        section: "info",
      },
      {
        label: "Width",
        id: "stageWidth",
        value: 0,
        type: "number",
        multiline: true,
        endAdornment: "cm",
        sm: "4",
        md: "3",
        section: "info",
      },
      {
        label: "Depth",
        id: "stageDepth",
        value: 0,
        type: "number",
        multiline: true,
        endAdornment: "cm",
        sm: "4",
        md: "3",
        section: "info",
      },
      {
        label: "Total Clearance",
        id: "stageClearanceTotal",
        value: 0,
        type: "number",
        multiline: true,
        endAdornment: "cm",
        sm: "4",
        md: "3",
        section: "info",
      },
      {
        label: "Clearance To Rig",
        id: "stageClearanceToRig",
        value: 0,
        type: "number",
        multiline: true,
        endAdornment: "cm",
        sm: "4",
        md: "3",
        section: "info",
      },
      ...notesField,
      ...economyFields,
      { label: "CO2e", id: "climate", value: 0, type: "number", endAdornment: "kg", sm: "3", md: "3", section: "CO2e" },
    ],
    capacityFields: [
      { label: "Option Label", id: "option", value: "Capacity", type: "text", endAdornment: "", sm: "4", md: "3", section: "info" },
      {
        label: "Standing",
        id: "capStanding",
        value: 0,
        type: "number",
        multiline: true,
        endAdornment: "",
        sm: "4",
        md: "3",
        section: "info",
      },
      { label: "Seated", id: "capSeated", value: 0, type: "number", multiline: true, endAdornment: "", sm: "4", md: "3", section: "info" },
      {
        label: "Combined",
        id: "capacity",
        value: 0,
        type: "capacityCombined",
        multiline: true,
        endAdornment: "",
        fieldWidsm: "4",
        md: "3",
        disabled: true,
        section: "info",
      },
      ...notesField,
      ...economyFields,
      { label: "CO2e", id: "climate", value: 0, type: "number", endAdornment: "kg", sm: "4", md: "3", section: "CO2e" },
    ],
  };

  console.log("allFields", allFields);

  // ADDITIONALS

  const addAdditional = async () => {
    const newAdditionalId = await createSmallId();
    const newOptionId = createSmallId();
    const newAdditional = {
      additionalId: newAdditionalId,
      additional: "New Module",
      type: "standardFields",
      options: {
        [newOptionId]: { optionId: newOptionId, option: "", info: " ", specs: " ", price: 0, quantity: 0, vat: " ", CO2e: 0 },
      },
    };
    setAdditionals((prevAdditionals) => ({
      ...prevAdditionals,
      [newAdditionalId]: newAdditional,
    }));
    console.log("additionals.length", additionals);
    setExpanded(Object.values(additionals).length); // Öppna den senast skapade
    setTimeout(() => {
      setFocusNewOption(newAdditionalId); // Focus on the the new additional title
    }, 1000);
  };

  const removeAdditional = async (AdditionalIdToRemove) => {
    await handleClose(); //THIS???
    const tmp = JSON.parse(JSON.stringify(additionals));
    delete tmp[AdditionalIdToRemove];

    setAdditionals(tmp);
    setTriggerSave(true);
  };

  const capacityCombined = () => {};

  //handle change of label for additional
  const handleChangeAdditional = (additionalId, value, type) => {
    console.log("???", additionalId, value, type);
    let tmp = JSON.parse(JSON.stringify(additionals[additionalId]));
    tmp.additional = value || "No name";
    tmp.type = type;

    //Get the corresponding icon
    let item = featureCategories.find((item) => item.label === value);
    if (item) {
      tmp.icon = item.icon;
    } else {
      tmp.icon = <CheckroomIcon className="categoryIcon" />;
    }
    // tmp.icon = featureCategories.find((item) => item.label === value).icon;

    if (value === null) tmp.additional = " ";
    setAdditionals((prev) => ({ ...prev, [additionalId]: tmp }));
  };

  // OPTIONS

  const addOption = (additional) => {
    const newOptionId = createSmallId();
    let newOption = {
      optionId: newOptionId,
      option: "",
      info: "",
      specs: "",
      price: 0,
      each: 0,
      quantity: 0,
      vat: "",
      CO2e: 0,
      internalCost: 0,
      markUp: 0,
    };

    const updatedOptions = { ...additional.options, [newOptionId]: newOption };
    // let tmp = additional;
    let tmp = JSON.parse(JSON.stringify(additional));
    tmp.options = updatedOptions;

    setAdditionals((prev) => ({ ...prev, [additional.additionalId]: tmp }));
    setFocusNewOption(`${newOptionId}option`);
    // setFocusNewOption(newOptionId);
    // document.getElementById(`${newOptionId}option`).focus();
  };

  useEffect(() => {
    if (!focusNewOption) return;
    // alert("focusNewOption: " + focusNewOption);
    // console.log("focusNewOption: " + document.getElementById(focusNewOption));
    document.getElementById(focusNewOption) && document.getElementById(focusNewOption).focus();
    setFocusNewOption(null);
  }, [focusNewOption, expanded]);

  const removeOption = (additionalId, optionIdToRemove) => {
    const tmp = JSON.parse(JSON.stringify(additionals));
    delete tmp[additionalId].options[optionIdToRemove];
    setAdditionals(tmp);
  };

  const [loading, setLoading] = useState(false);
  const saveData = async () => {
    // alert("saveData");
    await store();
    setLoading(true);
    if (currentVenue.features) {
      // alert("saveData");
      console.log("save check. Venueid: ", formData.venueid, "Additionals", additionals);
      try {
        console.log("additionals", additionals);
        // await updateDoc(doc(db, "Venues", formData.venueid), { features: additionals });
        await updateDoc(doc(db, "Venues", formData.venueid), { features: additionals });
        console.log("Document updated successfully");
      } catch (error) {
        // alert("No values were updated. Please enter some data.");
        console.log("Error updating document: ", error);
      }
    }
    // await updateDoc(doc(db, "Venues", formData.venueid), { features: Object.values(formData.features) }); // without merge in order to also remove stuff
    setExpanded(null);
    setLoading(false);
  };

  const revenue = () => {
    return <div>Revenue</div>;
  };

  const handleChangeOption = (additionalId, optionId, field, value) => {
    // console.log(additional, optionId, field, value);
    // const additionalId = additional.additionalId;
    const additional = Object.values(additionals).filter((obj) => obj.additionalId === additionalId);
    // let tmp = additional;
    // console.log("ADDITIONAL ID", additionalId, optionId, field, value);
    // console.log("ADDITIONAL", ...additional);
    let tmp = JSON.parse(JSON.stringify(...additional));
    console.log("TMP=>", tmp);
    // console.log("TMP mm=>", tmp.options[optionId]);
    // console.log(tmp.options[optionId][field]);
    tmp.options[optionId][field] = value;

    // if "each" or "price" is missing let's add it
    if (!tmp.options[optionId]["each"]) tmp.options[optionId]["each"] = 0;
    if (!tmp.options[optionId]["price"]) tmp.options[optionId]["price"] = 0;

    // if capSeated or capStanding => calculate capacity(combined)
    if (field === "capSeated") {
      tmp.options[optionId]["capacity"] = Number(value) + Number(tmp.options[optionId]["capStanding"] || 0);
      tmp.options[optionId]["specs"] = `Seated: ${value} Standing: ${tmp.options[optionId]["capStanding"] || 0}`;
    }
    if (field === "capStanding") {
      tmp.options[optionId]["capacity"] = Number(value) + Number(tmp.options[optionId]["capSeated"] || 0);
      tmp.options[optionId]["specs"] = `Seated: ${tmp.options[optionId]["capSeated"] || 0} Standing: ${value}`;
    }

    // THIS
    setAdditionals((prev) => ({ ...prev, [additionalId]: tmp }));
  };

  useEffect(() => {
    // console.log(additionals);
    const tmp = JSON.parse(JSON.stringify(formData));
    tmp.features = additionals;
    // console.log("This to currentVenue", tmp);
    // dispatch(storeCurrentVenue(tmp));
    // additionals !== currentVenue.features && dispatch(storeCurrentVenue(tmp)); //THIS!
    setFormData((prev) => ({ ...prev, features: additionals }));

    if (triggerSave) {
      saveData();
      setTriggerSave(false);
    }

    // If an additional i chosen, remove it from the list of available featureCategories
    let tmpFeatureCategories = featureCategories.filter((item) => !Object.values(additionals).some((obj) => obj.additional === item.label));
    setFeatureCategoriesReduced(tmpFeatureCategories);
  }, [additionals]);

  const handleFocus = (event) => {
    event.target.select();
  };

  useEffect(() => {
    // Make sure this doesn't run if any dialog is open, cus it will cause any changes switch to the last module (alphabetically) unintentionally.
    // That's why we first need to check if expanded is null.
    if (expanded !== null) return;
    // before anything else first sort existing additionals in letter order
    if (currentVenue.features) {
      let tmp = Object.values(currentVenue.features); // Make to a sortable array
      tmp.sort((a, b) => a.additional.localeCompare(b.additional)); // ...sort it
      const sorted = {}; // ...then make "sorted" an object with the same format as currentVenue.features originally (only now sorted)
      tmp.forEach((value, index) => {
        sorted[value.additionalId] = value; // and set the keys to additionalId
      });
      setAdditionals(
        // currentVenue.features
        tmp
          ? //...set additionals to these features,
            // currentVenue.features
            sorted
          : // { ...tmp }
            //otherwise create first feature object.
            {
              [firstAdditionalId]: {
                additionalId: firstAdditionalId,
                additional: "New Category",
                type: "standardFields",
                options: {
                  [firstOptionId]: {
                    optionId: firstOptionId,
                    option: "",
                    info: "",
                    specs: "",
                    price: 0,
                    each: 0,
                    quantity: 0,
                    vat: "",
                    CO2e: 0,
                  },
                },
              },
            }
      );
    }
  }, [currentVenue]);

  // useEffect(() => {
  //   store();
  // }, [!expanded]);

  // Store features temporary by storing to currentVenue. Then you need to click save in venue form in order to store to db.
  const store = () => {
    // alert("store");
    const tmp = { ...JSON.parse(JSON.stringify(currentVenue)) }; //Make a copy of currentVenue
    tmp.features = additionals; //...and set features to current additionals
    dispatch(storeCurrentVenue(tmp)); // Finally dispatch to currentVenue
  };

  const [expanded, setExpanded] = useState(null);

  const handleClose = async () => {
    // await checkUnsavedChanges();
    setTimeout(() => {
      setExpanded("closed");
    }, 100);
  };

  const formatNicely = (value) => {
    return value * 2;
  };

  return (
    <>
      {console.log("additionals", Object.values(additionals), "featureCategories", featureCategories)}
      {additionals &&
        // Object.values(additionals).map((item, index) => (
        Object.values(additionals)
          .sort((a, b) => a.additional.localeCompare(b.additional)) // Sort by 'additional' property
          .map((item, index) => (
            <>
              <div
                onClick={() => setExpanded(index)}
                style={{ width: "250px", padding: "10px", background: "transparent" }}
                className="additional"
              >
                <h7>
                  <span>{item.additional && getIcon(item.additional)}</span>
                  {item.additional}{" "}
                  {Object.values(item.options).length > 1 && (
                    <span style={{ color: "#00ffd499" }}>({Object.values(item.options).length})</span>
                  )}
                </h7>
              </div>
              <Dialog
                open={index === expanded}
                // onClose={() => setExpanded(null)}
                onClose={() => handleClose()}
                // PaperProps={{ className: "custom-dialog" }}
                sx={{ backdropFilter: "blur(0px)" }}
                PaperProps={{
                  className: "customDialog",
                  sx: {
                    minWidth: "80%", // Adjust as needed
                    background: "linear-gradient(#333, #111111)",
                  },
                }}
              >
                <Grid
                  container
                  xs={12}
                  p={2}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "20px",
                    // border: "1px solid #555",
                    // background: "#55555522",
                    borderRadius: "5px",
                  }}
                  onClick={() => setExpanded(index)}
                >
                  {/* {expanded !== index && item.additional} */}
                  <Grid item xs={12}>
                    {item.additional && (
                      <Tooltip
                        title={
                          Object.values(item.options).length > 1
                            ? "To avoid loosing data, categorys with more than one option are locked. However you can still add/edit options."
                            : "Change category"
                        }
                      >
                        <Autocomplete
                          // disabled={Object.values(item.options).length > 1 ? true : false}
                          size="small"
                          value={item.additional}
                          // id="combo-box"
                          id={item.additionalId}
                          // options={featureCategories}
                          options={featureCategoriesReduced}
                          // Send additionalId, event and newValue on "chosen option"... also checking for newValue instead of getting error
                          onChange={(event, newValue) =>
                            // handleChangeAdditional(item.additionalId, newValue ? newValue.label : "Empty", newValue.type)
                            handleChangeAdditional(
                              item.additionalId,
                              newValue ? newValue.label : " ",
                              newValue ? newValue.type : "Standard"
                            )
                          }
                          // ... and the same if written.
                          renderOption={(props, item) => (
                            <li {...props}>
                              <ListItemIcon className="categoryListIcon">{item.icon}</ListItemIcon>
                              <ListItemText primary={item.label} />
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              // fullWidth
                              {...params}
                              // label="Category"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    {/* {Object.values(item.options).length > 0 ? (
                              <InputAdornment position="start">{item.icon && item.icon}</InputAdornment>
                            ) : null} */}
                                    <InputAdornment position="start">{/* <{item.icon} /> */}</InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                                endAdornment: (
                                  <>
                                    {Object.values(item.options).length > 1 ? (
                                      <InputAdornment position="end">
                                        <LockIcon style={{ color: "#00ffd4", fontSize: "15px" }} />
                                      </InputAdornment>
                                    ) : null}
                                    {/* {params.InputProps.endAdornment} */}
                                  </>
                                ),
                              }}
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  color: Object.values(item.options).length > 1 ? "#00ffd4cc" : "#00ffd4",
                                  fontSize: "20px",
                                  fontWeight: "900",
                                  // color: "#00ffd4",
                                },
                              }}
                              onChange={(event) =>
                                handleChangeAdditional(item.additionalId, event.target.value ? event.target.value : " ", "standardFields")
                              }
                              // onBlur={() => store()}
                            />
                          )}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                  <span>{item.additional && getIcon(item.additional)}</span>
                  {/* <span>{additionalTools(item, item.type)}</span> */}
                  {/* {Object.values(item.options).map((option, index) => ( */}
                  {Object.values(item.options)
                    .sort((a, b) => a.option.localeCompare(b.option)) // Sort by option name alphabetically
                    .map((option, index) => (
                      <Grid
                        container
                        xs={12}
                        style={{ display: "flexWrap", flexDirection: "row", gap: "5px", marginBottom: "5px" }}
                        mt={1}
                        style={{}}
                        className="moduleOption"
                      >
                        <input
                          placeholder="✏️ Enter option name"
                          className="optionName"
                          // value={option.option}
                          defaultValue={option.option}
                          contentEditable
                          // onChange={(e) => handleChangeOption(item.additionalId, option.optionId, "option", e.target.value)}
                          onBlur={(e) => {
                            store();
                            handleChangeOption(item.additionalId, option.optionId, "option", e.target.value);
                          }}
                          onFocus={handleFocus}
                        />

                        {/* startAdornment: field.icon ? <InputAdornment position="start"></InputAdornment> : null, */}

                        <Grid container xs={12} md={12} mt={2} style={{ display: "flex", gap: "5px" }} className="moduleInfo">
                          {/* INFO START */}
                          {allFields[item.type].map(
                            (field) =>
                              field.section === "info" && (
                                <>
                                  {(field.type === "text" || field.type === "number") && field.id !== "option" && field.id !== "price" && (
                                    <TextField
                                      md={1}
                                      xs={12}
                                      id={option.optionId + field.id}
                                      label={field.label}
                                      type={field.type}
                                      multiline={field.multiline}
                                      value={option[field.id] ? option[field.id] : field.type === "number" ? 0 : ""}
                                      size="small"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end" style={{ color: "#555" }}>
                                            {field.endAdornment}
                                          </InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) => handleChangeOption(item.additionalId, option.optionId, field.id, e.target.value)}
                                      onBlur={() => store()}
                                      onFocus={handleFocus}
                                    />
                                  )}
                                  {field.id === "price" && (
                                    <Grid item xs={12} mt={2}>
                                      <NumericFormat
                                        mt={4}
                                        label={field.label}
                                        size="small"
                                        value={option[field.id]}
                                        suffix={" " + currentVenue.currency}
                                        thousandSeparator=" "
                                        customInput={TextField}
                                        onValueChange={(event) => {
                                          const { formattedValue, value } = event;
                                          handleChangeOption(item.additionalId, option.optionId, field.id, value);
                                        }}
                                        InputProps={{
                                          // style: { background: "#00ffd411", color: "#fff" },
                                          endAdornment: (
                                            <InputAdornment position="end" style={{ color: "#555" }}>
                                              <NumericFormat
                                                label="inc VAT"
                                                sx={{ color: "red", background: "transparent" }}
                                                size="small"
                                                disabled
                                                customInput={TextField}
                                                thousandSeparator=" "
                                                value={option[field.id] * (1 + removePercentage(option["vat"]) / 100)}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                        onFocus={handleFocus}
                                      />
                                    </Grid>
                                  )}

                                  {field.type === "switch" && (
                                    <>
                                      <Switch label="{field.label}" labelPlacement="bottom" />
                                      <span style={{ fontSize: "10px" }}>{field.label}</span>
                                    </>
                                  )}

                                  {field.type === "capacityCombined" && (
                                    <>
                                      <TextField
                                        disabled
                                        label={field.label + " (seated + standing)"}
                                        type={field.type}
                                        multiline={field.multiline}
                                        // value={option[field.id] ? Number(option.capSeated) + Number(option.capStanding) : ""}
                                        value={option.capacity}
                                        size="small"
                                        defaultValue={0}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end" style={{ color: "#555" }}>
                                              {field.endAdornment}
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e) => handleChangeOption(item.additionalId, option.optionId, field.id, e.target.value)}
                                        onBlur={() => store()}
                                      />
                                    </>
                                  )}
                                </>
                                // </Grid>
                              )
                          )}
                        </Grid>

                        {/* INFO END */}

                        {/*  NOTES START */}
                        {/* <Grid container xs={12} md={5} mt={2} style={{ display: "flex", gap: "10px" }} className="moduleNotes">
                      {allFields[item.type].map((field) => (
                        <>
                          {field.section === "notes" && (
                            // <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label={field.label}
                              type="text"
                              multiline={field.multiline}
                              maxRows={5}
                              minRows={1}
                              value={option.notes}
                              size="small"
                              defaultValue={""}
                              onChange={(e) => handleChangeOption(item.additionalId, option.optionId, field.id, e.target.value)}
                              onBlur={() => store()}
                              InputProps={{
                                // style: { color: "#00ffd499" },
                                style: { background: "#00ffd422", boxShadow: "2px 2px 5px 0px black" },
                              }}
                            />
                            // </Grid>
                          )}
                        </>
                      ))}
                    </Grid> */}
                        {/* OPTION NOTES END */}

                        {/*  ECONOMY START */}
                        <Grid mt={3} container xs={12} style={{ display: "flex", gap: "10px" }} className="moduleEconomy">
                          {allFields[item.type].map(
                            (field) =>
                              field.section === "economy" && (
                                <Grid item xs={12} sm={4} md={2.5}>
                                  {field.type === "number" && (
                                    <NumericFormat
                                      label={field.label}
                                      size="small"
                                      value={option[field.id]}
                                      suffix={" " + currentVenue.currency}
                                      thousandSeparator=" "
                                      customInput={TextField}
                                      onValueChange={(event) => {
                                        const { formattedValue, value } = event;
                                        handleChangeOption(item.additionalId, option.optionId, field.id, value);
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end" style={{ color: "#555" }}>
                                            <NumericFormat
                                              label="inc VAT"
                                              sx={{ color: "red", background: "transparent" }}
                                              size="small"
                                              disabled
                                              customInput={TextField}
                                              thousandSeparator=" "
                                              value={option[field.id] * (1 + removePercentage(option["vat"]) / 100)}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                  {field.type === "revenue" && (
                                    <NumericFormat
                                      disabled
                                      size="small"
                                      value={option["price"] - option["internalCost"]}
                                      suffix={" " + currentVenue.currency}
                                      thousandSeparator=" "
                                      customInput={TextField}
                                      label="Revenue"
                                      InputProps={{
                                        style: { color: "transparent" },
                                      }}
                                    />
                                  )}
                                  {field.type === "markUp" && (
                                    <TextField
                                      fullWidth
                                      disabled
                                      className={field.visibility}
                                      label={field.label}
                                      type="number"
                                      multiline={field.multiline}
                                      value={((option["price"] / option["internalCost"] - 1) * 100).toFixed(1)}
                                      size="small"
                                      defaultValue={0}
                                      InputProps={{
                                        style: { color: "transparent" },
                                        endAdornment: (
                                          <InputAdornment position="end" style={{ color: "#555" }}>
                                            {field.endAdornment}
                                          </InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) => handleChangeOption(item.additionalId, option.optionId, field.id, e.target.value)}
                                      onBlur={() => store()}
                                      onFocus={handleFocus}
                                    />
                                  )}
                                  {field.type === "VatSelector" && (
                                    <VatSelector
                                      handleChangeOption={handleChangeOption}
                                      label={field.label}
                                      value={option.vat}
                                      item={item}
                                      optionId={option.optionId}
                                    />
                                  )}
                                </Grid>
                              )
                          )}
                        </Grid>
                        {/*  ECONOMY  END */}

                        <Grid item mt={2} xs={3} sm={1} sx={{ display: "flex", flexDirection: "row" }} style={{ float: "left" }}>
                          {Object.values(item.options).length > 1 && (
                            <ConfirmDialog
                              noButton
                              onConfirm={() => removeOption(item.additionalId, option.optionId)}
                              header={`Delete option?`}
                              message="Are you sure you want to delete this option. This operation is irreversible."
                            >
                              <div
                                color="secondary"
                                aria-label="Remove option"
                                style={{ fontSize: "10px", color: "#77777755", marginLeft: "10px", textAlign: "center" }}
                              >
                                <DeleteIcon />
                                <div>Remove option</div>
                              </div>
                            </ConfirmDialog>
                          )}
                        </Grid>
                        <div style={{ width: "100%", borderBottom: "0.25px solid #ffffff22", padding: "10px" }}></div>
                      </Grid>
                    ))}
                  <Grid
                    item
                    xs={6}
                    color="primary"
                    aria-label="Add option"
                    onClick={() => addOption(item)}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ fontSize: "10px", color: "#777", textAlign: "center" }}>
                      <AddIcon />
                      <div>Add option</div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={12}
                    style={{
                      marginBottom: "10px",
                      fontSize: "10px",
                      color: "#00ffd4",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {Object.values(additionals).length > 1 && (
                      <div>
                        <ConfirmDialog
                          noButton
                          onConfirm={() => removeAdditional(item.additionalId)}
                          header={`Delete "${item.additional}"?`}
                          // We should really check wether the catogry holds features that are being selected in any products.
                          message="This module may hold options used in products. Are you sure you want to delete this module? All underlaying data will be deleted."
                        >
                          <div
                            color="secondary"
                            aria-label="Remove row"
                            style={{ color: "#00ffd455", marginLeft: "10px", textAlign: "center" }}
                          >
                            <DeleteIcon />
                            <div>Remove Module</div>
                          </div>
                        </ConfirmDialog>
                      </div>
                    )}
                  </Grid>
                  <div style={{ display: "flex", gap: "15px", padding: "20px" }}>
                    <Button variant="contained" onClick={() => saveData()}>
                      {loading ? (
                        <>
                          <CircularProgress style={{ zoom: "0.4", marginRight: "15px" }} />
                          Saving
                        </>
                      ) : (
                        "Save"
                      )}
                    </Button>
                    <Button variant="outlined" onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </div>
                </Grid>
                {/* <UnsavedChanges New={additionals} Old={originalFeatures} /> */}
              </Dialog>
            </>
          ))}
      <div aria-label="Add row" onClick={addAdditional} style={{ fontSize: "10px", color: "#00ffd4", marginBottom: "50px" }}>
        <AddIcon /> Add Module
      </div>
      {/* <Grid xs={12} p={2}>
        <Button onClick={() => store()}>Store</Button>
      </Grid>{" "} */}
      {console.log("currentVenue", currentVenue)}
      {console.log("formData", formData)}
    </>
  );
};

export default VenueAdditionals;

// The different categories to choose from. For "searchability" purposes some categories have specific fields and parameters.
export const featureCategories = [
  // { label: "New Standard", type: "newStandardFields", icon: <AppsIcon className="categoryIcon" /> },
  { label: "Standard (enter custom name)", type: "standardFields", icon: <AppsIcon className="categoryIcon" /> },
  // { label: "Capacity", type: "capacityFields", icon: <PeopleAltIcon className="categoryIcon" /> },
  // { label: "Rental Fee", type: "rentalFeeFields", icon: <StoreIcon className="categoryIcon" /> },
  { label: "Catering", type: "cateringFields", icon: <RestaurantIcon className="categoryIcon" /> },
  // { label: "Amplification", type: "standardFields" },
  { label: "Speakers", type: "standardFields", icon: <SpeakerIcon className="categoryIcon" /> },
  // { label: "Lights", type: "standardFields" },
  // { label: "Dynamics", type: "standardFields" },
  // { label: "Stands", type: "standardFields" },
  // { label: "Microphones", type: "standardFields" },
  // { label: "Cables", type: "standardFields" },
  { label: "Stage", type: "stageFields", icon: <StadiumIcon className="categoryIcon" /> },
  // { label: "Early get in", type: "standardFields" },
  // { label: "Later curfew", type: "standardFields" },
  { label: "Extra hours", type: "standardFields", icon: <QueryBuilderIcon className="categoryIcon" /> },
  { label: "Security", type: "standardFields", icon: <LocalPoliceIcon className="categoryIcon" /> },
  { label: "Wardrobe", type: "standardFields", icon: <CheckroomIcon className="categoryIcon" /> },
  { label: "Sound Engineer", type: "standardFields", icon: <TuneIcon className="categoryIcon" /> },
  { label: "Staff", type: "staffFields", icon: <GroupIcon className="categoryIcon" /> },
];

export const getIcon = (label) => {
  const iconObject = featureCategories.find((obj) => obj.label === label);
  console.log("iconObject", iconObject, label);
  return iconObject ? iconObject.icon : <AppsIcon className="categoryIcon" />;
};
