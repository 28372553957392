/**
 * /* eslint-disable
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import { useAuth } from "../firebase";
import Authentication from "./Authentication/Authentication";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/About";
import Profile from "../pages/Profile";
import Venue from "../pages/Venue";
import Agent from "../pages/Agent";
import { AppBar, Box, Button, ButtonGroup, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import VenueCalendar from "./Sidebar/VenueCalendar";
import Avails from "../pages/Avails";
import Reports from "../pages/Reports";
import GetVenues from "../helpers/GetVenues";
import Owner from "../pages/Owner";
import Editors from "../pages/Editors";
import CheckUserRole from "./Authentication/CheckUserRole";
import { useSelector } from "react-redux";
import RequestAvails from "../pages/RequestAvails";
import ArtistForm from "./Forms/ArtistForm";
import Customers from "../pages/Customers";
import Venues from "../pages/Venues";
import OwnerHome from "../pages/OwnerHome";
import OwnerVenueForm from "../pages/OwnerVenueForm";
import CheckRole from "./Authentication/CheckRole";
import InRedux from "../helpers/InRedux";
import ThemeSwitch from "../features/theme/ThemeSwitch";
import Certificates from "../pages/Certificates";
import Bookings from "../pages/Bookings";
import Booking from "../pages/Booking";
import PassedVerification from "../pages/PassedVerification";
import SettingsOwner from "../pages/SettingsOwner";
import Payment from "../pages/Payment";
import BookingSummary from "../pages/BookingSummary";
import TestSite from "../pages/TestSite";
import AdminBackend from "../pages/AdminBackend";
import OwnerSignup from "../pages/OwnerSignup";
import OwnerSignupPage from "../pages/OwnerSignupPage";
import Requests from "../pages/Requests";
import OwnerAccount from "../pages/OwnerAccount";
import GetBookings from "../helpers/GetBookings";
import Invoice from "../pages/Invoice";
import TravelFootprint from "../pages/TravelFootprint";
import SustainabilityForm from "../pages/SustainabilityForm";
import CustomerAccount from "../pages/CustomerAccount";
import SignAgreement from "../pages/SignAgreement";
import Transactions from "../pages/Transactions";
import BookingPreview from "../pages/BookingPreview";
import IncomingRequestPage from "../pages/IncomingRequestPage";
import Subscribed from "../pages/Subscribed";
import Unsubscribed from "../pages/Unsubscribed";
import PaymentSuccess from "../pages/PaymentSuccess";
import Sustainability from "../pages/Sustainability";
import DataSheet from "../pages/DataSheet";
import EventTravelForm from "../pages/EventTravelForm";
import BackupManager from "../pages/BackupManager";
import Itinerary from "../pages/Itinerary";
import { useDispatch } from "react-redux";
import { storeStartVenue } from "../features/venueFilter/venueFilterSlice";
import EventPollVisual from "../pages/EventPollVisual";
import ObjectSize from "../helpers/ObjectSize";

const Header = () => {
  // const currentUser = useAuth();
  const userRole = useSelector((state) => state.counter.userRole);
  const startVenue = useSelector((state) => state.counter.startVenue);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [startVenueFromCookie, setStartVenueFromCookie] = useState(
    document.cookie.match(new RegExp("(?:^|; )" + "vhStartVenueId" + "=([^;]+)") || null)
  );
  const dispatch = useDispatch();
  // const startVenueFromCookie = document.cookie.match(new RegExp("(?:^|; )" + "vhStartVenueId" + "=([^;]+)"));
  // console.log("startVenueFromCookie", startVenueFromCookie[1]);
  // const startVenueId = startVenueFromCookie[0] ? startVenueFromCookie[1] : "hejhej";
  // console.log("startVenue", startVenueId);
  // const [featuresSize, setFeaturesSize] = useState(new Blob([JSON.stringify(currentVenue.features)]).size);
  const [featuresSize, setFeaturesSize] = useState(0);

  const venueCookie = () => {
    document.cookie.match(new RegExp("(?:^|; )" + "vhStartVenueId" + "=([^;]+)") || null);
  };

  useEffect(() => {
    if (!startVenueFromCookie) return;
    dispatch(storeStartVenue(startVenueFromCookie[1]));
  }, [startVenueFromCookie]);

  // useEffect(() => {
  //   if (!startVenue) return;
  //   storeStartVenue(startVenue);
  // }, [startVenue]);

  useEffect(() => {
    const venuefeatures = JSON.stringify(currentVenue.features);
    setFeaturesSize(new Blob([venuefeatures]).size);
  }, [currentVenue]);

  return (
    <div>
      <Router>
        <Routes></Routes>
        <CheckRole />
        <AppBar
          position="sticky"
          xs={12}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          style={{
            backgroundColor: "#000000bb",
            boxShadow: "none",
            width: "100vw",
          }}
        >
          {/* <Grid container direction="row" xs={12}> */}
          <Grid container direction="row">
            <Grid item xs={3} px={2} sx={{ marginRight: "20px" }}>
              <span className="circlesvg"></span>
              <span className="logosvg"></span>
            </Grid>
            <Grid item>
              <span className="nuLogoSvg"></span>
            </Grid>

            {userRole == "admin" ||
              (superOwner && (
                <Grid item sx={{ flexShrink: 1 }}>
                  {/* features: <ObjectSize dataObject={currentVenue.features} /> */}
                  {featuresSize}
                  <ButtonGroup variant="text" aria-label="text button group" size="small">
                    <InRedux />
                    <ThemeSwitch />

                    <Link to="/Owner/Home">
                      <Button>Home</Button>
                    </Link>
                    <Link to="/Venue">
                      <Button>Venue</Button>
                    </Link>

                    <Link to="/Agent">
                      <Button>Agent</Button>
                    </Link>
                    <Link to="/Owner">
                      <Button>Owner</Button>
                    </Link>
                    <Link to="/Owner/Settings">
                      <Button>Settings</Button>
                    </Link>
                    <Link to="/Owner/TravelFootprint">
                      <Button>TravelFootprint</Button>
                    </Link>
                    <Link to="/EventPollVisual">
                      <Button>EventPollVisual</Button>
                    </Link>
                  </ButtonGroup>
                </Grid>
              ))}
            <Grid
              item
              sx={{
                float: "right",
                flexGrow: 1,
                // flexShrink: 1
              }}
              style={{ position: "absolute", right: "20px" }}
            >
              <Authentication />
            </Grid>
          </Grid>
        </AppBar>

        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="Signup" element={<OwnerSignupPage />} />
            <Route path="SignAgreement" element={<SignAgreement />} />
            <Route path="Transactions" element={<Transactions />} />
            <Route path="SustainabilityForm" element={<SustainabilityForm />} />
            <Route path="Invoice" element={<Invoice />} />
            <Route path="verificatonOK" element={<PassedVerification />} />
            <Route path="Subscribed" element={<Subscribed />} />
            <Route path="Unsubscribed" element={<Unsubscribed />} />
            <Route path="Testing" element={<TestSite />} />
            <Route path="TravelForm" element={<EventTravelForm />} />
            <Route path="Itinerary" element={<Itinerary />} />
            <Route path="EventPollVisual" element={<EventPollVisual />} />
            <Route path="/Owner" element={<Owner />}>
              <Route path="BookingPreview" element={<BookingPreview />} />
            </Route>
            {userRole === "owner" || userRole === "admin" || superOwner ? (
              <Route path="/Owner" element={<Owner />}>
                <Route path="Editors" element={<Editors />} />
                <Route path="Bookings" element={<Bookings />} />
                <Route path="BookingRequests" element={<IncomingRequestPage />} />
                <Route path="VenueCalendar" element={<VenueCalendar />} />
                <Route path="Avails" element={<Avails />} />
                <Route path="Sustainability" element={<Sustainability />} />
                <Route path="Reports" element={<Reports />} />
                <Route path="Customers" element={<Customers />} />
                <Route path="Home" element={<OwnerHome />} />
                <Route path="Venue" element={<OwnerVenueForm />} />
                <Route path="Booking" element={<Booking />} />
                <Route path="BookingPreview" element={<BookingPreview />} />
                <Route path="Settings" element={<SettingsOwner />} />
                <Route path="Account" element={<OwnerAccount />} />
                <Route path="Summary" element={<BookingSummary />} />
                <Route path="TravelFootprint" element={<TravelFootprint />} />
                <Route path="BackupManager" element={<BackupManager />} />
              </Route>
            ) : (
              <>No Access</>
            )}
            {userRole === "Sustainability" && <Route path="Sustainability" element={<Certificates />} />}
            {userRole === "editor" || userRole === "owner" ? (
              <Route path="/Venue" element={<Venue />}>
                <Route path="VenueCalendar" element={<VenueCalendar />} />
                <Route path="Sustainability" element={<Certificates />} />
                <Route path="Avails" element={<Avails />} />
                <Route path="Reports" element={<Reports />} />
              </Route>
            ) : (
              <>No Access</>
            )}
            {userRole === "owner" ? <Route path="/Agent" element={<Agent />}></Route> : <>No Access</>}{" "}
            {/* should be "agent" instead of "owner" */}
            <Route path="/RequestAvails*" element={<RequestAvails />} />
            <Route path="/Requests" element={<Requests />} />
            {userRole === "agent" || userRole === "owner" ? <Route path="/Artist" element={<ArtistForm />} /> : <>No Access</>}{" "}
            {/* should be "admin" instead of "owner" */}
            <Route path="/About" element={<About />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Payment" element={<Payment />} />
            <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
            <Route path="/Sustainability" element={<Sustainability />} />
            <Route path="/Account" element={<CustomerAccount />} />
            <Route path="*" element={<Home />} />
            {(userRole === "owner" || userRole === "admin") && <Route path="/AdminBackend" element={<AdminBackend />} />}
            {(userRole === "owner" || userRole === "admin") && <Route path="DataSheet" element={<DataSheet />} />}
          </Routes>
        </Box>
      </Router>
    </div>
  );
};

export default Header;
