import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useDispatch, useSelector } from "react-redux";
import { storeUIDrawerOpen } from "../../features/venueFilter/venueFilterSlice";

const UIDrawer = ({ content }) => {
  const drawerWidth = "800px";
  const UIDrawerOpen = useSelector((state) => state.counter.UIDrawerOpen);
  // const [open, setOpen] = React.useState(UIDrawer);
  const dispatch = useDispatch();

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  //   dispatch(storeUIDrawer(true));
  // };

  const handleDrawerClose = () => {
    // setOpen(false);
    dispatch(storeUIDrawerOpen(false));
  };

  return (
    <div>
      <Drawer
        xs={12}
        sx={{
          // width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={UIDrawerOpen}
      >
        {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader> */}
        <Divider />
        {/* <Content /> */}
        {content}
        <IconButton onClick={handleDrawerClose} size="large">
          <ChevronRightIcon />
          <h1>{UIDrawerOpen}</h1>
        </IconButton>
      </Drawer>
    </div>
  );
};

export default UIDrawer;
