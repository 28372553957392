/** @format */

import React from "react";

const SyncTickets = ({ ticketApiKey, ticketOrganizerId, ticketEventId }) => {
  //test organizer:2915, eventId:51819
  console.log("❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️", ticketApiKey, ticketOrganizerId, ticketEventId);
  return <div>SyncTickets</div>;
};

export default SyncTickets;
