/** @format */

import React, { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  storeCurrentAgreements,
  storeCurrentUser,
  storeCurrentUserId,
  storeUserRole,
  storeVenueAccess,
} from "../../features/venueFilter/venueFilterSlice";
import GetBookings from "../../helpers/GetBookings";

const CheckRole = () => {
  const auth = getAuth();
  const userRole = useSelector((state) => state.counter.userRole);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);

  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(storeCurrentUserId(userData));
  // }, [userData]);
  // console.log("balle");

  useEffect(() => {
    // console.group("THIS USER ROLE", userRole);
    // userRole === "owner" && <></>;
    currentUser.userRole === "Sustainability" && navigate("/SustainabilityForm");
    // THIS!
    // if (userRole === "owner" && userRole !== "admin") navigate("/Owner/Home");
  }, [userRole]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log('found user', user.uid)
        // alert(user.uid);
        dispatch(storeCurrentUserId(user.uid));
        const docRef = doc(db, "Users", user.uid);
        // const docSnap = await getDoc(docRef);

        // Get the user document data
        getDoc(docRef)
          .then((doc) => {
            if (doc.exists()) {
              // console.log("usrdata:", doc.data());
              // console.log("Role: ", doc.data().role);
              var userRoleToGo = doc.data().role;
              dispatch(storeUserRole(userRoleToGo));
              setUserData(doc.data());
              dispatch(storeCurrentUser(doc.data()));
              // console.log(currentUser);
              var tmpVenueAccess = doc.data().venueAccess;
              dispatch(storeVenueAccess(tmpVenueAccess));
              // console.log("Document finally arrived");
            } else {
              // console.log("Document does not exist");
            }
          })
          .catch((error) => {
            // console.log("Error getting document:", error);
          });

        // function wait() {
        //   setTimeout(() => {
        //     console.log("waiting in checkrole.js");
        //     console.log("since user isnt saved in collection yet");
        //   }, 500);
        // }

        // if user is found in collection Users then set Role and VenueAccess... otherwise wait

        // console.log("Role: ", docSnap.data().role);
        // var userRoleToGo = docSnap.data().role;
        // dispatch(storeUserRole(userRoleToGo));
        // setUserData(docSnap.data());
        // dispatch(storeCurrentUser(docSnap.data()));
        // console.log(currentUser);
        // var venueAccess = docSnap.data().venueAccess;
        // dispatch(storeVenueAccess(venueAccess));
      } else {
        // User is signed out
        // console.log("User not logged in");
        return;
      }
    });
  }, [onAuthStateChanged]);

  useEffect(() => {
    // console.log("venueAccess", venueAccess);
    if (venueAccess === "none" || !venueAccess) return;
    getAllAgreements();
    // }, [venueAccess.map((item) => item.venueid).join(",")]);
  }, [venueAccess]);

  // async function getAllAgreements() {
  //   alert("venueaccess" + venueAccess);
  //   const agreements = [];
  //   const q = query(collection(db, "Agreements"), where("venueid", "in", venueAccess));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //     console.log("pushing this", doc.data());
  //     agreements.push(doc.data());
  //   });
  //   console.log("dispatching agreements");
  //   dispatch(storeCurrentAgreements(agreements));
  //   console.log(agreements);
  // }

  async function getAllAgreements() {
    try {
      // if (!venueAccess || venueAccess.length === 0 || venueAccess.length > 10) {
      //   console.error("Check venueAccess: Ensure it's an array, not empty, and contains 10 or fewer items.");
      //   return;
      // }
      const agreements = [];
      const q = query(collection(db, "Agreements"), where("venueid", "in", venueAccess));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // console.log("pushing this", doc.data());
        agreements.push(doc.data());
      });
      // filter out agreements that does not have venueid in currentVenueBookings

      //
      //

      console.log("venueAccess", venueAccess);
      // console.log("dispatching agreements");
      dispatch(storeCurrentAgreements(agreements));
      // console.log(agreements);
    } catch (error) {
      console.error("Failed to fetch agreements:", error);
    }
  }

  useEffect(() => {
    if (currentAgreements.length === 0) return;
    if (currentVenueBookings.length === 0) return;
    console.log("currentVenueBookings:", currentVenueBookings);
    // i want to filter out the agreements that does not have a any of the currentVenueBookings.venueid in their bookingids
    const filteredAgreements = currentAgreements.filter((agreement) =>
      currentVenueBookings.some((booking) => booking.agreementId === agreement.agreementId)
    );
    // alert("number of filteredagrements: " + filteredAgreements.length);
    dispatch(storeCurrentAgreements(filteredAgreements));
  }, [currentVenueBookings]);

  // async function getAllVenueBookings() {
  //   // alert(venueAccess);
  //   const bookings = [];
  //   const q = query(collection(db, "Bookings"), where("venueid", "in", venueAccess));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //     bookings.push(doc.data());
  //   });
  //   dispatch(storeCurrentVenueBookings(bookings));
  //   console.log(bookings);
  // }

  // onAuthStateChanged(auth, async (user) => {
  //   if (user) {
  //     // console.log('found user', user.uid)
  //     const docRef = doc(db, "Users", user.uid);
  //     const docSnap = await getDoc(docRef);
  //     console.log(user.uid);
  //     console.log("Role: ", docSnap.data().role);

  //     var userRoleToGo = docSnap.data().role;
  //     dispatch(storeUserRole(userRoleToGo));
  //     setUserData(docSnap.data());
  //     // dispatch(storeCurrentUser(docSnap.data()));

  //     var venueAccess = docSnap.data().venueAccess;
  //     dispatch(storeVenueAccess(venueAccess));
  //   } else {
  //     // User is signed out
  //     // console.log("User not logged in");
  //     return;
  //   }
  // });

  return <div>{/* <GetBookings /> */}</div>;
};

export default CheckRole;
