/** @format */

import { Button, CircularProgress, Grid } from "@mui/material";
import { setData } from "@progress/kendo-intl";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import React, { useEffect, version } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import store from "../../app/store";
import { getEndChargeBody } from "../../finshark/finsharkEndChargeBody";
import { getPreChargeBody } from "../../finshark/finsharkPreChargeBody";
import { createId, createMidId, createSmallId, database, db } from "../../firebase";
import { makeNumber, numberFormatted } from "../../helpers/Numbers";
import { createCreditPost, createDebitPost } from "../../pages/LedgerView";
import { getUpfrontBody } from "../../finshark/finsharkUpfrontBody";
import EPCQRCode from "./EPCQRCode";
import { generateAndUploadQRCode } from "./generateAndUploadQRCode";
import { create } from "qrcode";
import OrderChanges from "./OrderChanges";
import axios from "axios";
import { getSettlementChargeBody } from "../../finshark/finsharkSettlementChargeBody";
import ObjectSize from "../../helpers/ObjectSize";
// import { set } from "date-fns";

const Settler = ({ type, agreementId }) => {
  //   const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [data, setData] = useState([]);
  const [sorted, setSorted] = useState([]);
  const [nextPosts, setNextPosts] = useState([]);
  const [agreement, setAgreement] = useState();
  const today = new Date().toISOString().split("T")[0];
  // const [agreementId, setAgreementId] = useState("akircg6otgbixmilliigthkiogrvlo6u");
  const [changesInAgreement, setChangesInAgreement] = useState([{}]);
  let amountAlreadyPaid = 0;
  const span = "Monthly";

  async function getAgreement() {
    setLoading(true);
    if (!agreementId) return;
    const docSnap = await getDoc(doc(db, "Agreements", agreementId));
    setAgreement(docSnap.data());
    console.log("agreement", agreement);
    // return docSnap.data();
  }

  async function RunOrderChanges() {
    let changes = await OrderChanges(agreementId);
    setChangesInAgreement(changes);
  }

  const makeNumber = (amount) => {
    return parseFloat(amount).toFixed(2);
    // return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK" }).format(amount);
  };

  useEffect(() => {
    getAgreement();
    console.log("agreement", agreement);
  }, [agreementId]);

  const dateDaysPriorTo = (date, days) => {
    let tmpDays = days;
    console.log("date", date);
    console.log("days", days);
    if (!days) tmpDays = 0;
    console.log("tmpDays", tmpDays);
    console.log("date", new Date(new Date(date).setDate(new Date(date).getDate() - tmpDays)).toISOString().split("T")[0]);
    return new Date(new Date(date).setDate(new Date(date).getDate() - tmpDays)).toISOString().split("T")[0];
  };
  if (span === undefined) span = "Weekly"; // can also be "monthly", depending on desired rate of payments.

  useEffect(() => {
    if (type === "signedAgreement") getBookingsByAgreementId();
  }, [agreement]);

  async function getBookingsByAgreementId() {
    if (bookings.length > 0) return; // Only get bookings once
    let tmp = [];
    const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", agreementId)));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //   console.log(doc.id, " => ", doc.data());
      tmp.push({ id: doc.id, ...doc.data() });
    });
    setBookings(tmp);
  }

  //store all includes (options) in the agreement.
  async function storeAgreementVersion(bookings) {
    // return; // <======= <======= <======= <======= <======= <======= <======= REMOVE THIS LINE TO ACTIVATE
    if (!agreement) return;

    let timestamp = Date.now();
    timestamp = new Date(timestamp).toLocaleString("sv-SE", { timeZone: "Europe/Stockholm" });
    let bookingFeaturesPerBooking = [];
    await bookings.forEach((booking) => {
      bookingFeaturesPerBooking[booking.bookingid] = booking.bookingFeatures;
    });
    let versionsArray = [];
    agreement.versions && agreement.versions.length > 0 && agreement.versions.forEach((version) => versionsArray.push(version));
    versionsArray.push({ timestamp, featuresInBookings: { ...bookingFeaturesPerBooking } });

    if (agreement.versions && agreement.versions.length > 4) {
      versionsArray = agreement.versions.slice(-4); // Only store the last 5 versions
    }

    await updateDoc(doc(db, "Agreements", agreementId), {
      versions: versionsArray,
    });

    // OrderChanges(agreement);
    // setLoading(false);
  }

  useEffect(() => {
    console.log("bookings", bookings);
    storeAgreementVersion(bookings); //store all includes (options) in the agreement

    // Get dates and amounts for upfront, preCharge and endCharge ("balance") for each booking and put into data.
    if (bookings.length) {
      let tmp = [];
      bookings.map((booking) => {
        const price = makeNumber(booking.priceIncVat) || 0;
        console.log("booking", booking);
        const upfront = (price * parseFloat(agreement.upfront ? agreement.upfront : booking.venue.upfront)) / 100;
        console.log("UPFRONT", upfront);
        const upfrontAt = new Date().toISOString().split("T")[0];
        const preCharge = (price * parseFloat(agreement.preCharge ? agreement.preCharge : booking.venue.preCharge)) / 100;
        const preChargeAt = dateDaysPriorTo(booking.date, agreement.preChargeAt);
        const balance = price - upfront - preCharge;
        const balanceAt = dateDaysPriorTo(booking.date, agreement.balanceAt);

        tmp.push({
          email: agreement.agent.email,
          date: booking.date,
          bookingid: booking.bookingid,
          price: price,
          upfront: upfront,
          upfrontAt: upfrontAt,
          preCharge: preCharge,
          preChargeAt: preChargeAt,
          balanceAt: balanceAt,
          balance: balance,
          artist: agreement.artist && agreement.artist.artist,
          venue: agreement.venue,
          agreementId: agreementId,
          ticketEventId: booking.ticketEventId ? booking.ticketEventId : "-",
          ticketVenueId: booking.ticketVenueId ? booking.ticketVenueId : "-",
        });
      });
      setData(tmp);
    }
  }, [bookings]);

  //Skapa ledgerPoster av varje bokning för sig.
  // LÄGG TILL UPFRONT!! <======= <======= <======= <======= <======= <======= <=======
  useEffect(() => {
    data.forEach((item) => {
      async function createForLedger() {
        // totalhyran
        await createDebitPost(item.artist, item.price, item.bookingid, "Venue rent");
        // bokningsavgit arrangör
        const bookingFeeOrganizer = 0.5;
        await createDebitPost(item.artist, bookingFeeOrganizer, item.bookingid, "Booking fee");
        await createCreditPost("Venuehub", bookingFeeOrganizer, item.bookingid, "Booking fee");
        // bokningsavgift venue
        const bookingFeeVenue = 0.3;
        await createDebitPost(item.venue, bookingFeeVenue, item.bookingid, "Booking fee");
        await createCreditPost("Venuehub", bookingFeeVenue, item.bookingid, "Booking fee");
      }
      createForLedger();
    });
  }, [data]);

  // Check if there are previous verisons of the agreement
  const getAmountAlreadyPaid = async () => {
    if (agreement && agreement.versions && agreement.versions.length > 1) {
      console.log("agreement.versions", agreement.versions.length);
      await removeOldPaymentPosts();
      await fetchTransactions();
    }

    async function removeOldPaymentPosts() {
      try {
        const querySnapshot = await getDocs(query(collection(db, "PaymentPosts"), where("agreementId", "==", agreementId)));
        console.log("Query", querySnapshot);
        querySnapshot.forEach(async (doc) => {
          console.log("doc", doc.id);
          await deleteDoc(doc.ref); // Provide the reference to the document
        });
        console.log("Documents deleted successfully");
      } catch (error) {
        console.error("Error deleting documents:", error);
      }
    }

    // If there are previous versions of the agreement, fetch transactions in order to see if something is already paid.
    // Then set the amountAlreadyPaid state to the total amount already paid.
    async function fetchTransactions() {
      try {
        // alert(agreement.OCR);
        const response = await axios.get(`https://europe-west3-venuehub-64e72.cloudfunctions.net/sumOCRTransactions?OCR=${agreement.OCR}`);
        // const response = await axios.get(`http://localhost:5001/venuehub-64e72/us-central1/sumOCRTransactions?OCR=${agreement.OCR}`);  // For testing purposes
        console.log("response", response.data);
        console.log("Total amount", response.data.totalAmount);
        amountAlreadyPaid = response.data.totalAmount;
        return response.data.totalAmount;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const ticketSales = async (post) => {
    console.log("checking ticket sales for post...");
    // let postTicketSales = 0;
    try {
      // Fetch the ticketSummary for the venue of the post...
      console.log("ticketVenueId", post.ticketVenueId);
      // get the document from the collection TicketSummaries where the ticketVenueId is equal to documentname
      const docSnap = await getDoc(doc(db, "TicketSummaries", post.ticketVenueId));
      const ticketSummary = docSnap.data();

      console.log("ticketSummary", ticketSummary);
      // ...and calculate the total ticket sales for each post by accumulating the sum for each ticketEventId for this post.
      const result = post.ticketEventIds.reduce((acc, ticketEventId) => {
        const matchingItem = Object.values(ticketSummary).find((item) => item.ticketEventId === ticketEventId); // Find the item in the ticketSummary where ticketEventId matches
        if (matchingItem && matchingItem.summary && matchingItem.summary.sum) {
          return acc + matchingItem.summary.sum;
        }
        return acc;
      }, 0);

      // postTicketSales = ticketSales;
      console.log("ticketSales", result);
      return result;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("DATA", data);
    // Sort by week or by month (decided by span prop)
    if (data.length) {
      // Om det är flera spelningar samma månad så bakar vi ihop betalningen till en och samma.
      if (span === "Monthly") {
        let sortedByMonth = {};
        data.forEach((item) => {
          const date = new Date(item.preChargeAt);
          const month = date.getMonth(); // getMonth() returns a zero-based index (0-11)
          if (!sortedByMonth[month]) {
            sortedByMonth[month] = [];
          }
          {
            console.log("ITEM", item);
          }
          sortedByMonth[month].push({ ...item, month });
        });
        setSorted(sortedByMonth);
      }

      // Om det är flera spelningar samma vecka så bakar vi ihop betalningen till en och samma.
      if (span === "Weekly") {
        let sortedByWeek = {};
        data.forEach((item) => {
          const date = new Date(item.preChargeAt);
          const weekNumber = getWeekNumber(date);
          if (!sortedByWeek[weekNumber]) {
            sortedByWeek[weekNumber] = [];
          }
          sortedByWeek[weekNumber].push({ ...item, weekNumber });
        });
        // console.log("Sorted", sortedByWeek);
        setSorted(sortedByWeek);
      }
    }
  }, [data]);

  useEffect(() => {
    //Här skapar vi PaymentPoster
    if (Object.keys(sorted).length > 0) {
      console.log("SORTED", sorted);
      let aggregatedData = [];

      async function createPaymentPosts() {
        setLoading(true);
        await getAmountAlreadyPaid();
        if (agreement.upfront > 0) await createUpfrontPayment();
        await createPreChargePayment();
        await createBalancePayment();
        // await createSettlementPayment(); // No, not here
        await sortAggregatedData(aggregatedData);
        await reduceByAlreadyPaid(aggregatedData);
        await setNextPosts(aggregatedData);
        setLoading(false);
      }
      // return; // <======= <======= <======= <======= <======= <======= <======= REMOVE THIS LINE TO ACTIVATE
      createPaymentPosts();

      //upfront (skapa en upfront-betalpost för avtalets alla ingående bokningar)
      async function createUpfrontPayment() {
        let earliestDate = null;
        let totalUpfront = 0;
        let bookingids = [];
        let email = "";
        let venue;
        let artist;
        let currentDate = new Date().toISOString().split("T")[0];
        let ticketVenueId;
        let ticketEventIds = [];

        const referenceId = `upfront${createMidId()}`;

        await bookings.map((booking) => {
          let upfront = (agreement.upfront * booking.priceIncVat) / 100; //upfront % * bokningspris
          console.log("🌿 booking", booking);
          console.log("✈️ ", booking.upfront);
          totalUpfront += upfront;
          bookingids.push(booking.bookingid);
          email = agreement.agent.email;
          venue = agreement.venue;
          artist = agreement.artist.artist;
          ticketVenueId = booking.ticketVenueId;
          ticketEventIds.push(booking.ticketEventId);

          let currentDate = new Date(booking.upfrontAt);
          if (!earliestDate || currentDate < earliestDate) {
            earliestDate = currentDate;
          }
        });

        aggregatedData.push({
          OCR: agreement.OCR,
          artist: artist,
          email: email,
          originalAmount: makeNumber(totalUpfront),
          amount: totalUpfront.toFixed(2),
          due: currentDate,
          nextDateToCheck: currentDate,
          bookingids: bookingids,
          status: "IDLE",
          paymentBody: getUpfrontBody(bookings && bookings[0], makeNumber(totalUpfront), agreement.OCR),
          reference: referenceId,
          venue: venue,
          vat: 0,
          type: "UPFRONT",
          agreementId: agreementId,
          ticketVenueId: ticketVenueId,
          ticketEventIds: ticketEventIds,
        });
      }

      //preCharge
      async function createPreChargePayment() {
        for (const weekNumber of Object.keys(sorted)) {
          let earliestDate = null;
          let totalPreCharge = 0;
          let bookingids = [];
          let email = "";
          let venue;
          let artist;
          let ticketEventIds = [];
          let ticketVenueId;
          const referenceId = `pre${createMidId()}`;

          for (const item of sorted[weekNumber]) {
            console.log("item ", item);
            totalPreCharge += (item.price * agreement.preCharge) / 100;
            console.log("✈️ item.price", item.price);
            console.log("✈️ totalPreCharge", totalPreCharge);
            bookingids.push(item.bookingid);
            email = item.email;
            venue = item.venue;
            artist = item.artist;
            ticketEventIds.push(item.ticketEventId);
            ticketVenueId = item.ticketVenueId;

            let currentDate = new Date(item.preChargeAt);
            if (!earliestDate || currentDate < earliestDate) {
              earliestDate = currentDate;
            }
          }

          if (new Date(earliestDate) < new Date(today)) earliestDate = new Date(today);
          // alert("precharge: " + totalPreCharge);
          aggregatedData.push({
            OCR: agreement.OCR,
            artist: artist,
            email: email,
            weekNumber: parseInt(weekNumber),
            originalAmount: makeNumber(totalPreCharge),
            amount: totalPreCharge.toFixed(2),
            due: earliestDate.toISOString().split("T")[0],
            nextDateToCheck: earliestDate.toISOString().split("T")[0],
            bookingids: bookingids,
            status: "IDLE",
            paymentBody: getPreChargeBody(bookings && bookings[0], makeNumber(totalPreCharge), agreement.OCR),
            reference: referenceId,
            venue: venue,
            vat: 0,
            type: "PRECHARGE",
            agreementId: agreementId,
            ticketEventIds: ticketEventIds,
            ticketVenueId: ticketVenueId,
          });
        }
      }

      //balance
      async function createBalancePayment() {
        for (const weekNumber of Object.keys(sorted)) {
          let earliestDate = null;
          let totalBalance = 0;
          let bookingids = [];
          let email = "";
          let venue;
          let artist;
          let ticketEventIds = [];
          let ticketVenueId;
          const referenceId = `end${createMidId()}`;

          for (const item of sorted[weekNumber]) {
            console.log("✈️ item ", item, agreement);
            totalBalance += (item.price * agreement.balance) / 100;
            bookingids.push(item.bookingid);
            email = item.email;
            venue = item.venue;
            artist = item.artist;
            ticketEventIds.push(item.ticketEventId);
            ticketVenueId = item.ticketVenueId;

            let currentDate = new Date(item.balanceAt);
            if (!earliestDate || currentDate < earliestDate) {
              earliestDate = currentDate;
            }
          }
          // alert(`referenceId ${referenceId}`);
          console.log("för QR", totalBalance, agreement.OCR);
          // alert(`qr Balance ${qr}`);

          aggregatedData.push({
            OCR: agreement.OCR,
            artist: artist,
            email: email,
            weekNumber: parseInt(weekNumber),
            originalAmount: makeNumber(totalBalance),
            amount: totalBalance.toFixed(2),
            due: earliestDate.toISOString().split("T")[0],
            nextDateToCheck: earliestDate.toISOString().split("T")[0],
            bookingids: bookingids,
            status: "IDLE",
            paymentBody: getEndChargeBody(bookings && bookings[0], makeNumber(totalBalance), agreement.OCR),
            reference: referenceId,
            venue: venue,
            vat: 0,
            type: "BALANCE",
            agreementId: agreementId,
            ticketEventIds: ticketEventIds,
            ticketVenueId: ticketVenueId,
          });
        }
      }

      // //settlement (skapa en för varje bokning i avtalet)
      // async function createSettlementPayment() {
      //   for (const item of bookings) {
      //     let totalSettlementCharge = item.price - (item.price * (agreement.upfront + agreement.preCharge + agreement.charge)) / 100;
      //     let earliestDate = new Date(item.date);
      //     const referenceId = `set${createMidId()}`;

      //     aggregatedData.push({
      //       OCR: agreement.OCR,
      //       artist: item.artist.artist,
      //       email: item.agent.email,
      //       weekNumber: "",
      //       originalAmount: Number(totalSettlementCharge.toFixed(2)),
      //       amount: totalSettlementCharge.toFixed(2),
      //       due: earliestDate.toISOString().split("T")[0],
      //       nextDateToCheck: earliestDate.toISOString().split("T")[0],
      //       bookingids: [item.bookingid],
      //       status: "IDLE",
      //       paymentBody: getSettlementChargeBody(bookings && bookings[0], totalSettlementCharge.toFixed(2), agreement.OCR),
      //       reference: referenceId,
      //       venue: item.venue.venue,
      //       vat: 0,
      //       type: "SETTLEMENT",
      //       agreementId: agreementId,
      //       ticketEventIds: item.ticketEventId,
      //       ticketVenueId: item.ticketVenueId,
      //     });
      //   }
      // }
    }
  }, [sorted]);

  async function sortAggregatedData(aggregatedData) {
    await aggregatedData.sort((a, b) => new Date(a.due) - new Date(b.due));
  }

  async function reduceByAlreadyPaid(aggregatedData) {
    amountAlreadyPaid += await ticketSales(aggregatedData[0]); // Add the current ticket sales to the amountAlreadyPaid
    let totalAmountToBePaidByNow = 0;
    for (let item of aggregatedData) {
      // reduce each payment with the amountAlreadyPaid and reduce amountAlreadyPaid by paymentPost amount until amountAlreadyPaid is 0
      console.log("originalAmount", item.originalAmount);
      totalAmountToBePaidByNow += Number(item.originalAmount); // Add up the total amount to be paid by now in order for CHECKER to know if amount is paid in full or covered by ticket sales.
      item.totalAmountToBePaidByNow = Number(totalAmountToBePaidByNow.toFixed(2));

      if (amountAlreadyPaid > 0) {
        if (item.amount > amountAlreadyPaid) {
          // change amount in paymentPost
          item.amount -= amountAlreadyPaid;
          item.paymentBody.amount.value = item.amount.toFixed(2);
          item.paymentBody.settlementCreditors[0].amount = item.amount.toFixed(2);
          amountAlreadyPaid = 0; // Since the amountAlreadyPaid is now less than what should be paid, we can break the loop
        }
        if (item.amount <= amountAlreadyPaid) {
          amountAlreadyPaid -= item.amount;
          // change amount in paymentPost
          item.amount = 0;
          item.paymentBody.amount.value = item.amount.toFixed(2);
          item.paymentBody.settlementCreditors[0].amount = item.amount.toFixed(2);
        }
      }
      let qr = await generateAndUploadQRCode({
        bg: "363-0274",
        amount: item.amount,
        ocr: agreement.OCR,
        referenceId: item.reference,
      });
      item.qr = qr;
      console.log(item.due);
      console.log("item with paymentbody", item);
    }
  }

  useEffect(() => {
    if (nextPosts.length) {
      // alert(`nextPosts.length: ${nextPosts.length}`);
      nextPosts.sort((a, b) => new Date(a.due) - new Date(b.due));
    }
  }, [nextPosts]);

  useEffect(() => {
    //store PaymentPosts of upfront, preCharge and endCharge to db in "PaymentPosts".

    if (nextPosts.length) {
      console.log("NextPosts", nextPosts);
      nextPosts.forEach(async (item) => {
        console.log("item=>", item);
        await store(item);
        // await createDebitPost(item.email, item.endCharge ? item.endCharge : item.preCharge, "b", item.reference);
      });

      async function store(item) {
        await setDoc(doc(db, "PaymentPosts", item.reference), {
          ...item,
        });
      }
    }
  }, [nextPosts]);

  //For TESTING PURPOSES ONLY
  async function deleteAllPosts() {
    const querySnapshot = await getDocs(query(collection(db, "PaymentPosts")));
    querySnapshot.forEach(async (item) => {
      //   console.log(item.data().reference);
      await deleteDoc(doc(db, "PaymentPosts", item.data().reference));
    });
  }

  if (!loading)
    return (
      <div style={{ display: "flex", gap: "15px" }}>
        {agreement && <ObjectSize dataObject={agreement} name="Agreement" />}
        {console.log("NEXTPOSTS", nextPosts)}
        <div>
          {/* <input type="text" value={agreementId} placeholder="enter agreementId" onChange={(e) => setAgreementId(e.target.value)} /> */}
        </div>

        <div>
          {loading && (
            <>
              <CircularProgress /> <h1>Loading</h1>
            </>
          )}
          AgreementId: <h6>{agreementId}</h6>
          Type: <h6>{type}</h6>
          Span: <h6>{span}</h6>
          Upfront: <h6>Direct</h6>
          {console.log("agreement", agreement)}
          PreCharge: <h6>{agreement && agreement.preChargeAt} days ahead</h6>
          Balance: <h6>{agreement && agreement.balanceAt} days ahead</h6>
          {nextPosts.map((item, index) => (
            <>
              <div>{/* <EPCQRCode bg={"363-0274"} amount={item.amount} ocr={"1234 5678 1234 5678"} /> */}</div>
              {/* <EPCQRCode bg={"363-0274"} amount={item.amount} ocr={"1234 5678 1234 5678"} /> */}
              {item.qr && <img src={item.qr} alt="qr" />}
              <div style={{ marginTop: "20px" }}>
                <div>
                  <b>Due Date: {item.due}</b>
                </div>
                {item.bookingids.map((bookingid) => (
                  <div style={{ opacity: "0.5", fontSize: "10px" }}>
                    {bookingid} date:{bookings.find((booking) => booking.bookingid === bookingid).date}
                  </div>
                ))}
              </div>

              {/* <div>Avser: {item.totalPreCharge ? `Deposition: ${item.totalPreCharge}` : `Sluthyra: ${item.totalEndCharge}`}</div> */}
              <div>Belopp: {item.amount}</div>
              <div>Avser: {item.type}</div>
              <div>Artist: {item.artist}</div>
              <div>OCR: {item.OCR}</div>
              <div>Total amount to be paid by now: {item.totalAmountToBePaidByNow}</div>

              {console.log("item now", item)}
            </>
          ))}
        </div>
        {
          <div>
            {!loading && (
              <Button onClick={() => RunOrderChanges()} variant="outlined">
                Run OrderChanges
              </Button>
            )}
            {changesInAgreement.length > 0 && (
              <div>
                <table className="summaryTable">
                  <thead>
                    <th>BookingId</th>
                    <th>Option</th>
                    <th>Quantity</th>
                    <th>Each</th>
                    <th>Sum</th>
                    <th>Vat</th>
                  </thead>
                  <tbody>
                    {changesInAgreement.map((change) => (
                      <tr>
                        <td>{change.bookingId}</td>
                        <td>{change.type}</td>
                        <td>{change.quantity}</td>
                        <td>{change.cost}</td>
                        <td>{change.sum}</td>
                        <td>{change.vat}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                Sum of changes: {changesInAgreement.sumOfChanges}
              </div>
            )}
          </div>
        }
        <div>
          <Button onClick={() => deleteAllPosts()} variant="outlined">
            Delete all paymentPosts
          </Button>
        </div>
      </div>
    );
};

export default Settler;

//
//
//
function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}
