/** @format */

import { Button, Grid, Icon, Modal, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { venueObj } from "../../dbObjects.js/dbVenue";
import PreferredDays from "../Sidebar/PreferredDays";
import { ImageUploader } from "../UI helpers/ImageUpload/ImageUploader";
import { updateDocument } from "../../firebase";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Navigate, useNavigate } from "react-router-dom";
import AddCoordinatesOnMap from "../MiscUIComponents/AddCoordinatesOnMap";
import { Box } from "@mui/system";
import GoogleCalendar from "../MiscUIComponents/GoogleCalendar";
import Products from "./Products";
import RoomIcon from "@mui/icons-material/Room";

const VenueForm = () => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { goBack } = useNavigate();
  const [coordinates, setCoordinates] = useState(undefined);
  const [formValues, setFormValues] = useState({
    timeSlot: [{ startTime: "14", endTime: "20" }],
    preferredDays: ["Friday", "Saturday"],
    bookings: [],
  });
  const key = process.env.REACT_APP_GOOGLE_API_KEY;
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const prefDays = useSelector((state) => state.counter.prefDays);
  const [loading, setLoading] = useState(true);
  const venueFormArray = venueObj;
  var showForm = () => {};
  //To determine if new section in order to apply accordion start/end
  var sectionA;
  var sectionB;
  // if (currentVenue == undefined) return <div>...is loading...</div>;
  const getAddressFromCoordinates = async () => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.latitude},${coordinates.longitude}&key=${key}`
    );
    const data = await response.json();
    const addressComponents = data.results[0].address_components;
    const formattedAddress = data.results[0].formatted_address;
    let address = "";
    let postalCode = "";
    let city = "";
    let country = "";
    let venueCounty = "";
    let venueCountyB = "";

    addressComponents.forEach((component) => {
      if (component.types.includes("street_number")) {
        address += component.long_name + " ";
      } else if (component.types.includes("route")) {
        address += component.long_name;
      } else if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      } else if (component.types.includes("postal_town")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("sublocality_level_1")) {
        venueCounty = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        venueCountyB = component.long_name;
      }
    });
    setFormValues({
      ...formValues,
      venueStreetAddress: address,
      venueZipCode: postalCode,
      venueCity: city,
      venueCountry: country,
      venueCounty: venueCounty,
      venueCountyB: venueCountyB,
    });
  };

  // useEffect(() => {
  //   console.log("formvalues123123123", formValues);
  // }, [formValues]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1);
    const keyValuePairs = Object.entries(currentVenue);
    const updatedFormValues = keyValuePairs.reduce((acc, [key, value]) => {
      return { ...acc, [key]: value };
    }, formValues);
    // console.log(updatedFormValues);

    setFormValues(updatedFormValues);
  }, [currentVenue]);

  useEffect(() => {
    if (coordinates) {
      getAddressFromCoordinates();
    }
  }, [coordinates]);

  // set values on product change
  useEffect(() => {
    const tempData = { ...formValues, ...currentProduct };
    setFormValues(tempData);
  }, [currentProduct]);

  function removeTimeSlot(index) {
    const tempTimeSlot = [...formValues.timeSlot];
    tempTimeSlot.splice(index, 1);
    setFormValues({
      ...formValues,
      timeSlot: tempTimeSlot,
    });
  }

  //Set preferred Days if changed
  // useEffect(() => {
  //   if (prefDays !== formValues.preferredDays)
  //     setFormValues({
  //       ...formValues,
  //       preferredDays: "Monday",
  //     });
  // }, [prefDays]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  if (loading === true) {
    return <div>Loading...</div>;
  }
  if (loading === false)
    return (
      <Grid p={4}>
        <h1>{currentVenue && currentVenue.venue}</h1>

        <Grid item className="imageUpload" direction="column" xs={4}>
          <ImageUploader storageFolder={"venueImages"} formImageKey={"venueImage"} setFormValues={setFormValues} formValues={formValues} />
          {formValues.venueImage && (
            <Button
              onClick={() => {
                // console.log("removing image");
                setFormValues({
                  ...formValues,
                  venueImage: "",
                });
              }}
            >
              Change Image
            </Button>
          )}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12} sm={6} sx={{ marginTop: 0 }}>
          <TextField
            defaultValue=""
            className="venueDescriptionTextField"
            label="Venue description"
            multiline
            rows="8"
            variant="outlined"
            value={formValues.venueDescription || ""}
            size="small"
            onChange={(event) => {
              setFormValues({
                ...formValues,
                venueDescription: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid container xs={12} sm={8} spacing={3}>
          {/* {console.log(currentVenue)} */}
          {venueFormArray ? (
            venueFormArray.map((formfield) => (
              <Grid item>
                {formfield.type == "Section" && <div className="formSectionLabel">{formfield.section}</div>}

                {formfield.section == "Products" && formfield.type !== "Section" && <Products />}

                {formfield.section == "Account" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id]}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}

                {formfield.section == "Location" && formfield.type !== "Section" && formfield.type !== "Button" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id] || ""}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {formfield.section == "Location" && formfield.type !== "Section" && formfield.type === "Button" && (
                  <Button
                    onClick={() => {
                      handleOpen();
                    }}
                    size="md"
                    variant="outlined"
                    color="secondary"
                  >
                    <RoomIcon />
                    Set
                  </Button>
                )}

                {formfield.section == "Audience" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id] || ""}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: parseInt(event.target.value),
                      });
                    }}
                  />
                )}

                {formfield.section == "Pricing" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id] || ""}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {formfield.section == "Stage" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id] || ""}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {formfield.section == "Lights" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id] || ""}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {formfield.section == "Sound Equipment" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id] || ""}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {formfield.section == "Rooms" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    value={formValues[formfield.id] || ""}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {formfield.section == "Standard Running Times" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    size="small"
                    value={formValues[formfield.id]}
                    style={{ width: "170px" }}
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {formfield.section == "Type" && formfield.type !== "Section" && (
                  <ToggleButtonGroup
                    size="small"
                    onChange={(event, val) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: val,
                      });
                    }}
                  >
                    {formfield.options.map((type) => (
                      <ToggleButton size="small" value={type} sx={{ fontSize: 11 }}>
                        {/* {console.log(formValues)} */}
                        {type}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
                {formfield.section == "Preferred Days" && formfield.type !== "Section" && (
                  <PreferredDays
                    // setFormValues={setFormValues}
                    // formfield={formfield.id}
                    // formValues={formValues}
                    prefDays={formValues.preferredDays}
                    numberOfLetters={10}
                  />
                )}
                {formfield.section == "Time Slot" && formfield.type !== "Section" && (
                  <Grid item xs={4} mt={-5}>
                    {/* <div className="formSectionLabel">Time Slot</div> */}
                    <div className="formSectionLabel"></div>
                    {formValues.timeSlot.map((timeSlot, index) => (
                      <div
                        key={index}
                        style={{
                          margin: "10px 10px",
                        }}
                      >
                        <TextField
                          size="small"
                          label={`Start Time`}
                          sx={{
                            width: "100px",
                            marginRight: "15px",
                            marginTop: "10px",
                          }}
                          value={timeSlot.startTime}
                          onChange={(event) => {
                            const newTimeSlot = formValues.timeSlot.map((t, i) =>
                              i === index
                                ? {
                                    ...t,
                                    startTime: event.target.value,
                                  }
                                : t
                            );
                            setFormValues({
                              ...formValues,
                              timeSlot: newTimeSlot,
                            });
                          }}
                        />
                        <TextField
                          style={{
                            width: "100px",
                            marginTop: "10px",
                          }}
                          size="small"
                          label={`End Time`}
                          value={timeSlot.endTime}
                          onChange={(event) => {
                            const newTimeSlot = formValues.timeSlot.map((t, i) =>
                              i === index
                                ? {
                                    ...t,
                                    endTime: event.target.value,
                                  }
                                : t
                            );
                            setFormValues({
                              ...formValues,
                              timeSlot: newTimeSlot,
                            });
                          }}
                        />
                        <Button
                          style={{
                            marginLeft: "15px",
                            marginTop: "12px",
                            fontSize: "10px",
                          }}
                          size="small"
                          onClick={() => {
                            removeTimeSlot(index);
                          }}
                        >
                          <RemoveCircleOutlineIcon
                            size="25"
                            style={{
                              marginRight: "10px",
                            }}
                          />{" "}
                          Remove
                        </Button>
                      </div>
                    ))}

                    <Button
                      size="small"
                      style={{
                        marginTop: "20px",
                        fontSize: "12px",
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setFormValues({
                          ...formValues,
                          timeSlot: [
                            ...formValues.timeSlot,
                            {
                              startTime: "",
                              endTime: "",
                            },
                          ],
                        });
                      }}
                    >
                      <icon>
                        <AddIcon size={25} style={{}} />
                      </icon>
                      Add Timeslot
                    </Button>
                  </Grid>
                )}
                {formfield.section == "Images" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )}
                {/* {formfield.section == "Bookings" && formfield.type !== "Section" && (
                  <TextField
                    {...formfield}
                    size="small"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        [formfield.id]: event.target.value,
                      });
                    }}
                  />
                )} */}
              </Grid>
            ))
          ) : (
            <></>
          )}

          <Grid item>
            {/* <Button
              onClick={() => {
                // Use the navigator.geolocation.getCurrentPosition() method to get the user's current coordinates
                navigator.geolocation.getCurrentPosition((position) => {
                  // Update the state using the setCoordinates function
                  setFormValues({
                    ...formValues,
                    venueLat: position.coords.latitude,
                    venueLng: position.coords.longitude,
                  });
                  setCoordinates({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  });
                });
              }}
            >
              Get Coordinates
            </Button> */}
            inRedux:{prefDays}
            Current:{currentVenue.preferredDays}
            {/* {console.log(formValues)} */}
            <Button
              onClick={async () => {
                // await setFormValues({
                //   ...formValues,
                //   preferredDays: prefDays,
                // });
                // console.log('------------------->', formValues);
                //first remove 'bookings'-array
                // const tempFormValues = { ...formValues, bookings: [1, 2, 3] };

                // then update document
                // updateDocument("Venues", tempFormValues, formValues.venueid);

                await updateDocument("Venues", formValues, formValues.venueid);
                setTimeout(() => {
                  navigate("/Owner/Home");
                }, 500);
              }}
              variant="contained"
            >
              Save
              {/* {console.log('=====> > > ', formValues)} */}
            </Button>
            <Button variant="contained" color="info" style={{ marginLeft: "15px" }} onClick={() => navigate("/Owner/Home")}>
              Cancel
            </Button>
          </Grid>
        </Grid>

        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <AddCoordinatesOnMap
              handleClose={handleClose}
              setCoordinates={setCoordinates}
              setFormValues={setFormValues}
              formValues={formValues}
            />
          </Box>
        </Modal>
      </Grid>
    );

  setLoading(true);
};

export default VenueForm;
