/** @format */

//ISSUES:
// - fel värden visas, eller följer med när man väljer vissa options.
// !! Kanske har att göra med eftersläpet i useState, kolla om det går att använda "let" istället.

import { CollectionsBookmarkOutlined, CollectionsOutlined } from "@mui/icons-material";
import { Grid, Switch, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { venueObj } from "../../dbObjects.js/dbVenue";
import { numberFormatted } from "../../helpers/Numbers";
import Checkbox from "@mui/material/Checkbox";
import { featureCategories, getIcon } from "../MiscUIComponents/VenueAdditionals";
import FlakyIcon from "@mui/icons-material/Flaky";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import DisplayObject from "../../helpers/displayObject";
import RentalFeeModule from "../ProductFeatures/RentalFeeModule";
import StageModule from "../ProductFeatures/StageModule";
import CateringModule from "../ProductFeatures/CateringModule";
import VatSelector from "../MiscUIComponents/VatSelector";
import StaffModule from "../ProductFeatures/StaffModule";

const ProductFeatures = ({ productData, setProductData, setValue }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const globalFeatures = currentVenue.features && currentVenue.features;
  const localFeatures = productData.features && productData.features;
  //   const hidden = ["block", "white", "10px"];
  const hidden = ["block", "#333", "10px"];

  // Set productFeatures to globalFeatures and override with localFeatures (if present)
  const [productFeatures, setProductFeatures] = useState({ ...globalFeatures, ...localFeatures });
  // console.log("globalFeatures new", globalFeatures);
  // console.log("localFeatures new", localFeatures);
  const featureColor = "white";
  const [productSum, setProductSum] = useState(0);

  const removeOldFeatures = () => {
    // alert("removeOldFeatures");
    // Edits in globalFeatures overrides localFeatures. If a globalFeature is removed it's removed locally.
    // and if a globalFeature is edited it's edited locally as well...
    //...except for selected, price and optional fields.
    let globals = JSON.parse(JSON.stringify(globalFeatures));
    let newLocals = JSON.parse(JSON.stringify(globalFeatures));
    console.log("globals", globals);
    console.log("localFeatures", localFeatures);

    let locals = Object.values(globals).map((feature) => {
      newLocals[feature.additionalId] = { ...feature, options: { ...feature.options } };

      console.log("newLocals", newLocals);

      localFeatures[feature.additionalId] !== undefined &&
        Object.values(localFeatures[feature.additionalId].options).map((option) => {
          // console.log(newLocals, feature.additionalId, option.optionId);
          // console.log("options", localFeatures[feature.additionalId]);
          // console.log("additionalid", feature.additionalId);
          // console.log("optionid", option.optionId);
          // console.log("option", newLocals[feature.additionalId].options[option.optionId]);
          if (newLocals[feature.additionalId].options[option.optionId] === undefined) {
            return;
          }
          if (option.selected) newLocals[feature.additionalId].options[option.optionId].selected = option.selected;
          if (option.price) newLocals[feature.additionalId].options[option.optionId].price = option.price;
          if (option.optional) newLocals[feature.additionalId].options[option.optionId].optional = option.optional;
        });
    });

    setProductFeatures(newLocals);
  };

  useEffect(() => {
    localFeatures !== undefined && removeOldFeatures();
  }, []);

  const handleSelect = async (checked, additionalId, optionId, type) => {
    let tmp = JSON.parse(JSON.stringify(productFeatures));
    // alert("price: " + tmp[additionalId].options[optionId].price + " each: " + tmp[additionalId].options[optionId].each);
    //set product.price
    // checked &&
    //   tmp[additionalId].additional === "Rental Fee" &&
    //   //   setProductData((prev) => ({ ...prev, price: numberFormatted(tmp[additionalId].options[optionId].price) }));
    //   setProductData((prev) => ({ ...prev, price: numberFormatted(productSum) }));
    // console.log("productData", productData);

    // set "each" if not set
    if (tmp[additionalId].options[optionId].each === 0) {
      // if (tmp[additionalId].options[optionId].each === undefined && tmp[additionalId].options[optionId].price > 0) {
      // alert("price: " + tmp[additionalId].options[optionId].price + " each: " + tmp[additionalId].options[optionId].each);
      tmp[additionalId].options[optionId].each = tmp[additionalId].options[optionId].price; // if each is not provided, set it to "price"
    }

    // set quantity to 1 if selected
    if (checked === true) {
      tmp[additionalId].options[optionId].quantity = 1;
    } else {
      tmp[additionalId].options[optionId].quantity = 0;
    } // if quantity is not provided, set it to 1

    //set capacity
    tmp[additionalId].additional === "Capacity" &&
      setProductData((prev) => ({ ...prev, capacity: numberFormatted(tmp[additionalId].options[optionId].capacity) }));

    //Check if unique
    if (type === "unique") {
      tmp = await setSelectedToFalseForAllOptions(additionalId);
    }

    // Turn strings into actual numbers if they are not
    tmp[additionalId].options[optionId].price = Number(tmp[additionalId].options[optionId].price);
    tmp[additionalId].options[optionId].each = Number(tmp[additionalId].options[optionId].each);
    tmp[additionalId].options[optionId].quantity = Number(tmp[additionalId].options[optionId].quantity);
    tmp[additionalId].options[optionId].CO2e = Number(tmp[additionalId].options[optionId].CO2e);

    // store state
    tmp[additionalId].options[optionId].selected = checked;
    setProductData((prev) => ({ ...prev, features: tmp }));
    setProductFeatures(tmp);

    console.log("current piece", tmp[additionalId].options[optionId]);
    // alert("changed q to:" + tmp[additionalId].options[optionId].quantity);
  };

  const handleOptional = (checked, additionalId, optionId) => {
    const tmp = JSON.parse(JSON.stringify(productFeatures));
    // console.log("See if checked?", checked);
    tmp[additionalId].options[optionId].optional = checked; //checked is either true or false
    setProductData((prev) => ({ ...prev, features: tmp }));
    setProductFeatures(tmp);
  };

  const handleChangeValue = (additionalId, optionId, key, value) => {
    const tmp = JSON.parse(JSON.stringify(productFeatures));
    // alert("Change value" + tmp[additionalId].options[optionId]["price"]);
    // some conditionals if value is not set for either each, price or quantity
    tmp[additionalId].options[optionId]["quantity"] === undefined && (tmp[additionalId].options[optionId]["quantity"] = 1); // if quantity is not provided, set it to 1
    if (key === "price") tmp[additionalId].options[optionId]["each"] = Number(value); // if price is set, set each to price
    //  tmp[additionalId].options[optionId]["each"] === undefined && (tmp[additionalId].options[optionId]["each"] = 0); //if neither each or price set each to 0
    // console.log("Change value:", additionalId, optionId, key, value);
    tmp[additionalId].options[optionId][key] = value;
    console.log("new value?", tmp);
    setProductData((prev) => ({ ...prev, features: tmp }));
    setProductFeatures(tmp);
  };

  // This is to update productData on startup...
  useEffect(() => {
    setProductData((prev) => ({ ...prev, features: productFeatures }));
  }, [productFeatures]); //THIS!!!

  //   useEffect(() => {
  //     console.log("productFeatures", productFeatures);
  //     console.log("productData", productData);
  //     // setProductData((prev) => ({ ...prev, features: productFeatures }));
  //   }, [productFeatures]);

  const setSelectedToFalseForAllOptions = (additionalId) => {
    // Set all selected===false (happens if an option with type==="unique" is set in order to only have one at the time)
    //first get all options for capacity
    let featureOptions = Object.values(productFeatures).find((obj) => obj.additionalId === additionalId);
    // then make a temporary "options"...
    let tmpOptions = JSON.parse(JSON.stringify(featureOptions.options));
    // ...to set all selected to false
    Object.values(tmpOptions).map((option) => (option.selected = false));
    // lastly make temporary features...
    let tmpFeatures = JSON.parse(JSON.stringify(productFeatures));
    // ...and set the options of this feature to new values (selected===false)
    tmpFeatures[additionalId].options = tmpOptions;
    // ...to return tmpFeatures to become tmp in handleSelect
    return tmpFeatures;
  };

  const handleUniqueOnOff = (feature) => {
    const checked = !feature.unique;
    let tmp = { ...productFeatures };
    tmp[feature.additionalId].unique = checked;
    setProductFeatures(tmp);
  };

  const handleFeatureOptionalOnOff = (feature) => {
    const checked = !feature.optional;
    let tmp = { ...productFeatures };
    tmp[feature.additionalId].optional = checked;
    if (checked === false) {
      Object.values(tmp[feature.additionalId].options).map((option) => {
        tmp[feature.additionalId].options[option.optionId].optional = false;
        // delete tmp[feature.additionalId].options[option.optionId].optional;
      });
    }
    if (checked === true) {
      Object.values(tmp[feature.additionalId].options).map((option) => {
        tmp[feature.additionalId].options[option.optionId].optional = true;
      });
    }
    // console.log("productData", productData);
    // console.log("ProductFeaturesroductData", tmp);
    setProductData((prev) => ({ ...prev, features: tmp }));
    setProductFeatures(tmp);
  };

  return (
    <Grid container className="productFeatures">
      {Object.values(productFeatures).length === 0 && (
        <div className="Reminder" onClick={() => setValue("Features")}>
          Add features..?
        </div>
      )}
      <Grid item xs={12} sm={6}>
        {console.log("THIS PRODUCT FEATURES", productFeatures)}
        {productFeatures &&
          Object.values(productFeatures).map((feature) => (
            // <table className="overviewTable">
            <Grid item className="overviewTable" sx={{ width: { xs: "390px", sm: "800px" } }}>
              {feature.type === "capacityFields" && (
                <>
                  <h6 style={{ textAlign: "left", color: featureColor }}>{getIcon(feature.additional)} Capacity</h6>
                  <table>
                    <thead>
                      <th>Select</th>
                      <th>Option</th>
                      <th>Total</th>
                      <th>Seated</th>
                      <th>Standing</th>
                      <th>Additional Cost</th>
                      <th>Vat</th>
                      <th>CO2e</th>
                      {/* <th>Make optional</th> */}
                    </thead>
                    {feature.options &&
                      Object.values(feature.options).map((option) => (
                        <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
                          <td>
                            <Switch
                              //   value={productFeatures[feature.additionalId].options[option.optionId].selected}
                              //   value={option.selected}
                              //   onChange={(event) => handleSelect(event.target.checked, feature.additionalId, option.optionId, "unique")}
                              onChange={(event) =>
                                handleSelect(event.target.checked, feature.additionalId, option.optionId, feature.unique ? "unique" : null)
                              }
                              checked={option.selected ? true : false}
                            />
                          </td>
                          <td>{option.option}</td>
                          <td>{option.capacity}</td>

                          <td>{option.capSeated}</td>
                          <td>{option.capStanding}</td>
                          <td>
                            <input
                              type="number"
                              disabled={!option.selected}
                              value={numberFormatted(option.price)}
                              onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value)}
                            />
                          </td>
                          <td>
                            <VatSelector
                              handleChangeOption={handleChangeValue}
                              value={option.vat}
                              item={feature}
                              optionId={option.optionId}
                            />

                            {/* <input
                              type="number"
                              disabled={!option.selected}
                              value={numberFormatted(option.vat)}
                              onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "vat", e.target.value)}
                            /> */}
                          </td>
                          <td>{option.climate}</td>
                          {/* <td>
                            <Checkbox
                              disabled={!option.selected}
                              defaultChecked={option.optional}
                              onChange={(event) => handleOptional(event.target.checked, feature.additionalId, option.optionId)}
                            />
                          </td> */}
                        </tr>
                      ))}
                  </table>
                </>
              )}
              {feature.type === "rentalFeeFields" && (
                <RentalFeeModule
                  featureColor={featureColor}
                  feature={feature}
                  handleSelect={handleSelect}
                  handleChangeValue={handleChangeValue}
                  getIcon={getIcon}
                />
                // <>
                //   <h6 style={{ textAlign: "left", color: featureColor }}>{getIcon(feature.additional)} Rental fee</h6>
                //   <table>
                //     <thead>
                //       <th>Select</th>
                //       <th>Option</th>
                //       <th>Price</th>
                //       <th>CO2e</th>
                //       {/* <th>Make optional</th> */}
                //     </thead>
                //     {feature.options &&
                //       Object.values(feature.options).map((option) => (
                //         <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
                //           <td>
                //             <Switch
                //               onChange={(event) => handleSelect(event.target.checked, feature.additionalId, option.optionId, "unique")}
                //               checked={option.selected ? true : false}
                //             />
                //           </td>
                //           <td>{option.option}</td>
                //           <td>
                //             <input
                //               disabled={!option.selected}
                //               type="number"
                //               value={option.price}
                //               onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value)}
                //             />
                //           </td>
                //           <td>{option.climate}</td>
                //         </tr>
                //       ))}
                //   </table>
                // </>
              )}
              {feature.type === "stageFields" && (
                <StageModule
                  featureColor={featureColor}
                  feature={feature}
                  handleSelect={handleSelect}
                  handleChangeValue={handleChangeValue}
                  getIcon={getIcon}
                />
                // <>
                //   <h6 style={{ textAlign: "left", color: featureColor }}>{getIcon(feature.additional)} Stage</h6>
                //   <table>
                //     <thead>
                //       <th>Select</th>
                //       <th>Option</th>
                //       <th>Width</th>
                //       <th>Depth</th>
                //       <th>Total Height</th>
                //       <th>Clearance Rig</th>
                //       <th>Additional Cost</th>
                //       <th>CO2e</th>
                //       {/* <th>Make optional</th> */}
                //     </thead>
                //     {feature.options &&
                //       Object.values(feature.options).map((option) => (
                //         <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
                //           <td>
                //             <Switch
                //               checked={option.selected ? true : false}
                //               onChange={(event) =>
                //                 handleSelect(event.target.checked, feature.additionalId, option.optionId, feature.unique ? "unique" : null)
                //               }
                //             />
                //           </td>
                //           <td>{option.stageLabel}</td>
                //           <td>{option.stageWidth}</td>
                //           <td>{option.stageDepth}</td>
                //           <td>{option.stageClearanceTotal}</td>
                //           <td>{option.stageClearanceToRig}</td>
                //           <td>
                //             <input
                //               disabled={!option.selected}
                //               type="number"
                //               value={option.price}
                //               onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value)}
                //             />
                //           </td>
                //           <td>{option.climate}</td>
                //         </tr>
                //       ))}
                //   </table>
                // </>
              )}
              {feature.type === "cateringFields" && (
                <CateringModule
                  featureColor={featureColor}
                  feature={feature}
                  handleSelect={handleSelect}
                  handleChangeValue={handleChangeValue}
                  getIcon={getIcon}
                />
              )}
              {feature.type === "staffFields" && (
                <div>
                  <StaffModule
                    featureColor={featureColor}
                    feature={feature}
                    handleSelect={handleSelect}
                    handleChangeValue={handleChangeValue}
                    getIcon={getIcon}
                  />
                </div>
              )}
              {feature.type === "standardFields" && (
                <>
                  <h6 style={{ textAlign: "left", color: featureColor }}>
                    {getIcon(feature.additional)} {feature.additional}{" "}
                    <span
                      style={{ fontSize: "12px", fontWeight: "100", opacity: "0.5", marginLeft: "10px" }}
                      // onClick={() => setProductFeatures(prev=>({...prev,[feature.additionalId][unique]:false}))}
                      onClick={() => handleUniqueOnOff(feature)}
                      //   onClick={() => console.log("HÄR", productFeatures)}
                    >
                      {feature.unique ? (
                        <>
                          <RemoveDoneIcon color="primary" /> Single Choice
                        </>
                      ) : (
                        <>
                          <DoneAllIcon /> Multi Choice
                        </>
                      )}
                    </span>
                    {/* <span
                      style={{ fontSize: "12px", fontWeight: "100", opacity: "0.5", marginLeft: "10px" }}
                      onClick={() => handleFeatureOptionalOnOff(feature)}
                    >
                      {feature.optional ? (
                        <>
                          <FlakyIcon color="primary" /> Remove optional
                        </>
                      ) : (
                        <>
                          <FlakyIcon style={{ opacity: "0.4" }} /> Make optional
                        </>
                      )}
                    </span> */}
                  </h6>

                  <table>
                    <thead>
                      <th className="featureSelect">Select</th>
                      <th className="featureOption">Option</th>
                      <th>Info</th>
                      <th>Specs</th>
                      <th>Additional Cost</th>
                      <th>Vat</th>
                      <th>CO2e</th>
                      {/* {feature.optional && <th>Make optional</th>} */}
                      {/* <th>Make optional</th> */}
                    </thead>
                    {feature.options &&
                      Object.values(feature.options).map((option) => (
                        <tr style={{ opacity: option.selected ? 1 : 0.3 }}>
                          <td className="featureSelect">
                            <Switch
                              onChange={(event) =>
                                handleSelect(event.target.checked, feature.additionalId, option.optionId, feature.unique ? "unique" : null)
                              }
                              checked={option.selected ? true : false}
                            />
                          </td>
                          <td className="featureOption">{option.option}</td>
                          <td className="featureInfo">{option.info}</td>
                          <td>{option.specs}</td>
                          <td>
                            <input
                              disabled={!option.selected}
                              type="number"
                              value={option.price}
                              onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value)}
                            />
                          </td>
                          <td>
                            <VatSelector
                              handleChangeOption={handleChangeValue}
                              value={option.vat ? option.vat : "25%"}
                              item={feature}
                              optionId={option.optionId}
                            />
                          </td>
                          <td>{option.climate}</td>
                          {/* {feature.optional && ( */}
                          {/* <td>
                            <Checkbox
                              // disabled={!option.selected}
                              checked={option.optional}
                              onChange={(event) => handleOptional(event.target.checked, feature.additionalId, option.optionId)}
                            />
                          </td> */}
                          {/* )} */}
                        </tr>
                      ))}
                  </table>
                </>
              )}
            </Grid>
          ))}
      </Grid>
      {/* <DisplayObject data={productData} /> */}
    </Grid>
  );
};

export default ProductFeatures;
