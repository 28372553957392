/** @format */

import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Authentication from "../components/Authentication/Authentication";
import { signup, login, logout, useAuth, db } from "../firebase";
import SidebarVenue from "../components/Sidebar/SidebarVenue";
import CheckRole from "../components/Authentication/CheckRole";
import { useSelector } from "react-redux";
import { reloadCalendarBookings } from "../helpers/Listeners";
import { Button, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PieChart from "../components/UI helpers/PieChart";
import { MonthDiff } from "../helpers/AddMonth";
import Example from "../features/bookings/Example";
import DayOfTheWeekMini from "../components/UI helpers/DayOfTheWeekMini";
import TimeLeftRequest from "../components/UI helpers/TimeLeftRequest";
import { quickSend } from "../features/avails/QuickSend";
import RequestAvails from "./RequestAvails";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { collection, getDocs, query } from "firebase/firestore";
import TestMap from "./TestMap";
// import TestMapB from "./TestMapB";
import { MapComponent } from "./TestMapC";
import AddressInput from "./TestMap";
import MailingListForm from "../features/MailingListForm";
import Co2eCalculator from "../components/MiscUIComponents/Co2eCalculator";
import AgreementModal from "../components/Documents/AgreementModal";
import Pricing from "../features/Pricing";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MailingListFormExtended from "../features/MailingListFormExtended";
// import axios from "axios";
// import site from "../utils/site";

const Home = () => {
  const navigate = useNavigate();
  const currentUser = useAuth();
  var itemData = [];
  const [ready, setReady] = useState(false);
  const [venues, setVenues] = useState();
  const userRole = useSelector((state) => state.counter.userRole);
  const email = "owner@slamdunk.live";
  const targetDivRef = useRef(null);
  const location = useLocation();
  const currentURL = location.pathname;
  // userRole = "owner" ? navigate("/Owner") : console.log("Access only to:", userRole);

  function scrollToTargetDiv() {
    if (!targetDivRef.current) return;
    targetDivRef.current.scrollIntoView && targetDivRef.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    setTimeout(() => {
      currentURL === "/" && scrollToTargetDiv();
    }, 2000);
  }, []);

  return (
    <div>
      <div
        className="bodyBkg"
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: "0px",
          // overflow: "hidden",
        }}
      >
        {/* <video autoPlay muted loop id="BillbackVideo" style={{}} defaultPlaybackRate="5">
          <source
            src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/VH-audience.mp4?alt=media&token=f0c4c6a3-33ab-4d48-8162-be521e530294"
            type="video/mp4"
          />
        </video> */}
      </div>
      {/* THIS ! ! ! */}
      {/* <CheckRole /> */}
      {/* ---- PAGE 1 ---- */}
      <div xs={12} className="pageContainer">
        <Grid
          item
          container
          xs={12}
          sx={{ height: "100vh", background: "#00434399" }}
          className="fullPage"
          align="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <superHeader>Perform where it matters</superHeader>
          </Grid>
          <div onClick={() => scrollToTargetDiv()}>
            <KeyboardArrowDownIcon style={{ fontSize: "8vh", opacity: "0.2", transform: "scaleX(2.5)" }} />
          </div>
        </Grid>

        {/* ---- PAGE 2 ---- */}
        <Grid
          ref={targetDivRef}
          item
          container
          xs={12}
          sx={{ height: "100vh", background: "#aaffd433", backdropFilter: "blur(20px)" }}
          className="fullPage"
          spacing={0}
          align="center"
          justifyContent="center"
          direction="column"
        >
          <Grid item xs={12}>
            <centerofpage>
              <MailingListForm />
              {/* <MailingListFormExtended /> */}
            </centerofpage>
          </Grid>
        </Grid>

        {/* ---- PAGE 4 ---- */}
        <Grid
          container
          xs={12}
          sx={{ height: "100vh", background: "#00ffd499" }}
          className="fullPage blurpage"
          spacing={0}
          align="center"
          justifyContent="center"
          direction="column"
        >
          <Grid item xs={12}>
            {/* <centerofpage>
              <Pricing />
            </centerofpage> */}
            <superHeader>
              <div>
                <img
                  className="logoCenter"
                  // src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4"
                />
              </div>
            </superHeader>
            {/* <MailingListForm /> */}
          </Grid>
        </Grid>

        {/* ---- Footer ---- */}
        <Grid
          container
          xs={12}
          px={5}
          sx={{ maxHeight: "100px", background: "#222222" }}
          className="footer"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        ></Grid>
      </div>
    </div>
  );
};

export default Home;
