/** @format */

import React from "react";
import { numberFormatted } from "../../helpers/Numbers";

export const AvtalsingressSwedish = ({ agreement, bookingData, sumIncVat, currency }) => {
  return (
    <>
      <div style={{ fontSize: "12px" }}>
        Det här avtalet är upprättat mellan <strong>{agreement.venue.company || "xx"}</strong> (kallat "Venue") och{" "}
        <strong>{agreement.agent.company || "yy"}</strong> {agreement.agent.orgNumber} {"  "} (kallat "Kund"). Lokalen hyrs ut till Kund och
        Kund hyr från Lokalen belägen på{" "}
        <strong>
          {bookingData.venue.venueStreetAddress}, {bookingData.venue.venueZipCode}
        </strong>{" "}
        {bookingData.venue.venueCity} (kallat "Lokalen"). Lokalen ska användas enligt överenskommelse.
        <br></br>
        <br></br>
        Kunden ska betala till {agreement.venue.company || "xx"}{" "}
        {agreement.upfront > 0 &&
          `inklusive en handpenning på ${agreement.upfront}% av den totala kostnaden för varje bokat tillfälle, som ska betalas ${
            agreement.upfrontAt > 365 ? "direkt." : `senast ${agreement.upfrontAt} dagar före evenemangsdagen.`
          }`}{" "}
        {agreement.preCharge > 0 &&
          `en deposition på ${agreement.preCharge}% av den totala kostnaden för varje bokat tillfälle, senast ${agreement.preChargeAt} dagar före evenemangsdagen.`}
        {agreement.charge > 0 &&
          `Saldobetalning på ${
            100 - parseFloat(agreement.preCharge) - parseFloat(agreement.upfront)
          }% av totalkostnaden för varje bokningstillfälle betalas senast ${agreement.chargeAt} dagar före evenemangsdagen.`}{" "}
        Extra avgifter kan tillkomma för extra tjänster i Settlement.
      </div>
      <div style={{ fontSize: "12px" }}>
        <br></br>
        Avbokningar som görs senare än {agreement.earlyCancelationAt} dagar före evenemangsdagen kommer att debiteras en avbokningsavgift på{" "}
        {agreement.earlyCancelation}% av den totala bokningskostnaden. Avbokningar som görs senare än {agreement.lateCancelationAt} dagar
        före evenemangsdagen kommer att debiteras en avbokningsavgift på {agreement.lateCancelation}% av den totala bokningskostnaden.
        Avbokningar som görs senare än 2 veckor före evenemangsdagen kommer att debiteras en avbokningsavgift på 100% av den totala
        bokningskostnaden, för alla bekräftade åtaganden, kostnader för underleverantörer, personalkostnader, samt kostnader för mat &
        dryck.
      </div>
    </>
  );
};

export const GeneralConditionsSwedish = ({ agreement }) => {
  return (
    <table className="generalConditionsPDF" style={{ display: "grid", overflowWrap: "anywhere", fontSize: "10px" }}>
      <tr>
        <td>
          <strong>ALLMÄNNA AVTALSVILLKOR</strong>
        </td>
      </tr>
      <tr>
        <td>TILLFÄLLIG UTHYRNING AV LOKAL</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          Dessa allmänna avtalsvillkor gäller avseende det avtal om tillfällig uthyrning av lokal som ingåtts mellan Uthyraren och
          Hyrestagaren (”Hyresavtalet”). Uthyraren och Hyrestagaren kallas nedan även ”Parterna” respektive ”Part”.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Upplåtelse av nyttjanderätt </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          1.1 Genom Hyresavtalet upplåter Uthyraren till Hyrestagaren rätten att nyttja Lokalen för det arrangemang/ändamål
          (”Arrangemanget”) som anges i Hyresavtalet. Hyrestagaren har inte rätt att nyttja Lokalen för andra arrangemang/ändamål.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          1.2 I samband med uthyrning av Lokalen ska Uthyraren även tillhandahålla de tjänster och den utrustning som framgår av
          Hyresavtalet inklusive bilagor och/eller dessa allmänna avtalsvillkor.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          1.3 Såvida inte annat anges i Hyresavtalet med bilagor avser Hyrestagarens nyttjanderätt samtliga publika utrymmen i Lokalen
          artistloger samt omklädningsrum.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>1.4 Hyrestagaren disponerar Lokalen de dagar och under de tider som anges i Hyresavtalet inklusive bilagor. </td>
      </tr>
      <tr>
        <td>Lokalens skick och användning</td>
      </tr>
      <tr>
        <td>2.1 Lokalen med tillhörande utrymmen uthyrs i ett väl fungerande skick för det upplåtna ändamålet.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          2.2 Hyrestagaren ska vårda Lokalen väl och tillse att denna återlämnas i samma skick som den befann sig när den överlämnades till
          Hyrestagaren.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          2.3 Hyrestagaren får inte utan Uthyrarens skriftliga tillstånd göra några ändringar i Lokalen inklusive eventuella om- och
          tillbyggnationer. Hyrestagaren förbinder sig att inhämta Uthyrarens godkännande vad gäller samtliga montagearbeten samt uppförande
          av konstruktioner i Lokalen (inklusive byggnationer av scen, montrar, läktare och plattformar). Uthyraren förbehåller sig rätten
          att låta besiktiga sådana arbeten (på Hyrestagarens bekostnad) samt rätten att underkänna sådana arbeten och konstruktioner.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>2.4 Uthyraren svarar för uppvärmning, komfort, kyla, ventilation, vatten och varmvatten. </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>El och teknik</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>3.1 Uthyraren svarar för erforderlig elförsörjning samt för att det finns erforderliga elanslutningar i Lokalen. </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          3.2 Om Parterna har kommit överens om att Uthyraren ska svara för ljud och/eller ljus i Hyresavtalet ska omfattningen av
          Uthyrarens åtagande framgå av den Rider som bifogas Hyresavtalet. Samma sak ska gälla om Uthyraren har åtagit sig att svara för
          annan teknik eller teknisk utrustning såsom AV-utrustning, teleteknik eller datateknik.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Utflyttning och städning</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.1 Hyrestagaren ska återlämna Lokalen senast på det datum och vid den tidpunkt som anges i Hyresavtalet med bilagor. Vid
          återlämning av Lokalen ska Hyrestagaren nedmontera och ta med sig all utrustning samt all övrig egendom som tillhör Hyrestagaren
          (eller någon annan som Hyrestagaren svarar för). Vad som anges i det föregående gäller även Hyrestagarens reklamskyltar och annat
          marknadsföringsmaterial. Vid återlämning ska Hyrestagaren återlämna samtliga passerkort och nycklar.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>4.2 Vid återlämning ska parterna gemensamt genomföra en besiktning av Lokalen. </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.3 Om Hyrestagaren (eller någon annan som Hyrestagaren svarar för) lämnar kvar utrusning eller annan egendom i Lokalen har
          Uthyraren rätt att bortforsla och låta förvara egendomen på Hyrestagarens bekostnad.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.4 Såvida inte annat överenskommes i Hyresavtalet inklusive bilagor svarar Uthyraren för städning dock enbart vad gäller normal
          nedsmutsning. Övrig städning svarar Hyrestagaren för. Uthyraren har rätt att låta ombesörja sådan städning på Hyrestagarens
          bekostnad.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.5 Hyrestagaren svarar för bortforsling av skräp i samband med Arrangemanget inklusive konfetti och likande. Om skräp kvarlämnats
          i Lokalen har Uthyraren rätt att ombesörja bortforsling (och städning i samband därmed) på Hyrestagarens bekostnad.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Uppsägning av Hyresavtalet/avbokning</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          5.1 Båda Parter förbehåller sig rätten att säga upp Hyresavtalet till omedelbart upphörande om den andra Parten (eller någon för
          vilken sådan Part svarar) väsentligen bryter mot bestämmelse i Hyresavtalet med bilagor (inklusive bestämmelserna i dessa allmänna
          avtalsvillkor) och inte vidtar rättelse efter skriftlig anmaning från den andra Parten. Rättelse ska därvid vidtas så snart som
          möjligt dock senast 5 (fem) kalenderdagar efter mottagandet av den skriftliga anmaningen. Vad som anges i det föregående ska även
          gälla vid ett s.k. befarat väsentligt avtalsbrott dvs. ifall då Part (ännu) inte väsentligen brutit mot Hyresavtalet men då det
          (ändå) står klart att Part kommer att väsentligen bryta mot Hyresavtalet.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          5.2 Vid bedömning av om ett väsentligt avtalsbrott kan anses ha begåtts eller kommer att begås ska samtliga relevanta
          omständigheter beaktas inklusive om den drabbade Partens intressen kan tillgodoses genom andra påföljder än uppsägning av
          Hyresavtalet och om den avtalsbrytande Parten drabbas oproportionerligt hårt av uppsägningen.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          5.3 Utöver vad som anges i 5.1 ovan har Part även rätt att säga upp Hyresavtalet till omedelbart upphörande enligt 18.2 nedan
          (Force majeure).
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        {!agreement && (
          <td>
            5.4 Vid önskan om avbokning av Hyrestagare senare än 6 månader innan genomförande debiteras 50 % av lokalhyran. Vid avbokning
            senare än 2 månader före dagen för Evenemanget debiteras 100 % av lokalhyran. Vid avbokning senare än 2 veckor före dagen för
            Evenemanget debiteras 100 % av lokalhyran samt 100 % av eventuella åtaganden som är bekräftade från Hyrestagare till Uthyraren,
            från Uthyraren till leverantör (t. ex hyra av specialteknik från annan firma som bokats och bekräftats av Uthyraren) samt
            personalkostnader och beställd mat & dryck från Uthyraren. Uthyraren hänvisar till Visitas allmänna villkor. Vid avbokning p g a
            covid-19 restriktioner efter biljettupplägg debiteras en avgift på 10 000kr för redan utfört arbete. Flyttas Evenemanget fram på
            grund av covid-19 restriktioner men genomförs vid senare tillfälle hos Uthyraren förbehåller sig Uthyraren rätten att ta ut en
            extra avgift.
          </td>
        )}
        {agreement && (
          <td>
            5.4 Vid önskan om avbokning av Hyrestagare senare än {agreement.earlyCancelationAt} dagar innan genomförande debiteras{" "}
            {agreement.earlyCancelation} % av lokalhyran. Vid avbokning senare än {agreement.lateCancelationAt} dagar före dagen för
            Evenemanget debiteras {agreement.lateCancelation} % av lokalhyran. Vid avbokning senare än 2 veckor före dagen för Evenemanget
            debiteras 100 % av lokalhyran samt 100 % av eventuella åtaganden som är bekräftade från Hyrestagare till Uthyraren, från
            Uthyraren till leverantör (t. ex hyra av specialteknik från annan firma som bokats och bekräftats av Uthyraren) samt
            personalkostnader och beställd mat & dryck från Uthyraren. Uthyraren hänvisar till Visitas allmänna villkor. Vid avbokning p g a
            covid-19 restriktioner efter biljettupplägg debiteras en avgift på 10 000kr för redan utfört arbete. Flyttas Evenemanget fram på
            grund av covid-19 restriktioner men genomförs vid senare tillfälle hos Uthyraren förbehåller sig Uthyraren rätten att ta ut en
            extra avgift.
          </td>
        )}
      </tr>
      <tr>
        <td>
          5.5 Utöver i de fall som anges i 5.1, 5.3 (med hänvisning till 18.2 nedan) och 5.4 ovan har varken Uthyraren eller Hyrestagaren
          rätt att avboka Arrangemanget och/eller upp Hyresavtalet till upphörande.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Mat, dryck och garderob</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          6.1 Såvida annat inte överenskommes i Hyresavtalet inklusive bilagor svarar Uthyraren för all försäljning av mat och dryck i
          Lokalen. Samma sak gäller för servering av mat och dryck till Artisten och personal i loger och andra icke-publika ytor. I den mån
          och omfattning Uthyraren enligt överenskommelse serverar mat och dryck till Artisten eller någon annan som Hyrestagaren svarar för
          förbehåller sig Uthyraren att debitera Hyrestagaren enligt Uthyrarens ordinarie prislista. Hyrestagaren har inte rätt att föra in
          mat eller dryck (inklusive alkoholhaltig dryck) utan särskild överenskommelse med Uthyraren.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          6.2 Om Hyrestagaren ska svara för försäljning av mat och dryck i Lokalen eller annan servering av mat och dryck förbinder sig
          Hyrestagaren att inhämta samtliga erforderliga tillstånd i samband därmed samt att i övrigt tillse att försäljningen och
          serveringen sker i enlighet med gällande lagstiftning.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          6.3 Såvida annat inte överenskommes i Hyresavtalet svarar Uthyraren för all garderobsservice inom Lokalen och förbehåller sig
          rätten att debitera garderobsavgifter till Arrangemangets besökare.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Biljetter</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>7.1 Såvida inte annat anges i Hyresavtalet svarar Hyrestagaren för all bokning, försäljning och distribution av biljetter. </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          7.2 Hyrestagaren har rätt att ensam fatta beslut om biljettpriset samt datum för släpp av biljetter men förbinder sig att på
          begäran av Uthyraren att samråda med Uthyraren i samband därmed.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          7.3 Om Parterna kommer överens om att Uthyraren ska svara för bokning, försäljning och distribution av biljetter har Uthyraren
          rätt att ta ut en den serviceavgift som framgår av Hyresavtalet inklusive bilagor. Om Uthyraren ska svara för bokning, försäljning
          och distribution av biljetter förbinder sig Uthyraren att redovisa och utbetala biljettintäkterna till Hyrestagaren efter avdrag
          för serviceavgiften senast 10 (tio) dagar efter datum för genomförande av Arrangemanget.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          7.4 Oaktat vad som anges i 7.3 ovan förbehåller sig Uthyraren rätten att kvittningsvis tillgodogöra sig samtliga belopp som
          Hyrestagaren har att betala till Uthyraren enligt Hyresavtalet med bilagor inklusive eventuella skadestånd vid redovisning av
          biljettintäkterna till Hyrestagaren.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Merchandiseförsäljning</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          8.1 Såvida inte annat överenskommits i Hyresavtalet inklusive bilagor har Hyrestagaren rätt att bedriva (eller låta tredje man)
          bedriva s.k. merchandiseförsäljning i Lokalen i samband med Arrangemanget.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>STIM och SAMI</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          9.1 Hyrestagaren ska svara för samtliga erforderliga licenser och tillstånd vad gäller offentligt framförande av musik i samband
          med Arrangemanget och förbinder sig att erlägga betalning till STIM och SAMI i anledning därav.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Marknadsföring</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          10.1 Hyrestagaren svarar för all marknadsföring av Arrangemanget och svarar även för att marknadsföringen följer
          Marknadsföringslagen (2008:486) och annan tillämplig lagstiftning.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          10.2 Oaktat vad som anges i 10.1 ovan har dock Uthyraren rätt att publicera information om Arrangemanget på Uthyrarens webbsajt
          eller i övrigt i Uthyrarens informationskanaler.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          10.3 Hyrestagaren förklarar sig införstådd i att det kan förekomma reklam i Lokalen samt att Hyrestagaren på grund av Uthyrarens
          avtal med sponsorer och andra avtalsparter kan vara förhindrad att göra reklam för vissa produkter och tjänster i Lokalen eller i
          anslutning därtill. Uthyraren förbinder sig att informera Hyrestagaren om sådana begränsningar i samband med ingående av
          Hyresavtalet.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Vakter och säkerhet </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          11.1 Såvida annat inte överenskommes i Hyresavtalet inklusive bilagor svarar Hyrestagaren för erforderlig vaktpersonal i samband
          med Arrangemanget inklusive ordningsvakter, scenvakter och visiteringspersonal. Hyrestagaren svarar därvid för Arrangemangets
          säkerhet inklusive för Artistens (och dess personals) säkerhet (inklusive för att inga obehöriga personer får tillträde till
          Lokalen). Sådant ansvar ska gälla från och med det att Hyrestagaren tillträder Lokalen till dess att Hyrestagaren återlämnar
          Lokalen.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          11.2 Om Uthyraren enligt Hyresavtalet ska svara för vaktpersonal svarar dock Uthyraren för Arrangemangets säkerhet i enlighet med
          vad som anges i 11.1 ovan.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Personal</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          12.1 Parterna garanterar att all personal som tillhandahålls av Part i anledning av Arrangemanget (inklusive all personal som
          tillhandahålls av underkonsulter som anlitas av Parterna) har de utbildningar, kvalifikationer, tillstånd och behörigheter som är
          erforderliga för utförandet av de arbetsuppgifter som personerna har att utföra. Parterna garanterar likväl att sådan personal är
          lämpade att utföra arbetsuppgifterna samt att de kommer att utföra arbetsuppgifterna på ett fackmannamässigt sätt.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Tillstånd </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          13.1 Såvida inget annat anges i Hyresavtalet inklusive bilagor svarar Hyrestagaren för samtliga erforderliga tillstånd enligt
          Ordningslagen (1993:1617) gällande Arrangemanget. Hyrestagaren svarar även för erforderliga tillstånd för eventuell pyroteknik och
          andra tillstånd som är specifika för Arrangemanget.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Hyrestagarens ansvar i övrigt</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.1 Hyrestagaren svarar för allt material och all utrustning som Hyrestagaren (eller någon annan som Hyrestagaren svarar för) för
          med sig in i Lokalen inklusive för att sådant material och utrustning uppfyller samtliga gällande säkerhets- och
          arbetarskyddsbestämmelser.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.2 Hyrestagaren är ansvarig för all skadegörelse i Lokalen som orsakas av Hyrestagaren (eller någon annan som Hyrestagaren
          svarar för).
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.3 Hyrestagarens svarar för skador på Lokalen som uppkommer p.g.a. eller som en direkt följd av brottsligt angrepp mot
          Hyrestagaren eller Arrangemanget t.ex. anlagd brand eller sprängning. Hyrestagarens ansvar för skador av det slag som nämns i
          denna bestämmelse är dock begränsat till belopp som inte ersätts genom Uthyrarens försäkring t.ex. självrisk.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.4 Hyrestagaren ansvarar för att Arrangemanget inte strider mot gällande lagstiftning eller myndighetsföreskrift. Vad gäller
          rättsförhållandet mellan Hyrestagaren och Uthyraren svarar därvid Hyrestagaren även för eventuella upphovsrättsintrång, andra
          immaterialrättsliga brott samt yttrandefrihetsbrott.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.5 Parts ansvar enligt Hyresavtalet avser såväl Parts egna företrädare, anställda, konsulter och personal i övrigt som tredje
          mans företrädare, anställda, konsulter och personal i övrigt i det senare fallet förutsatt att sådan tredje man anlitats som
          underentreprenör av berörd Part eller på annat sätt getts i uppdrag av berörd Part att leverera tjänster eller tillhandahålla
          utrustning, inventarier och liknande i anledning av Arrangemanget. Hyrestagarens ansvar enligt detta Hyresavtal omfattar även
          såvida inte annat uttryckligen anges i Hyresavtalet inklusive bilagor publiken samt Artisten (och dess företrädare anställda
          konsulter och personal i övrigt).{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Skydd mot olyckor</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          15.1 Uthyraren svarar för att det inom Lokalen finns erforderlig utrustning för släckning av brand och för livräddning vid brand
          eller annan olycka. Det åligger Uthyraren att vidta de åtgärder som behövs för att förebygga brand och för att hindra eller
          begränsa skador till följd av brand. Uthyraren ska bedriva ett systematiskt brandskyddsarbete inom lokalen och i detta arbete
          följa från tid till annan från myndigheter lämnade riktlinjer för sådant arbete.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Försäkringar </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          16.1 Det åligger Uthyraren att teckna erforderliga försäkringar för Lokalen vad gäller t.ex. brand, inbrott, vattenskada och
          liknande händelser. Det åligger även Uthyraren att ha sin egendom i Lokalen försäkrad.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          16.2 Det åligger Hyrestagaren att teckna erforderliga försäkringar för sådan utrustning och annan egendom som Hyrestagaren för med
          sig in i Lokalen. Hyrestagaren förbinder sig att tillse att dess underentreprenörer tecknar motsvarande försäkringar för sin
          egendom.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          16.3 Utöver vad som anges ovan ska båda Parter teckna tillfredställande ansvarsförsäkringar för sina verksamheten samt tillse att
          Parternas underentreprenörer gör detsamma. Hyrestagaren ska därvid svara för att t.ex. Artisten tecknar ansvarsförsäkring.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Skadestånd</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          17.1 Parterna förbinder sig att hålla varandra fria från all skada inklusive alla utgifter, kostnader och förluster (inklusive
          utebliven vinst) vid Parts brott mot Hyresavtalet (inklusive bestämmelserna i dessa allmänna avtalsvillkor). Vad som anges i det
          föregående gäller oavsett om oaktsamhet eller uppsåt föreligger.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          17.2 Parts skyldighet att hålla den andra Parten fri från skada ska omfatta samtliga ersättningar skadestånd viten böter eller
          andra belopp som part är skyldig att utge till tredje man i anledning av Parts brott mot Hyresavtalet (inklusive bestämmelserna i
          dessa allmänna avtalsvillkor) inklusive skadestånd och ersättningar för person- och sakskador.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Force majeure </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          18.1 Om parts fullgörande av sina åtaganden enligt Hyresavtalet väsentligen försvåras eller förhindras på grund av hinder utanför
          Partens kontroll inklusive krig, strejk, naturkatastrof, eldsvåda, upplopp, terrorattentat, myndighetsåtgärd, nytillkommen eller
          ändrad lagstiftning och vars följder Parten inte heller skäligen kunde ha undvikit ska detta utgöra grund för befrielse från
          ansvar för skadestånd och andra påföljder.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          18.2 Om genomförandet av Arrangemanget väsentligen försvåras eller förhindras på grund av ett sådan hinder som anges i 18.1 ovan
          ska Parterna gemensamt fatta beslut om senareläggande av Arrangemanget till en tidpunkt när hindret kan förväntas ha upphört. Om
          sådan tidpunkt infaller senare än 3 (tre) månader efter avtalat datum för Arrangemanget har dock Part rätt att i stället för att
          medverka till senareläggandet säga upp Hyresavtalet till omedelbart upphörande. Samma sak ska gälla om det inte är möjligt att
          senarelägga Arrangemanget på grund av omständigheter utanför Parts kontroll eller om Part skulle orsakas väsentligen ökade
          kostnader vid en senareläggning av Arrangemanget.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          18.3 Part ska så snart som är möjligt efter det att Part fått kännedom om ett hinder enligt 18.1 ovan meddela den andra Parten om
          hindret. Båda Parter ska vidta samtliga skäliga åtgärder för att förebygga och begränsa de skador som uppstår vid förekomsten av
          hinder enligt 18.1 ovan.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Överlåtelse av rättigheter och skyldigheter</td>
      </tr>
      <tr>
        <td> </td>
      </tr>
      <tr>
        <td>
          19.1 Part har varken rätt att upplåta eller överlåta sina rättigheter eller skyldigheter enligt Hyresavtalet (inklusive dessa
          allmänna avtalsvillkor) utan att först inhämta den andra Partens skriftliga godkännande. Vad som anges i det föregående gäller
          även (fullständig eller partiell) andrahandsuthyrning av Lokalen.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Meddelanden</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          20.1 Meddelande i anledning av detta Avtal ska överlämnas personligen genom bud eller genom skriftligt meddelande skickat till de
          postadresser eller e-mailadresser som anges i Hyresavtalet. Om meddelande överlämnats personligen eller genom bud ska det anses
          som kommit mottagaren tillhanda vid överlämnandet. Om meddelandet skickats med rekommenderat brev ska det anses kommit mottagaren
          tillhanda 3 (tre) dagar efter det att avlämnades för postbefordran. Om meddelande skickats via e-post ska det anses kommit
          motparten tillhanda när mottagandet bekräftats (t.ex. genom att mottagaren svarar på meddelandet).
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Tvister</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          21.1 Tvister i anledning av Hyresavtalet ska avgöras av allmän domstol med Stockholms tingsrätt i första instans. Svensk lag ska
          tillämpas på Hyresavtalet.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      {/* <tr>
        <td>
          <b>TILLÄGG MAXIM</b>
        </td>
      </tr>
      <tr>
        <td>AVBOKNINGSREGLER</td>
      </tr>
      <tr>
        <td>
          Vid avbokning senare än 6 månader innan genomförande debiteras 50% av lokalhyran. Vid avbokning senare än 3 månader före dagen för
          Evenemanget debiteras 100% av lokalhyran. Fram till 30 dagar innan genomförandet kan deltagarantalet minskas med 20% från signerat
          antal.
        </td>
      </tr>
      <tr>
        <td> AVBOKNINGSREGLER ANTAL DELTAGARE/INHYRD TEKNIK, PERSONAL</td>
      </tr>
      <tr>
        <td>
          Fram till 14 dagar innan genomförandet kan deltagarantalet minskas med 10% från senast anmälda antal. Vid avbokning senare än 2
          veckor före dagen för Evenemanget debiteras 100% av lokalhyran samt 100% av eventuella åtaganden som är bekräftade från kund till
          X, från X till leverantör (t. ex hyra av specialteknik från annan firma som bokats och bekräftats av X) samt personalkostnader och
          beställd mat & dryck från X. X hänvisar till Visitas allmänna villkor.
        </td>
      </tr>
      <tr>
        <td>BETALNING/FÖRSKOTT</td>
      </tr>
      <tr>
        <td>
          Ett förskott på 50 procent av bekräftat belopp ska betalas när bokningen görs mindre än tre månader innan arrangemanget. Om
          bokning görs mer än tre månader innan arrangemanget ska ett förskott på 25 procent betalas vid bokning och ett på 25 procent när
          det återstår tre månader till arrangemanget. Förskotten förfaller till betalning inom 10 dagar från fakturornas datum.
          Slutavräkning skickas efter genomfört arrangemang. Fakturor på förskottsbeloppet sänds omgående efter bekräftad bokning samt tre
          månader innan arrangemanget när bokning skett mer än tre månader innan arrangemanget och är ej återbetalningsbara.
        </td>
      </tr> */}
    </table>
  );
};

export const AvtalsingressEnglish = ({ agreement, bookingData, currency, sumIncVat }) => {
  return (
    <>
      <div style={{ fontSize: "12px" }}>
        This Agreement is made between <strong>{agreement.venue.company || "xx"}</strong> (hereinafter referred to as "Venue") and{" "}
        <strong>{agreement.agent.company || "yy"}</strong> ({agreement.agent.orgNumber}){"  "} (hereinafter referred to as "Customer").
        Venue leases to Customer and Customer rents from Venue a certain residential unit located at{" "}
        <strong>
          {bookingData.venue.venueStreetAddress}, {bookingData.venue.venueZipCode}
        </strong>
        {bookingData.venue.venueCity} (hereinafter referred to as "Premises"). The Premises shall be used as agreed upon.
        <br></br>
        <br></br>
        Customer shall pay to Venue a fee of{" "}
        <strong>
          {numberFormatted(sumIncVat)} {currency}
        </strong>{" "}
        for the use of the Premises.
        {agreement.upfront > 0 &&
          `An upfront fee of ${agreement.upfront}% of the total cost of each booked date, shall be payed ${
            agreement.upfrontAt > 365 ? "direct." : `no later than ${agreement.upfrontAt} days prior to event date.`
          }`}{" "}
        {agreement.preCharge > 0 &&
          `A deposition of ${agreement.preCharge}% of the total cost of each booked date, payable no later than ${agreement.preChargeAt} days prior
to event date.`}
        {agreement.charge > 0 &&
          `Balance payment of ${
            100 - parseFloat(agreement.preCharge) - parseFloat(agreement.upfront)
          }% of the total cost of each booked date payable no later than ${agreement.chargeAt} days prior to event date.`}{" "}
        Additional charges may apply for extra services in final settlement.
      </div>
      <div style={{ fontSize: "12px" }}>
        <br></br>
        Cancellations made later than {agreement.earlyCancelationAt} days prior to the Event date will incur a {agreement.earlyCancelation}%
        rental fee. Cancellations made later than {agreement.lateCancelationAt} days before the Event date will incur a{" "}
        {agreement.lateCancelation}% rental fee. For cancellations made later than 2 weeks before the Event date, 100% of the rental fee,
        all confirmed commitments, supplier costs, personnel costs, and food & beverage orders will be charged.
      </div>
    </>
  );
};

export const GeneralConditionsEnglish = ({ agreement }) => {
  return (
    <table className="generalConditionsPDF" style={{ display: "grid", overflowWrap: "anywhere", fontSize: "10px" }}>
      <tr>
        <td>
          <strong>GENERAL TERMS AND CONDITIONS</strong>
        </td>
      </tr>
      <tr>
        <td>TEMPORARY RENTAL OF PREMISES</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          These general terms and conditions apply to the agreement on the temporary rental of premises entered into between the Lessor and
          the Lessee (the "Rental Agreement"). The Lessor and the Lessee are also referred to below as the "Parties" and "Party"
          respectively.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Grant of right of use </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          1.1 Through the Rental Agreement, the Lessor grants the Lessee the right to use the Premises for the event/purpose ("the Event")
          specified in the Rental Agreement. The Lessee does not have the right to use the Premises for other events/purposes.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          1.2 In connection with renting out the Premises, the Lessor must also provide the services and the equipment specified in the
          Rental Agreement including addenda and/or in these general terms and conditions.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          1.3 Unless otherwise stated in the Rental Agreement including addenda, the Lessee's right of use applies to all public spaces in
          the Premises, including dressing rooms and green rooms.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          1.4 The Lessee disposes of the Premises on the days and during the times specified in the Rental Agreement including addenda.{" "}
        </td>
      </tr>
      <tr>
        <td>Condition and use of the Premises</td>
      </tr>
      <tr>
        <td>2.1 The Premises including auxiliary spaces are rented out in a suitable condition for the intended purpose.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          2.2 The Lessee shall take good care of the Premises and ensure that they are returned in the same condition as when the Lesee was
          granteed access.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          2.3 The Lessee may not, without the Lessor's written permission, make any changes to the Premises, including any alterations and
          additions. The Lessee undertakes to obtain the Lessor's approval regarding all assembly work and construction of structures in the
          Premises (including the construction of the stage, stands, bleachers and platforms). The Lessor reserves the right to have such
          works inspected (at the Lessee's expense) and the right to reject such works and constructions.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>2.4 The Lessor undertakes to provide heating, comfort cooling, ventilation, water and hot water. </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Electricity and technical equipment</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          3.1 The Lessor undertakes to ensure that the required electricity supply and electrical connections are provided in the Premises.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          3.2 If the Parties have agreed that the Lessor shall be responsible for PA equipment and/or lights in the Rental Agreement
          including addenda, the extent of the Lessor's commitment must be stated in the Rider attached to the Rental Agreement. The same
          shall apply if the Lessor has undertaken to be responsible for other technology or technical equipment such as AV equipment,
          telecommunications or computer technology.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Move out and cleaning</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.1 The Lessee must relinquish the Premises at the latest on the date and at the time specified in the Rental Agreement with
          addenda. When returning the Premises, the Lessee must dismantle and take with them all equipment and all other property belonging
          to the Lessee (or someone else for whom the Lessee is responsible). What is stated in the preceding also applies to the Lessee's
          posters, signs and other marketing material. Upon return, the Lessee must return all access cards and keys.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.2 Upon the return of the Premises to the Lessor, both Parties have the right to call for an inspection of the Premises to be
          carried out jointly by the Parties.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.3 If the Lessee (or someone else for whom the Lessee is responsible) leaves equipment or other property in the Premises, the
          Lessor has the right to remove and have the property stored at the Lessee's expense.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.4 Unless otherwise agreed in the Rental Agreement including addenda, the Lessor is responsible for cleaning, however, only with
          regard to normal soiling. The Lessee is responsible for other cleaning. The Lessor has the right to arrange for such cleaning at
          the Lessee's expense.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          4.5 The Lessee is responsible for the removal of refuse in connection with the Event, including confetti and the like. If refuse
          or rubbish is left in the Premises, the Lessor has the right arrange for its removal (and associated cleaning) at the Tenant's
          expense.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Termination of the Rental Agreement/cancellation</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          5.1 Both Parties reserve the right to terminate the Rental Agreement until immediate termination if the other Party (or someone
          for whom such Party is responsible) materially violates a provision in the Rental Agreement with addenda (including the provisions
          of these general terms and conditions) and does not take corrective action after a written request from the other Party.
          Correction must be taken as soon as possible, but no later than 5 (five) calendar days after receipt of the written request. What
          is stated in the preceding shall also apply in the event of a so-called feared material breach of contract, i.e. if the Party has
          not (yet) materially breached the Rental Agreement but when it is (still) clear that the Party will materially breach the Rental
          Agreement.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          5.2 When assessing whether a material breach of contract can be considered to have been committed or will be committed, all
          relevant circumstances must be taken into account, including whether the affected Party's interests can be satisfied through
          sanctions other than termination of the Rental Agreement and whether the breaching Party is disproportionately affected by the
          termination.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          5.3 In addition to what is stated in 5.1 above, the Party also has the right to terminate the Rental Agreement with immediate
          termination according to 18.2 below (Force majeure).
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        {!agreement && (
          <td>
            5.4 If the Lessee wishes to cancel later than 180 days before the Event, 50% of the rental fee will be charged. In case of
            cancellation later than 60 days before the date of the Event, 100% of the rental fee will be charged. In case of cancellation
            later than 2 weeks before the date of the Event, 100% of the rental fee is charged as well as 100% of any commitments that are
            confirmed from the Lessee to the Lessor, from the Lessor to its suppliers (e.g. rental of special technology from another
            company booked and confirmed by the Lessor) as well as staff costs and food & beverage ordered from the Lessor. The Lessor
            refers to Visita's general terms and conditions. In case of cancellation after ticketing due to covid-19 restrictions, a fee of
            SEK 10,000 will be charged for work already carried out. If the Event is moved forward due to covid-19 restrictions but is
            carried out at a later date with the Lessor, the Lessor reserves the right to charge an additional fee.
          </td>
        )}
        {agreement && (
          <td>
            5.4 If the Lessee wishes to cancel later than {agreement.earlyCancelationAt} days before the Event, {agreement.earlyCancelation}
            % of the rental fee will be charged. In case of cancellation later than {agreement.lateCancelationAt} days before the date of
            the Event, {agreement.lateCancelation}% of the rental fee will be charged. In case of cancellation later than 2 weeks before the
            date of the Event, 100% of the rental fee is charged as well as 100% of any commitments that are confirmed from the Lessee to
            the Lessor, from the Lessor to its suppliers (e.g. rental of special technology from another company booked and confirmed by the
            Lessor) as well as staff costs and food & beverage ordered from the Lessor. The Lessor refers to Visita's general terms and
            conditions. In case of cancellation after ticketing due to covid-19 restrictions, a fee of SEK 10,000 will be charged for work
            already carried out. If the Event is moved forward due to covid-19 restrictions but is carried out at a later date with the
            Lessor, the Lessor reserves the right to charge an additional fee.
          </td>
        )}
      </tr>
      <tr>
        <td>
          5.5 In addition to the cases specified in 5.1, 5.3 (with reference to 18.2 below) and 5.4 above, neither the Lessor nor the Lessee
          has the right to cancel the Event and/or terminate the Rental Agreement.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Food, beverage and cloakroom services</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          6.1 Unless otherwise agreed in the Rental Agreement including addenda, the Lessor is responsible for all sales of food and
          beverages in the Premises. The same applies to serving food and beverages to the Artist and staff in green rooms and other
          auxiliary spaces. To the extent that the Lessor agrees to serve food and beverage to the Artist or someone else for whom the
          Lessee is responsible, the Lessor reserves the right to charge the Lessee according to the Lessor's regular pricing. The Lessee
          does not have the right to bring in food or beverage (including alcoholic beverages) without special agreement with the Lessor.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          6.2 If the Lessee is to be responsible for the sale of food and beverage in the Premises or other serving of food and beverage,
          the Lessee undertakes to obtain all necessary permits in connection therewith and to otherwise ensure that the sale and serving
          takes place in accordance with current legislation.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          6.3 Unless otherwise agreed in the Rental Agreement, the Lessor is responsible for all cloakroom service within the Premises and
          reserves the right to charge cloakroom fees to the Event's visitors.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Tickets</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          7.1 Unless otherwise stated in the Rental Agreement including addenda, the Lessor is responsible for all booking, sale and
          distribution of tickets.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          7.2 The Lessee has the right to make decisions regarding ticket price and the date of release of tickets, but undertakes, at the
          request of the Lessor, to consult with the Lessor in connection therewith.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          7.3 If the Parties agree that the Lessor shall be responsible for the booking, sale and distribution of tickets, the Lessor has
          the right to charge the service fee specified in the Rental Agreement and/or addenda. If the Lessor is to be responsible for the
          booking, sale and distribution of tickets, the Lessor undertakes to report and pay the ticket revenue to the Lessee after
          deducting the service fee no later than 10 (ten) days after the date of the Event.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          7.4 Notwithstanding what is stated in 7.3 above, the Lessor reserves the right to set off all amounts that the Lessee has to pay
          to the Lessor according to the Rental Agreement with addenda, including any damages, when reporting the ticket revenue to the
          Lessee.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Merchandise sales</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          8.1 Unless otherwise agreed in the Rental Agreement including addenda, the Lessee has the right to conduct (or have a third party
          conduct) merchandise sales in the Premises in connection with the Event.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>STIM, SAMI and licensing costs</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          9.1 The Lessee shall be responsible for all required licenses and permits regarding public performance of music in connection with
          the Event and undertakes to make payment to STIM and SAMI in connection therewith.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Marketing</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          10.1 The Lessee is responsible for all marketing of the Event and is also responsible for ensuring that the marketing complies
          with the Marketing Act (Marknadsföringslagen 2008:486) and other applicable legislation.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          10.2 Notwithstanding what is stated in 10.1 above, the Lessor has the right to publish information about the Event on the Lessor's
          website or otherwise in the Lessor's information channels.{" "}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          10.3 The Lessee declares that he understands that there may be advertising in the Premises and that, due to the Lessor's agreement
          with sponsors and other contractual parties, the Lessee may be prevented from advertising certain products and services in the
          Premises or in connection therewith. The Lessor undertakes to inform the Lessee of such restrictions when entering into the Rental
          Agreement.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Security </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          11.1 Unless otherwise agreed in the Rental Agreement including addenda, the Lessee undertakes to provide the necessary security
          personnel in connection with the Event, including security guards, stage guards and inspection personnel. The Lessee is thereby
          responsible for the security of the Event, including the security of the Artist (and its staff), including that no unauthorized
          persons gain access to the Premises. Such liability shall apply from the time the Lessee enters the Premises until the Lessee
          returns the Premises.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          11.2 If, according to the Rental Agreement including addenda, the Lessor is to be responsible for security personnel, the Lessor
          is responsible for the security of the Event in accordance with what is stated in 11.1 above.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Staff</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          12.1 The Parties guarantee that all personnel provided by the Party in connection with the Eventt (including all personnel
          provided by subcontractors engaged by the Parties) have the training, qualifications, permits and authorizations required for the
          performance of the tasks that the persons have to perform. The partiesguarantee that such personnel are suitable to perform the
          tasks and that they will perform the tasks in a professional manner.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Permits </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          13.1 Unless otherwise stated in the Rental Agreement including addenda, the Lessee is responsible for all required permissions
          according to the Public Order Act (Ordningslagen 1993:1617) regarding the Event. The Lessee is also responsible for the necessary
          permits for any pyrotechnics and other permits that are specific to the Arrangement.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>The Lessee's responsibilities in general</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.1 The Lessee is responsible for all material and equipment that the Lessee (or someone else for whom the Lessee is responsible)
          brings into the Premises, including ensuring that such material and equipment meets all applicable safety and occupational health
          and safety regulations.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.2 The Lessee is responsible for all damage to the Premises caused by the Lessee (or someone else for whom the Lessee is
          responsible).
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.3 The Lessee is responsible for damage to the Premises that occurs due to or as a direct result of a criminal attack against
          the Lessee or the Event, e.g. arson or explosion. The Lessee's liability for damages of the kind mentioned in this provision is,
          however, limited to amounts not covered by the Lessor's insurance, e.g. deductible.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.4 The Lessee is responsible for ensuring that the Event does not contravene current legislation or regulations. With regard to
          the legal relationship between the Lessee and the Lessor, the Lessee is also responsible for possible copyright infringements,
          other intellectual property law violations and freedom of expression violations.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          14.5 The Party's liability according to the Rental Agreement applies to the Party's own representatives, employees, consultants
          and personnel in general as well as third parties' representatives, employees, consultants and personnel in general, in the latter
          case provided that such third parties have been engaged as subcontractors by the Party concerned or otherwise provided on behalf
          of the concerned Party to deliver services or provide equipment, fixtures and the like in connection with the Event. The Lessee's
          responsibility under this Rental Agreement also includes, unless otherwise expressly stated in the Rental Agreement including
          addenda, the audience as well as the Artist (and its representatives, employed consultants and staff in general).
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Protection against accidents</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          15.1 The Lessor is responsible for ensuring that the required equipment is available within the Premises for extinguishing fires
          and for saving lives in the event of a fire or other accident. It is the Lessor's responsibility to take the necessary measures to
          prevent fire and to prevent or limit damage as a result of fire. The Lessor must carry out systematic fire protection work within
          the Premises and in this work follow guidelines for such work provided by the authorities.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Insurance </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          16.1 It is the Lessor's responsibility to take out the required insurance for the Premises regarding e.g. fire, burglary, water
          damage and similar events. It is also the Lessor's responsibility to have their property in the Premises insured.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          16.2 It is the Lessee's responsibility to take out the required insurance for such equipment and other property that the Lessee
          brings into the Premises. The Lessee undertakes to ensure that its subcontractors take out corresponding insurance policies for
          their property.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          16.3 In addition to what is stated above, both Parties must take out satisfactory liability insurance for their operations and
          ensure that the Parties' subcontractors do the same. The Lessee must thereby be responsible for e.g. the Artist taking out
          liability insurance.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Damages</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          17.1 The Parties agree to hold each other harmless from all damages including all expenses, costs and losses (including loss of
          profit) in the event of the breach of the Rental Agreement including addenda by the Party (including the provisions of these
          general terms and conditions). What is stated in the preceding applies regardless of whether there is negligence or intent.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          17.2 The Party's obligation to keep the other Party free from damage shall include all compensation, damages, fines or other
          amounts that the Party is obliged to pay to third parties due to the Party's breach of the Rental Agreement with addenda
          (including the provisions of these general terms and conditions), including damages and compensation for personal and property
          damage.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>Force majeure </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          18.1 If a party's fulfillment of its obligations under the Rental Agreement is significantly hindered or prevented due to
          obstacles beyond the Party's control, including war, strike, natural disaster, fire, riot, terrorist attack, official action, or
          newly introduced or amended legislation the consequences of which the Party could not reasonably have avoided, this forms the
          basis for exemption from liability for damages and other penalties.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          18.2 If the implementation of the Event is significantly hindered or prevented due to such an obstacle as specified in 18.1 above,
          the Parties must jointly decide whether to postpone the Event until a time when the obstacle can be expected to have ceased. If
          such time falls later than 3 (three) months after the agreed date for the Event, however, the Party has the right to terminate the
          Rental Agreement to immediate termination instead of contributing to the postponement. The same shall apply if it is not possible
          to postpone the Event due to circumstances beyond the Party's control or if the Party would incur substantially increased costs in
          the event of a postponement of the Event.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          18.3 The Party shall, as soon as possible after the Party becomes aware of an obstacle according to 18.1 above, notify the other
          Party of the obstacle. Both Parties must take all reasonable measures to prevent and limit the damage that occurs in the presence
          of obstacles according to 18.1 above.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Transfer of rights and obligations</td>
      </tr>
      <tr>
        <td> </td>
      </tr>
      <tr>
        <td>
          19.1 The Party has no right to assign or transfer its rights or obligations under the Rental Agreement with addenda (including
          these general terms and conditions) without first obtaining the other Party's written approval. What is stated in the preceding
          also applies to (full or partial) subletting of the Premises.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Notices</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          20.1 Notice in connection with this Agreement must be delivered personally by courier or by written notice sent to the postal
          addresses or e-mail addresses specified in the Rental Agreement. If a notice is delivered in person or by courier, it shall be
          deemed to have reached the recipient upon delivery. If the notice is sent by registered mail, it shall be deemed to have reached
          the recipient 3 (three) days after it was handed over for postal forwarding. If a notice is sent via e-mail, it shall be deemed to
          have reached the counterparty when receipt is confirmed (e.g. by the recipient replying to the message).
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> Disputes</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>
          21.1 Disputes due to the Rental Agreement shall be settled by a judicial court with the Stockholm district court in first
          instance. Swedish law shall apply to the Rental Agreement.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      {/* <tr>
        <td>
          <b>TILLÄGG MAXIM</b>
        </td>
      </tr>
      <tr>
        <td>AVBOKNINGSREGLER</td>
      </tr>
      <tr>
        <td>
          Vid avbokning senare än 6 månader innan genomförande debiteras 50% av lokalhyran. Vid avbokning senare än 3 månader före dagen för
          Evenemanget debiteras 100% av lokalhyran. Fram till 30 dagar innan genomförandet kan deltagarantalet minskas med 20% från signerat
          antal.
        </td>
      </tr>
      <tr>
        <td> AVBOKNINGSREGLER ANTAL DELTAGARE/INHYRD TEKNIK, PERSONAL</td>
      </tr>
      <tr>
        <td>
          Fram till 14 dagar innan genomförandet kan deltagarantalet minskas med 10% från senast anmälda antal. Vid avbokning senare än 2
          veckor före dagen för Evenemanget debiteras 100% av lokalhyran samt 100% av eventuella åtaganden som är bekräftade från kund till
          X, från X till leverantör (t. ex hyra av specialteknik från annan firma som bokats och bekräftats av X) samt personalkostnader och
          beställd mat & dryck från X. X hänvisar till Visitas allmänna villkor.
        </td>
      </tr>
      <tr>
        <td>BETALNING/FÖRSKOTT</td>
      </tr>
      <tr>
        <td>
          Ett förskott på 50 procent av bekräftat belopp ska betalas när bokningen görs mindre än tre månader innan arrangemanget. Om
          bokning görs mer än tre månader innan arrangemanget ska ett förskott på 25 procent betalas vid bokning och ett på 25 procent när
          det återstår tre månader till arrangemanget. Förskotten förfaller till betalning inom 10 dagar från fakturornas datum.
          Slutavräkning skickas efter genomfört arrangemang. Fakturor på förskottsbeloppet sänds omgående efter bekräftad bokning samt tre
          månader innan arrangemanget när bokning skett mer än tre månader innan arrangemanget och är ej återbetalningsbara.
        </td>
      </tr> */}
    </table>
  );
};

// export default GeneralConditions;
