/** @format */

import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import { db } from "../../firebase";

const AdditionalFees = () => {
  const [fees, setFees] = useState(null);

  useEffect(() => {
    getAdditionalFees();
  }, []);

  async function getAdditionalFees() {
    const docSnap = await getDoc(doc(db, "GeneralSettings", "AdditionalFees"));
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setFees(docSnap.data());
    }
  }

  const handleChange = async (key, value) => {
    setFees({ ...fees, [key]: value });
    console.log("fees", fees);
    await setDoc(doc(db, "GeneralSettings", "AdditionalFees"), { [key]: { sum: value } }, { merge: true });
    // store new value in AdditionalFees in GeneralSettings
  };

  return (
    <div>
      {fees && (
        <table className="smallTable">
          <tr>
            <td>Transaction</td>
            {console.log("fees", fees)}
            <td>
              <input value={fees["transactionFee"]["sum"]} onChange={(e) => handleChange("transactionFee", e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Reminder</td>
            <td>
              <input value={fees["reminderFee"]["sum"]} onChange={(e) => handleChange("reminderFee", e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Second Reminder</td>
            <td>
              <input value={fees["secondReminder"]["sum"]} onChange={(e) => handleChange("secondReminder", e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Settlement</td>
            <td>
              <input value={fees["settlementFee"]["sum"]} onChange={(e) => handleChange("settlementFee", e.target.value)} />
            </td>
          </tr>
        </table>
      )}
    </div>
  );
};

export default AdditionalFees;
